import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, styled, useMediaQuery } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Pagination from '@mui/material/Pagination'

import { useTextualContext } from 'contexts/TextualContext'
import RadioDropdown from 'components/common/Dropdown/RadioDropdown'
import TableHeaderSorter from './TableHeaderSorter'
import { SORT_ORDER } from 'utils/constants'

const StyledBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	paddingBlock: 12,
	gap: 8,

	[theme.breakpoints.up('lg')]: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}))

const StyledPagination = styled(Pagination)(({ theme }) => ({
	'.MuiPagination-ul': {
		gap: 10,
	},
	'.MuiPaginationItem-root': {
		'&:focus::before': {
			content: '""',
			position: 'absolute',
			top: -3,
			right: -3,
			bottom: -3,
			left: -3,
			boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
			borderRadius: '4px',
			opacity: 1,
		},
		'&:focus-visible': {
			backgroundColor: 'transparent',
		},
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
	},
	'.MuiPaginationItem-page.Mui-selected': {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.common.white,
	},
	'.MuiPaginationItem-page': {
		...theme.typography.linkFilter,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.secondary.main,
		border: '1px solid ' + theme.palette.secondary.main,
		borderRadius: 8,
	},
	'.MuiPaginationItem-previousNext': {
		border: '1px solid ' + theme.palette.secondary.main,
		color: theme.palette.secondary.main,
	},
}))

const StyledTable = styled(Table)(({ theme }) => ({
	borderCollapse: 'separate',
	borderSpacing: '0 16px',
	tableLayout: 'fixed',

	'& .MuiTableRow-root': {
		backgroundColor: theme.palette.primary.lightBlue,
	},

	'& .MuiTableHead-root': {
		'& .MuiTableRow-root': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiTypography-root': {
				paddingTop: 6,
			},
		},
	},
	td: {
		paddingTop: '12px',
		paddingBottom: '12px',
	},
	th: {
		paddingTop: '15px',
		paddingBottom: '15px',
	},
	'th:first-of-type, td:first-of-type': {
		borderTopLeftRadius: '8px',
		borderBottomLeftRadius: '8px',
		paddingLeft: '24px',
	},
	'th:last-child, td:last-child': {
		borderTopRightRadius: '8px',
		borderBottomRightRadius: '8px',
		paddingRight: '24px',
	},
	'tr:first-of-type th:first-of-type': {
		borderTopLeftRadius: '8px',
	},
	'tr:first-of-type th:last-child': {
		borderTopRightRadius: '8px',
	},
	'tr:last-child td:first-of-type': {
		borderBottomLeftRadius: '8px',
	},
	'tr:last-child td:last-child': {
		borderBottomRightRadius: '8px',
	},
}))

const TableViewer = (props) => {
	const {
		data,
		columns,
		rowActions,
		activeSort,
		activeOrder,
		onSortChange,
		onPageNumberChange,
		children,
	} = props
	const { t } = useTextualContext()
	const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	const [page, setPage] = useState(1)
	const itemsPerPage = 10
	const pageCount = Math.ceil(data.length / itemsPerPage)

	useEffect(() => {
		setPage(1)
	}, [data])

	const handleChangePage = (event, newPage) => {
		onPageNumberChange()
		setPage(newPage)
	}

	const sortOptions = columns
		.filter((v) => v.sortable)
		.reduce((acc, column) => {
			acc.push({
				value: `${column.field} ${SORT_ORDER.ASC}`,
				label: column.sortLabel[SORT_ORDER.ASC],
				fieldName: column.field,
				order: SORT_ORDER.ASC,
			})

			acc.push({
				value: `${column.field} ${SORT_ORDER.DSC}`,
				label: column.sortLabel[SORT_ORDER.DSC],
				fieldName: column.field,
				order: SORT_ORDER.DSC,
			})
			return acc
		}, [])

	const displayedData = data.slice(
		(page - 1) * itemsPerPage,
		page * itemsPerPage
	)

	const startIndex = (page - 1) * itemsPerPage + 1
	const endIndex = Math.min(page * itemsPerPage, data.length)
	const viewingRange = `${startIndex} - ${endIndex}`

	const renderDesktopTable = () => (
		<StyledTable aria-live="polite">
			<TableHead>
				<TableRow>
					{columns.map((column, index) => (
						<TableCell key={index} width={column.width}>
							<Box display={'flex'} gap={1}>
								<Typography color="common.white" variant="textLink">
									{column.headerName}
								</Typography>
								{column.sortable && (
									<TableHeaderSorter
										options={sortOptions.filter(
											(v) => v.fieldName === column.field
										)}
										currentState={
											activeSort === column.field
												? activeOrder
												: SORT_ORDER.DEFAULT
										}
										onStateChange={(sortOrder) => {
											onSortChange(column.field, sortOrder)
										}}
									/>
								)}
							</Box>
						</TableCell>
					))}
					<TableCell width={'15%'}></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{displayedData.map((row, index) => (
					<TableRow key={index}>
						{columns.map((column, colIndex) => {
							return (
								<TableCell key={colIndex}>
									<Typography
										variant={colIndex === 0 ? 'titleSmall' : 'bodySmall'}
										paddingTop={colIndex === 0 ? 0.5 : 0}
										color={'primary.main'}
										component={'div'}
										noWrap
									>
										{column.renderCell
											? column.renderCell(row)
											: row[column.field]}
									</Typography>
								</TableCell>
							)
						})}

						<TableCell>{rowActions ? rowActions(row) : null}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</StyledTable>
	)

	const renderMobileTable = () => (
		<>
			<Box mt={4}>
				<Typography
					color="primary.main"
					variant="textLink"
					component={'div'}
					mb={2}
				>
					{t('my-documents.sort-title')}
				</Typography>
				<RadioDropdown
					options={sortOptions}
					selectedValue={`${activeSort} ${activeOrder}`}
					onChange={(event, value) => {
						const parts = value.split(' ')
						if (parts.length > 1) {
							const [name, order] = parts
							onSortChange(name, order)
						} else {
							onSortChange(parts[0])
						}
					}}
				/>
			</Box>
			<StyledTable aria-live="polite">
				<TableBody>
					{displayedData.map((row, index) => (
						<TableRow key={index}>
							<TableCell width={'80%'}>
								{columns.map((column, colIndex) => {
									if (colIndex === 0) {
										return (
											<Box
												key={colIndex}
												display={'flex'}
												height={'60px'}
												alignItems={'center'}
											>
												<Typography
													variant="titleSmall"
													color={'primary.main'}
													// noWrap
													sx={{
														width: 'max-content',
														display: '-webkit-box',
														overflow: 'hidden',
														WebkitBoxOrient: 'vertical',
														WebkitLineClamp: 2,
														textOverflow: 'ellipsis',
														overflowWrap: 'anywhere',
													}}
												>
													{column.renderCell
														? column.renderCell(row)
														: row[column.field]}
												</Typography>
											</Box>
										)
									}

									return (
										<div key={colIndex}>
											<Typography variant="bodySmall" color={'primary.main'}>
												{column.renderCell
													? column.renderCell(row)
													: row[column.field]}
											</Typography>
										</div>
									)
								})}
							</TableCell>
							<TableCell align="right">
								{rowActions ? rowActions(row, true) : null}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</StyledTable>
		</>
	)

	return (
		<div>
			{/* Info */}
			<StyledBox>
				<Typography component={'h2'} color="primary.main" variant="titleMedium">
					{data.length} {t('my-documents.number-of-available-document')}
				</Typography>
				<Typography color="primary.main" variant="bodyMedium">
					{t('my-documents.numbers-of-viewing-document')} {viewingRange}
				</Typography>
			</StyledBox>

			{children}

			{/* Table */}
			{matches && renderDesktopTable()}
			{matches || renderMobileTable()}

			{/* Pagination */}
			<Box display={'flex'} justifyContent={'center'} mt={2}>
				<StyledPagination
					count={pageCount}
					page={page}
					onChange={handleChangePage}
				/>
			</Box>
		</div>
	)
}

TableViewer.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.string.isRequired,
			headerName: PropTypes.string.isRequired,
		})
	).isRequired,
	rowActions: PropTypes.func,
	activeSort: PropTypes.string.isRequired,
	activeOrder: PropTypes.oneOf([SORT_ORDER.ASC, SORT_ORDER.DSC]).isRequired,
	onSortChange: PropTypes.func,
	onPageNumberChange: PropTypes.func,
}

export default TableViewer
