const { Outlet } = require('react-router-dom')
import LoginBackgroundImage from 'assets/images/LoginErrorBg.png'
import LoginBackgroundImageMobile from 'assets/images/LoginErrorBgMobile.png'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Link, useLocation } from 'react-router-dom'
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useMediaQuery } from '@mui/material'
import TextIconLink from 'components/common/Links/TextIconLink'
import { ReactComponent as BackIcon } from 'assets/svgs/actions/back.svg'
import ContentWrapper from '../ContentWrapper'
import { ReactComponent as MattamyHomesIcon } from 'assets/svgs/mattamyHomesIcon.svg'
import { useTextualContext } from 'contexts/TextualContext'

const Footer = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.functional.footerGrey,
	textAlign: 'center',
	color: theme.palette.common.white,
	marginTop: '128px',
	'.copyrights': {
		padding: '40px 0px 40px 160px',
	},
	'.contacts': {
		padding: '40px 160px 40px 0px',
	},
	a: {
		color: theme.palette.common.white,
		textDecoration: 'none',
	},
	[theme.breakpoints.down('lg')]: {
		marginTop: 0,
		'.copyrights': {
			padding: '16px 32px 40px 32px',
		},
		'.contacts': {
			padding: '40px 32px 16px 32px',
		},
	},
}))

const ReturnToLoginNavBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.primary.lightBlue,
	paddingBlock: '32px',
}))

const LoginErrorPage = () => {
	const location = useLocation()
	const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	const { instance } = useMsal()
	const showReturnToLogin =
		location.pathname === '/error/privacyPolicy' ||
		location.pathname === '/error/accountSupport' ||
		location.pathname === '/error/termsAndConditions'

	const handleLoginRedirect = async () => {
		await instance.loginRedirect()
	}

	const { t } = useTextualContext()
	const logoText = t('navbar.logo-text')

	return (
		<>
			<UnauthenticatedTemplate>
				<Box
					sx={{
						backgroundImage:
							location.pathname === '/error' && !isSmallDevice
								? `url(${LoginBackgroundImage})`
								: location.pathname === '/error' && isSmallDevice
									? `url(${LoginBackgroundImageMobile})`
									: 'none',
						backgroundSize: {
							xs: 'contain',
							lg: 'cover',
						},
						backgroundRepeat: 'no-repeat',
					}}
				>
					<Link to="/" aria-label={logoText}>
						<MattamyHomesIcon
							width={!isSmallDevice ? '220px' : '157px'}
							height={!isSmallDevice ? '108px' : '78px'}
						/>
					</Link>
					{showReturnToLogin && (
						<ReturnToLoginNavBox>
							<ContentWrapper>
								<TextIconLink
									iconType={<BackIcon />}
									iconPosition="before"
									role="link"
									onClick={handleLoginRedirect}
									aria-label="Return to Login"
									variant="breadCrumb"
								>
									RETURN TO LOGIN
								</TextIconLink>
							</ContentWrapper>
						</ReturnToLoginNavBox>
					)}
					<Outlet />
					<Footer container item xs={12}>
						<Grid
							className="copyrights"
							item
							display="flex"
							alignItems="center"
							justifyContent={{
								xs: 'center',
								lg: 'flex-start',
							}}
							xs={12}
							sm={12}
							md={12}
							lg={8}
							order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
						>
							<Typography variant="linkFilter">
								&copy;2023 Mattamy Homes. Prices are subject to change. E & OE.
							</Typography>
						</Grid>
						<Grid
							className="contacts"
							item
							display="flex"
							alignItems="center"
							justifyContent={{
								xs: 'center',
								lg: 'flex-end',
							}}
							xs={12}
							sm={12}
							md={12}
							lg={4}
							order={{ xs: 1, sm: 1, md: 1, lg: 2 }}
						>
							<TextIconLink
								role="link"
								to="/error/accountSupport"
								aria-label="Go to Account Support"
								variant="inline-link-dark"
								analyticsid={'account-support'}
								analyticstargeturl="/error/accountSupport"
								analyticstext={`Need Help? View 'Account Support'`}
							>
								Need Help? View &quot;Account Support&quot;
							</TextIconLink>
						</Grid>
					</Footer>
				</Box>
			</UnauthenticatedTemplate>
		</>
	)
}

export default LoginErrorPage
