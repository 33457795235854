import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { useMsal } from '@azure/msal-react'
import { useTextualContext } from 'contexts/TextualContext'
import { useLookupContext } from 'contexts/LookupContext'

import {
	Box,
	Drawer,
	ClickAwayListener,
	useTheme,
	useMediaQuery,
} from '@mui/material'
import NavButton from 'components/common/Buttons/NavButton'
import IconButton from 'components/common/Buttons/IconButton'
import DropdownButton from 'components/common/Buttons/DropdownButton'
import { ReactComponent as MattamyHomesIcon } from 'assets/svgs/mattamyHomesIcon.svg'
import { ReactComponent as CloseIcon } from 'assets/svgs/actions/close.svg'
import { ReactComponent as MenuIcon } from 'assets/svgs/actions/menu.svg'
import Profile from './Profile'
import { getDisplayFullName } from 'utils/string.utils'

const NavigationBar = () => {
	const { t } = useTextualContext()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [profileOpen, setProfileOpen] = useState(false)
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('lg'))
	const navigate = useNavigate()
	const location = useLocation()
	const handleProfileToggle = () => {
		setProfileOpen((prev) => !prev)
	}

	const handleProfileClose = () => {
		setProfileOpen(false)
	}

	const { instance } = useMsal()
	const { firstName, lastName, email } = useLookupContext()
	const fullName = getDisplayFullName(firstName, lastName)

	const navigationHandler = (item) => {
		setDrawerOpen(false)
		navigate(item.route)
	}

	const handleDrawerToggle = () => {
		setDrawerOpen((prevState) => !prevState)
	}

	const handleLogout = async () => {
		const idToken = sessionStorage.getItem('idToken')

		sessionStorage.clear()
		instance.logoutRedirect({
			idTokenHint: idToken,
			onRedirectNavigate: () => {
				return true
			},
		})
	}

	const navItems = [
		{
			label: t('navbar.feature-1'),
			route: '/',
		},
		{
			label: t('navbar.feature-2'),
			route: '/myJourney',
		},
		{
			label: t('navbar.feature-3'),
			route: '/myDocuments',
		},
	]

	const myAccountText = t('navbar.my-account')
	const logOutButtonText = t('navbar.log-out-button')
	const logoText = t('navbar.logo-text')

	return (
		<>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				mx={1}
				sx={{
					position: 'sticky',
					width: '100%',
					top: '0',
					zIndex: '999',
					margin: '0',
					backgroundColor: 'common.white',
				}}
			>
				<Link to="/" aria-label={logoText}>
					<MattamyHomesIcon
						width={matches ? '220px' : '157px'}
						height={matches ? '108px' : '78px'}
					/>
				</Link>
				{matches ? (
					<>
						<Box display="flex">
							{navItems.map((item, index) => (
								<NavButton
									aria-current={location.pathname === item.route ? 'page' : ''}
									device="desktop"
									key={index}
									route={item.route}
									onClick={navigationHandler.bind(this, item)}
									analyticsid={item.label.replaceAll(' ', '-').toLowerCase()}
									analyticstargeturl={item.route}
									analyticstext={item.label}
								>
									{item.label}
								</NavButton>
							))}
						</Box>
						<Box component="span">
							<Box position="relative" mx={4}>
								<DropdownButton
									aria-expanded={profileOpen}
									onClick={handleProfileToggle}
									expanded={profileOpen}
									analyticsid={myAccountText.replaceAll(' ', '-').toLowerCase()}
									analyticstext={myAccountText}
								>
									{myAccountText}
								</DropdownButton>

								{profileOpen && (
									<ClickAwayListener onClickAway={handleProfileClose}>
										<div>
											<Profile
												handleLogout={handleLogout}
												fullName={fullName}
												email={email}
												buttonText={logOutButtonText}
												position="absolute"
												top="100%"
												right="0"
												mt={1}
											/>
										</div>
									</ClickAwayListener>
								)}
							</Box>
						</Box>
					</>
				) : (
					<Box mr={2}>
						<IconButton
							onClick={handleDrawerToggle}
							aria-label={'menu'}
							color="primary"
						>
							<MenuIcon />
						</IconButton>
					</Box>
				)}
			</Box>

			{/* mobile nav bar */}
			<Drawer
				anchor="right"
				open={matches ? false : drawerOpen}
				onClose={handleDrawerToggle}
				PaperProps={{
					style: {
						width: '100%',
					},
				}}
			>
				<Box role="presentation">
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
						mx={1}
					>
						<Box component="span">
							<MattamyHomesIcon width={'157px'} height={'78px'} />
						</Box>
						<Box mr={2}>
							<IconButton onClick={handleDrawerToggle} color={'secondary'}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
					<Box
						display="flex"
						sx={{ margin: 'auto 24px' }}
						flexDirection={'column'}
					>
						{navItems.map((item, index) => (
							<NavButton
								aria-current={location.pathname === item.route ? 'page' : ''}
								device="mobile"
								key={index}
								route={item.route}
								onClick={navigationHandler.bind(this, item)}
								analyticsid={item.label.replaceAll(' ', '-').toLowerCase()}
								analyticstargeturl={item.route}
								analyticstext={item.label}
							>
								{item.label}
							</NavButton>
						))}
					</Box>
					<Profile
						handleLogout={handleLogout}
						fullName={fullName}
						email={email}
						buttonText={logOutButtonText}
						position="fixed"
						bottom="0"
						m={3}
						width="fill-available"
					/>
				</Box>
			</Drawer>
		</>
	)
}

export default NavigationBar
