import React from 'react'
import PropTypes from 'prop-types'
import { Box as MuiBox, styled } from '@mui/material'

const StyledBox = styled(MuiBox)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 64,
	height: 64,
	img: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}))

const IconContainer = ({ src, alt, ...props }) => {
	return (
		<StyledBox {...props}>
			<img src={src} alt={alt} />
		</StyledBox>
	)
}

IconContainer.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
}

IconContainer.defaultProps = {
	src: undefined,
	alt: undefined,
}

export default IconContainer
