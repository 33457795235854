import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import CustomDialogs from 'components/common/CustomDialog'
import { useLookupContext } from 'contexts/LookupContext'
import LegalContentPage from 'pages/LegalContentPage/Index'
import { useContext, useEffect, useRef, useState } from 'react'
import Button from 'components/common/Buttons/Button'
import { useTextualContext } from 'contexts/TextualContext'
import { LookupDispatchContext } from 'contexts/LookupContext/LookupState'
import { useMsal } from '@azure/msal-react'
import userService from 'services/userService'
import { useAppContext } from 'contexts/AppContext'
import Alert from 'components/common/Alert'

const LegalContainer = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '4px',
			height: '10px',
		},
		'&::-webkit-scrollbar-track': {
			background: theme.palette.primary.lightBlue,
			borderRadius: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.main,
			borderRadius: '4px',
		},
	},
}))

const AlertBox = styled(Alert)(() => ({
	'&.MuiAlert-standard': {
		padding: '32px 24px',
		svg: {
			height: '48px',
			width: '48px',
		},
	},
}))

const LegalContentDialog = () => {
	const [open, setOpen] = useState(false)
	const { t } = useTextualContext()
	const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	let { legalContentData, uniqueUserId, email } = useLookupContext()
	const { setConsetGiven } = useAppContext()
	const dispatch = useContext(LookupDispatchContext)
	const [showTCcontent, setShowTCContent] = useState(false)
	const [showPPcontent, setShowPPContent] = useState(false)
	const isTCdateUpdated =
		new Date(t('legal-dialog.tc-timestamp')) >
		new Date(legalContentData.tcTimestamp)
	const isPPdateUpdated =
		new Date(t('legal-dialog.pp-timestamp')) >
		new Date(legalContentData.ppTimestamp)
	const isTutorialViewed = legalContentData.viewTutorial.toLowerCase() === 'yes'
	const [isTCSubmitted, setIsTCSubmitted] = useState(
		(legalContentData.termsAndCondition.toLowerCase() === 'no'
			? false
			: true) && !isTCdateUpdated
	)
	const [updateConsent, setUpdateConsent] = useState(false)
	const dialogRef = useRef()
	const { instance } = useMsal()

	const [isPPSubmitted, setIsPPSubmitted] = useState(
		(legalContentData.privacyPolicy.toLowerCase() === 'no' ? false : true) &&
			!isPPdateUpdated
	)

	const [totalCount, setTotalCount] = useState(false)
	const [alertDetails, setAlertDetails] = useState(null)

	//eslint-disable-next-line
	const [stepCount, setStepCount] = useState(0)

	const handleLogout = async () => {
		await updateConsentDB()
		const idToken = sessionStorage.getItem('idToken')
		sessionStorage.clear()
		instance.logoutRedirect({
			idTokenHint: idToken,
			onRedirectNavigate: () => {
				return true
			},
		})
	}

	useEffect(() => {
		if (isTCSubmitted && isPPSubmitted && isTutorialViewed) {
			setConsetGiven(true)
		} else {
			setConsetGiven(false)
		}
		setTotalCount(!isTCSubmitted && !isPPSubmitted ? 2 : 1)
		if (isTCdateUpdated && isPPdateUpdated) {
			setAlertDetails(t('legal-dialog.tc-pp-updated-alert'))
		} else if (isTCdateUpdated && !isPPdateUpdated) {
			setAlertDetails(t('legal-dialog.tc-updated-alert'))
		} else if (isPPdateUpdated && !isTCdateUpdated) {
			setAlertDetails(t('legal-dialog.pp-updated-alert'))
		} else if (!isTCSubmitted && !isPPSubmitted) {
			setAlertDetails(t('legal-dialog.tc-new-alert'))
		} else if (isTCSubmitted && !isPPSubmitted) {
			setAlertDetails(t('legal-dialog.pp-new-alert'))
		}
		setStepCount(1)
	}, [])

	const updateConsentDB = async () => {
		const reqData = {
			uniqueUserId,
			// add email attribute and encode to base64
			email: window.btoa(email),
			privacyPolicy: isPPSubmitted ? 'YES' : 'NO',
			termsAndCondition: isTCSubmitted ? 'YES' : 'NO',
			viewTutorial: isTutorialViewed ? 'YES' : 'NO',
		}
		await userService.updateConsent(reqData)
		setConsetGiven(true)
	}

	useEffect(() => {
		if (!isTCSubmitted) {
			setShowTCContent(true)
			setShowPPContent(false)
			setOpen(true)
		} else if (!isPPSubmitted) {
			setShowPPContent(true)
			setShowTCContent(false)
			setOpen(true)
		} else if (!isTutorialViewed) {
			dispatch({ type: 'SHOW_TUTORIAL', showTutorial: true })
			setOpen(false)
		} else {
			setOpen(false)
		}
		if (updateConsent) {
			updateConsentDB()
		}
		setTimeout(() => {
			if (dialogRef.current) {
				dialogRef.current.scrollTop = 0
			}
		}, 500)
	}, [isTCSubmitted, isPPSubmitted, updateConsent])

	const handleTCsubmitted = () => {
		setIsTCSubmitted(true)
		if (!isPPdateUpdated) {
			setAlertDetails(t('legal-dialog.pp-new-alert'))
		} else {
			setAlertDetails(t('legal-dialog.pp-updated-alert'))
		}
		setStepCount(2)
		dispatch({ type: 'UPDATE_LEGAL_CONTENT', termsAndCondition: 'YES' })
		if (isPPSubmitted && isTutorialViewed) setUpdateConsent(true)
	}

	const handlePPsubmitted = () => {
		setIsPPSubmitted(true)
		dispatch({ type: 'UPDATE_LEGAL_CONTENT', privacyPolicy: 'YES' })
		if (isTCSubmitted && isTutorialViewed) setUpdateConsent(true)
	}

	return (
		<CustomDialogs
			open={open}
			setOpen={setOpen}
			closeable={0}
			maxWidth={fullScreen ? false : 'lg'}
		>
			{alertDetails && (
				<>
					<Box
						component="div"
						sx={{
							marginBottom: '40px',
							marginTop: { xs: '-56px', lg: '-40px' },
						}}
					>
						<AlertBox
							type={alertDetails.type}
							title={alertDetails.title}
							message={alertDetails.message}
						/>
					</Box>

					<Typography
						marginLeft={{ lg: '32px' }}
						fontWeight={600}
						fontSize={'14px'}
						color={'secondary.main'}
						variant="bodySmall"
					>
						{`${t('legal-dialog.step-count.start')} ${stepCount} ${t(
							'legal-dialog.step-count.end'
						)} ${totalCount}`}
					</Typography>
				</>
			)}
			<LegalContainer ref={dialogRef}>
				{showTCcontent && (
					<LegalContentPage
						redirectLink={'termsAndConditions'}
						isContentUpdated={isTCdateUpdated}
					/>
				)}
				{showPPcontent && (
					<LegalContentPage
						redirectLink={'privacyPolicy'}
						isContentUpdated={isPPdateUpdated}
					/>
				)}
				<Grid
					container
					direction="row"
					justifyContent="center"
					spacing={{ xs: 2, lg: 2 }}
					columns={12}
				>
					<Grid item xs={12} lg={4}>
						<Button
							variant="secondary"
							width="100%"
							onClick={handleLogout}
							data-testid="login-redirect"
							aria-label={t('legal-dialog.login-redirect-button')}
							analyticsid={t('legal-dialog.login-redirect-button').replaceAll(
								' ',
								'-'
							)}
							analyticstext={t('legal-dialog.login-redirect-button')}
						>
							{t('legal-dialog.login-redirect-button')}
						</Button>
					</Grid>
					{showTCcontent && (
						<>
							{!isPPSubmitted && (
								<Grid item xs={12} lg={6}>
									<Button
										variant="secondary-light"
										width="100%"
										onClick={handleTCsubmitted}
										data-testid="handle-tc-submitted"
										aria-label={t('legal-dialog.accept-privacy-policy-button')}
										analyticsid={t(
											'legal-dialog.accept-privacy-policy-button'
										).replaceAll(' ', '-')}
										analyticstext={t(
											'legal-dialog.accept-privacy-policy-button'
										)}
									>
										{t('legal-dialog.accept-privacy-policy-button')}
									</Button>
								</Grid>
							)}
							{isPPSubmitted && (
								<Grid item xs={12} lg={6}>
									<Button
										variant="secondary-light"
										width="100%"
										data-testid="handle-tc-submitted"
										onClick={handleTCsubmitted}
										aria-label={t(
											'legal-dialog.accept-continue-to-portal-button'
										)}
										analyticsid={t(
											'legal-dialog.accept-continue-to-portal-button'
										).replaceAll(' ', '-')}
										analyticstext={t(
											'legal-dialog.accept-continue-to-portal-button'
										)}
									>
										{t('legal-dialog.accept-continue-to-portal-button')}
									</Button>
								</Grid>
							)}
						</>
					)}
					{showPPcontent && (
						<Grid item xs={12} lg={6}>
							<Button
								variant="secondary-light"
								width="100%"
								onClick={handlePPsubmitted}
								data-testid="handle-pp-submitted"
								aria-label={t('legal-dialog.accept-continue-to-portal-button')}
								analyticsid={t(
									'legal-dialog.accept-continue-to-portal-button'
								).replaceAll(' ', '-')}
								analyticstext={t(
									'legal-dialog.accept-continue-to-portal-button'
								)}
							>
								{t('legal-dialog.accept-continue-to-portal-button')}
							</Button>
						</Grid>
					)}
				</Grid>
			</LegalContainer>
		</CustomDialogs>
	)
}

export default LegalContentDialog
