import React from 'react'
import { IconButton as MuiIconButton } from '@mui/material'
import { styled } from '@mui/system'

const StyledIconButton = styled(MuiIconButton)(({ theme }) => {
	return {
		'&:focus-visible': {
			boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
			borderRadius: '2px',
		},
		'&:hover': {
			transform: 'scale(1.1)',
		},
		'& path': {
			fill: 'currentColor',
		},
	}
})

const IconButton = ({ ...props }) => (
	<StyledIconButton disableRipple {...props} />
)

export default IconButton
