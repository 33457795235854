import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/system'
import {
	Box,
	Card as MuiCard,
	CardContent,
	CardMedia,
	Typography,
} from '@mui/material'

import { useTextualContext } from 'contexts/TextualContext'
import AlertCard from 'components/common/AlertCard'

const NotAvailableAlertBox = styled(Box)(({ theme }) => ({
	height: 388,
	[theme.breakpoints.up('lg')]: {
		height: 539,
	},
}))

const StyledCard = styled(MuiCard)({
	height: '100%',
	borderRadius: 8,
})

const YourCommunity = ({ division, name, description, image }) => {
	const { t } = useTextualContext()

	if (division === null || name === null) {
		return (
			<NotAvailableAlertBox data-testid="no-data-alert">
				<AlertCard text={t('my-home.error-my-community-text')} />
			</NotAvailableAlertBox>
		)
	}

	return (
		<StyledCard>
			<CardMedia
				component="img"
				height="296"
				image={image?.src}
				alt={image?.alt}
			/>
			<CardContent>
				<Box p={2}>
					<Typography
						variant="linkFilter"
						color={'secondary.main'}
						component={'div'}
						mb={0.5}
					>
						{division}
					</Typography>
					<Typography
						variant="labelCard"
						color={'primary.main'}
						component={'div'}
						fontSize={'26px'}
						lineHeight={'39px'}
						mb={1}
					>
						{name}
					</Typography>
					<Typography
						variant="bodySmall"
						color={'primary.main'}
						component={'div'}
					>
						{description}
					</Typography>
				</Box>
			</CardContent>
		</StyledCard>
	)
}

YourCommunity.propTypes = {
	division: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.shape({
		src: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}),
}

YourCommunity.defaultProps = {
	division: null,
	name: null,
	description: null,
	image: null,
}

export default YourCommunity
