import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { ReactComponent as AddIcon } from 'assets/svgs/actions/add.svg'
import { ReactComponent as RemoveIcon } from 'assets/svgs/actions/remove.svg'
import { useMediaQuery } from '@mui/material'
import TextIconLink from '../Links/TextIconLink'
import { useState } from 'react'
import parse from 'html-react-parser'

const Accordion = styled((props) => (
	<MuiAccordion
		TransitionProps={{ unmountOnExit: true }}
		disableGutters
		elevation={0}
		{...props}
	/>
))(() => ({
	marginBottom: '32px',
	outline: 'none',
	'&:before': {
		height: 0,
	},
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme, ...props }) => ({
		padding: theme.spacing('40px'),
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.lightBlue,
		'&:focus': {
			backgroundColor: theme.palette.primary.lightBlue,
			borderTop: `2px solid ${theme.palette.primary.main}`,
			borderLeft: `2px solid ${theme.palette.primary.main}`,
			borderRight: `2px solid ${theme.palette.primary.main}`,
			borderBottom: !props.expanded
				? `2px solid ${theme.palette.primary.main}`
				: 'none',
		},
		'& .MuiAccordionSummary-content': {
			margin: theme.spacing(0),
		},
		'& .mattamy-icon rect': {
			fill: theme.palette.secondary.main,
		},
		'& .mattamy-icon': {
			width: '64px',
			height: '64px',
		},
		[theme.breakpoints.down('lg')]: {
			padding: theme.spacing('24px'),
			'& .mattamy-icon': {
				width: '32px',
				height: '32px',
			},
		},
	})
)

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	backgroundColor: theme.palette.primary.lightBlue,
	padding: theme.spacing('0 40px 64px 40px'),
	color: theme.palette.primary.darkGrey,
	'&.focused-class': {
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		borderLeft: `2px solid ${theme.palette.primary.main}`,
		borderRight: `2px solid ${theme.palette.primary.main}`,
	},
	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing('0 24px 40px 24px'),
	},
}))

const ControlledAccordion = ({ accordianData }) => {
	const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	accordianData.map((item, i) =>
		i !== 0 ? (item.expanded = false) : (item.expanded = true)
	)
	const [data, setData] = useState(accordianData)

	const [focusedIndex, setFocusedIndex] = useState(null)

	const handleFocus = (index) => {
		setFocusedIndex(index)
	}

	const handleBlur = () => {
		setFocusedIndex(null)
	}

	const handleChange = (panel) => (event, newExpanded) => {
		const updatedAccordian = { ...data[panel], expanded: newExpanded }
		const updatedData = [
			...data.slice(0, panel),
			updatedAccordian,
			...data.slice(panel + 1),
		]
		setData(updatedData)
	}
	return (
		<div>
			{data &&
				data.map((item, key) => {
					return (
						<Accordion
							expanded={item.expanded}
							key={key}
							onChange={handleChange(key)}
						>
							<AccordionSummary
								onFocus={() => handleFocus(key)}
								onBlur={handleBlur}
								style={{ borderRadius: !item.expanded ? '8px' : '8px 8px 0 0' }}
								aria-controls={'panel' + key + 'content'}
								id={'panel' + key + 'header'}
								expandIcon={
									item.expanded ? (
										<RemoveIcon className="mattamy-icon" />
									) : (
										<AddIcon className="mattamy-icon" />
									)
								}
								expanded={item.expanded ? 1 : 0}
							>
								<Typography
									component={'h2'}
									variant={!isMobileScreen ? 'titleMedium' : 'titleSmall'}
								>
									{item.question}
								</Typography>
							</AccordionSummary>
							<AccordionDetails
								data-testid="expanded-accordian"
								className={focusedIndex === key ? 'focused-class' : ''}
								style={{
									borderRadius: !item.expanded ? '0' : '0 0 8px 8px',
								}}
								id={'panel' + key + 'content'}
								expanded={item.expanded ? 1 : 0}
							>
								<Typography variant="bodySmall">
									{item.answer.text &&
										item.answer.text.length > 0 &&
										item.answer.text.map((t, i) => {
											if (t.type === 'text') {
												{
													return <span key={i}>{t.value}</span>
												}
											} else if (t.type === 'link') {
												return (
													<TextIconLink
														style={{
															textDecoration: 'underline',
															textUnderlinePosition: 'under',
														}}
														key={i}
														role="link"
														aria-label={t['aria-label']}
														variant="body-link"
														to={t.to}
														analyticsid={
															t.value.replaceAll(' ', '-').toLowerCase() +
															'-accordian'
														}
														analyticstargeturl={t.to}
														analyticstext={t.value}
													>
														{t.value}
													</TextIconLink>
												)
											}
										})}
									{item.answer.list && item.answer.list.value.length > 0 && (
										<ol
											style={{
												margin: 0,
												listStyleType: item.answer.list.type,
											}}
										>
											{item.answer.list.value.map((ans, key) => (
												<li key={key}>{ans}</li>
											))}
										</ol>
									)}
									{item.answer.richtext &&
										item.answer.richtext.value &&
										parse(item.answer.richtext.value)}
								</Typography>
							</AccordionDetails>
						</Accordion>
					)
				})}
		</div>
	)
}

export default ControlledAccordion
