import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import HomePage from 'pages/HomePage'
import MyJourneyPage from 'pages/MyJourney'
import LoginErrorPage from 'components/layout/LoginErrorPage'
import AccountSupportPage from 'pages/LoginAccountSupport'
import ErrorPage from 'pages/LoginError'
import { Content } from 'components/layout/Content'
import MyDocuments from 'pages/MyDocuments'
import { useEffect } from 'react'
import LegalContentPage from 'pages/LegalContentPage/Index'
import Contactus from 'pages/Contactus'
import InformationNotFound from 'pages/InformationNotFound'

const TitleWrapper = ({ title, children }) => {
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{title}</title>
				</Helmet>
				{children}
			</HelmetProvider>
		</>
	)
}

const AppRoutes = () => {
	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])
	return (
		<>
			<Routes>
				<Route path="/:authCode?" element={<Content />}>
					<Route
						index
						element={
							<TitleWrapper title="My Home - Mattamy Customer Portal">
								<HomePage />
							</TitleWrapper>
						}
					/>
					<Route
						path="myJourney"
						element={
							<TitleWrapper title="My Journey - Mattamy Customer Portal">
								<MyJourneyPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="myDocuments"
						element={
							<TitleWrapper title="My Documents - Mattamy Customer Portal">
								<MyDocuments />
							</TitleWrapper>
						}
					/>
					<Route
						path="FAQ"
						element={
							<TitleWrapper title="FAQs - Mattamy Customer Portal">
								<AccountSupportPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="contactus"
						element={
							<TitleWrapper title="Contact Us - Mattamy Customer Portal">
								<Contactus />
							</TitleWrapper>
						}
					/>
					<Route
						path="accessibility"
						element={
							<TitleWrapper title="Accessibility - Mattamy Customer Portal">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="privacyPolicy"
						element={
							<TitleWrapper title="Privacy Policy - Mattamy Customer Portals">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="termsAndConditions"
						element={
							<TitleWrapper title="Terms & Conditions - Mattamy Customer Portal">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
				</Route>
				<Route path="error" element={<LoginErrorPage />}>
					<Route
						index
						element={
							<TitleWrapper title="Login Error - Mattamy Customer Portal">
								<ErrorPage />
							</TitleWrapper>
						}
					></Route>
					<Route
						path="accountSupport"
						element={
							<TitleWrapper title="Login Support - Mattamy Customer Portal">
								<AccountSupportPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="notFound"
						element={
							<TitleWrapper title="Login Error - Mattamy Customer Portal">
								<InformationNotFound />
							</TitleWrapper>
						}
					/>
					<Route
						path="privacyPolicy"
						element={
							<TitleWrapper title="Privacy Policy - Mattamy Customer Portal">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="termsAndConditions"
						element={
							<TitleWrapper title="Terms & Conditions - Mattamy Customer Portal">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
					<Route
						path="accessibility"
						element={
							<TitleWrapper title="Accessibility - Mattamy Customer Portal">
								<LegalContentPage />
							</TitleWrapper>
						}
					/>
				</Route>
				<Route path="*" element={<Navigate to={'/'} replace />} />
			</Routes>
		</>
	)
}

export default AppRoutes
