import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '../Buttons/IconButton'
import { ReactComponent as CloseIcon } from 'assets/svgs/actions/close.svg'
import PropTypes from 'prop-types'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: '12px',
		padding: '64px 24px',
		// overflow: 'auto',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},

	[theme.breakpoints.down('lg')]: {
		'& .MuiPaper-root': {
			borderRadius: 0,
			padding: '80px 24px 64px 24px',
		},
	},
}))

const CustomDialogs = ({
	open,
	setOpen,
	children,
	closeable = 1,
	...props
}) => {
	const handleClose = (event, reason) => {
		if (
			!closeable &&
			((reason && reason === 'backdropClick') || reason === 'escapeKeyDown')
		)
			return
		setOpen(false)
	}
	const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	return (
		<React.Fragment>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				fullScreen={fullScreen}
				maxWidth={fullScreen ? false : 'auto'}
				{...props}
			>
				{closeable === 1 && (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						color="primary"
						sx={{
							position: 'absolute',
							right: 24,
							top: 24,
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
				{children}
			</BootstrapDialog>
		</React.Fragment>
	)
}

CustomDialogs.propTypes = {
	open: PropTypes.bool.isRequired,
	closeable: PropTypes.number,
	setOpen: PropTypes.func,
}

export default CustomDialogs
