import React from 'react'
import { useMsal } from '@azure/msal-react'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

import { useTextualContext } from 'contexts/TextualContext'
import Button from 'components/common/Buttons/Button'
import { ReactComponent as NotFoundIcon } from 'assets/svgs/404.svg'
import ErrorContentWrapper from 'components/layout/Content/ErrorContentWrapper'
import { useNavigate } from 'react-router-dom'

const StyledContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '64px 24px',
	gap: '40px',

	borderRadius: '12px',
	color: theme.palette.common.white,
	backgroundColor: theme.palette.primary.main,

	[theme.breakpoints.up('lg')]: {
		gap: '64px',
		padding: '96px 0px',

		maxWidth: '1200px',
		// minHeight: '100vh', // Full height

		svg: {
			width: '272px',
			height: '272px',
		},
	},
}))

const StyledBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '8px',

	button: {
		width: '100%',
	},

	[theme.breakpoints.up('lg')]: {
		maxWidth: '750px',

		button: {
			width: 'unset',
		},
	},
}))

const InformationNotFound = () => {
	const { t } = useTextualContext()
	const { instance } = useMsal()
	const navigate = useNavigate()

	const titleText = t('information-not-found.headline')
	const descriptionText = t('information-not-found.description')
	const secondaryButtonText = t('information-not-found.button-secondary')
	const primaryButtonText = t('information-not-found.button-primary')

	const handleLoginRedirect = async () => {
		await instance.loginRedirect()
	}

	return (
		<ErrorContentWrapper>
			<StyledContainer>
				<NotFoundIcon />
				<StyledBox>
					<Typography variant="titleMedium" component="h1" noWrap>
						{titleText}
					</Typography>
					<Typography variant="bodyMedium" component="p" textAlign={'center'}>
						{descriptionText}
					</Typography>

					<Box display={'flex'} gap={2} flexWrap={'wrap'} mt={3}>
						<Button
							variant="primary-dark"
							role="link"
							onClick={handleLoginRedirect}
						>
							{primaryButtonText}
						</Button>
						<Button
							role="link"
							variant="secondary-dark"
							onClick={() => {
								navigate('/error/accountSupport')
							}}
						>
							{secondaryButtonText}
						</Button>
					</Box>
				</StyledBox>
			</StyledContainer>
		</ErrorContentWrapper>
	)
}

export default InformationNotFound
