import { ReactComponent as WarningIcon } from 'assets/svgs/actions/circleWarning.svg'
import { ReactComponent as CloseIcon } from 'assets/svgs/actions/close.svg'
import MuiCard from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { useTextualContext } from 'contexts/TextualContext'
import { useMsal } from '@azure/msal-react'
import IconButton from 'components/common/Buttons/IconButton'
import { HTMLContainsLink } from 'utils/textDecorations'

const Card = styled(MuiCard)(({ theme }) => ({
	padding: theme.spacing('72px 80px'),
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.common.white,
	textAlign: 'center',
	borderRadius: '12px',
	marginBotton: '128px',
	'.error-message': {
		position: 'relative',
		backgroundColor: theme.palette.functional.urgency0_05,
		color: theme.palette.primary.darkGrey,
		padding: '16px 64px 16px 56px',
		textAlign: 'left',
		margin: '144px 0',
		borderRadius: '8px',
		border: '2px solid ' + theme.palette.functional.urgency,
	},
	'.error-message .link': {
		color: theme.palette.primary.darkGrey,
		textDecoration: 'underline',
	},
	'.error-message .icon': {
		position: 'absolute',
		width: '24px',
		top: '16px',
		'&.error-icon path': {
			fill: theme.palette.functional.urgency,
		},
	},
	'.error-message .close-icon': {
		position: 'absolute',
		top: '8px',
		right: '8px',
	},
	'.bottom-content': {
		a: {
			cursor: 'pointer',
			color: theme.palette.common.white,
			textDecoration: 'underline',
			textUnderlineOffset: '2px',
		},
		'.register-content': {
			marginTop: '40px',
		},
	},
	[theme.breakpoints.down('lg')]: {
		'.error-message': {
			margin: '132px 0',
		},
		borderRadius: 0,
		padding: theme.spacing('64px 24px'),
		marginTop: '80%',
	},
	[theme.breakpoints.down('md')]: {
		marginTop: '70%',
	},
	[theme.breakpoints.down('sm')]: {
		marginTop: '65%',
	},
}))

const ErrorPage = () => {
	const { t } = useTextualContext()
	const { instance } = useMsal()
	const handleLoginRedirect = async () => {
		await instance.loginRedirect()
	}
	return (
		<Grid container>
			<Grid item xs></Grid>
			<Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
				<Card variant="outlined">
					<div
						id="header"
						role="heading"
						aria-label="Mattamy Login"
						aria-level="1"
					>
						<Typography variant="titleLarge">
							{t('login-error.heading')}
						</Typography>
					</div>
					<div>
						<Typography variant="bodyMedium">
							{t('login-error.sub0heading')}
						</Typography>
					</div>
					<div className="error-message">
						<WarningIcon
							className="icon error-icon"
							style={{ left: '16px' }}
							alt="Error Icon"
						/>
						<IconButton
							aria-label="close"
							color="inherit"
							onClick={handleLoginRedirect}
							className="close-icon"
							alt="Close Icon"
						>
							<CloseIcon />
						</IconButton>
						{HTMLContainsLink(t('login-error.error-message'))}
					</div>
					<div className="bottom-content">
						<Typography variant="bodySmall">{t('login-error.note')}</Typography>
					</div>
				</Card>
			</Grid>
			<Grid item xs></Grid>
		</Grid>
	)
}

export default ErrorPage
