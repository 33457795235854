import React from 'react'
import { styled } from '@mui/system'
import { Box as MuiBox } from '@mui/material'

const StyledBox = styled(MuiBox)(() => ({
	padding: '56.25% 0 0 0',
	position: 'relative',
	iframe: {
		maxWidth: '100%',
		// maxWidth: '546px',
		// maxHeight: '308px',
		// width: '100%',
		// height: '308px',
		borderRadius: '12px',
		overflow: 'hidden',
		display: 'block',
		margin: '0px',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	},
}))

const IFrameContainer = ({ src, text }) => {
	return (
		<StyledBox>
			<iframe
				title={text}
				src={src}
				width="640"
				height="360"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				allowFullScreen
			></iframe>
		</StyledBox>
	)
}

export default IFrameContainer
