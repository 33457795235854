import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	Box as MuiBox,
	Card as MuiCard,
	Typography,
	styled,
	useMediaQuery,
} from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/svgs/actions/download.svg'
import { formatToReadableDate } from 'utils/date.utils'
import { stripFileExtension } from 'utils/string.utils'
import { useTextualContext } from 'contexts/TextualContext'
import DocumentService from 'services/documentService'
import { useAppContext } from 'contexts/AppContext'
import AlertCard from 'components/common/AlertCard'
import TextIconLink from 'components/common/Links/TextIconLink'
import { useLookupContext } from 'contexts/LookupContext'

const StyledBox = styled(MuiBox)({
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
	maxHeight: 344,
})

const NotAvailableAlertBox = styled(MuiBox)(({ theme }) => ({
	height: 388,
	[theme.breakpoints.up('lg')]: {
		height: 344,
	},
}))

const NotAvailableLowerAlertBox = styled(MuiBox)(() => ({
	display: 'flex',
	height: 224,
	'.MuiPaper-root': {
		paddingInline: 24,
	},
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
	display: 'flex',
	// justifyContent: 'space-between',
	alignItems: 'center',

	borderRadius: 8,
	padding: '12px 16px 12px 16px',
	gap: 8,
	backgroundColor: theme.palette.primary.lightBlue,
	boxShadow: 'none',
	height: 70,
	path: {
		fill: theme.palette.secondary.main,
	},
	[theme.breakpoints.up('lg')]: {
		minHeight: 104,
		padding: '24px 16px 24px 24px',
	},
}))

const UploadedDocuments = ({ documents }) => {
	const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	const { t } = useTextualContext()
	const { showPageAlert: showAlert, hidePageAlert: hideAlert } = useAppContext()
	const { email: userEmail } = useLookupContext()

	useEffect(() => {
		return () => {
			hideAlert()
		}
	}, [])

	if (documents === undefined) {
		return (
			<NotAvailableAlertBox data-testid="undefined-docs">
				<AlertCard text={t('my-home.error-documents-text')} />
			</NotAvailableAlertBox>
		)
	}

	if (documents.length === 0) {
		return (
			<NotAvailableAlertBox data-testid="no-available-documents">
				<AlertCard text={t('my-home.no-available-documents')} />
			</NotAvailableAlertBox>
		)
	}

	const handleDownloadDocument = async ({
		serverRelativeUrl,
		documentName,
	}) => {
		try {
			const { success } = await DocumentService.downloadDocument(
				serverRelativeUrl,
				documentName,
				userEmail
			)

			if (success) {
				showAlert(
					'success',
					t('my-documents.download-success-alert-title'),
					t('my-documents.download-success-alert-message')
				)
			} else {
				showAlert(
					'error',
					t('my-documents.download-failed-alert-title'),
					t('my-documents.download-failed-alert-message')
				)
			}
		} catch (error) {
			showAlert(
				'error',
				t('my-documents.download-failed-alert-title'),
				t('my-documents.download-failed-alert-message')
			)
		}
	}

	return (
		<StyledBox>
			{documents.length > 0 &&
				documents.map((item, index) => (
					<StyledCard key={index}>
						<Grid container>
							<Grid xs={10.5} item>
								<div>
									<Typography
										data-testid="doc-name"
										style={{
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
										}}
										variant="titleSmall"
										color={'primary.main'}
										component={'div'}
										paddingTop={0.5}
									>
										{stripFileExtension(item.documentName)}
									</Typography>
									<Typography
										data-testid="doc-category-uploadDate"
										variant={isMobileScreen ? 'bodyXSmall' : 'bodySmall'}
										color={'primary.main'}
										component={'div'}
										noWrap
									>
										{item.category} | {formatToReadableDate(item.uploadDate)}
									</Typography>
								</div>
							</Grid>
							<Grid
								display="flex"
								justifyContent={'center'}
								alignItems={'center'}
								xs={1.5}
								item
							>
								<TextIconLink
									data-testid="doc-download-btn"
									iconType={<DownloadIcon />}
									iconPosition={'before'}
									onClick={() => handleDownloadDocument(item)}
									aria-label={'Download ' + item.documentName}
									analyticsid={item.documentName.replaceAll(' ', '-')}
									analyticstargeturl={item.serverRelativeUrl}
									analyticstext={'Download'}
								>
									<span style={{ display: 'none' }}>Download</span>
								</TextIconLink>
							</Grid>
						</Grid>
					</StyledCard>
				))}
			{!isMobileScreen && documents.length === 1 && (
				<NotAvailableLowerAlertBox data-testid="one-doc-present">
					<AlertCard text={t('my-home.one-available-documents')} />
				</NotAvailableLowerAlertBox>
			)}
			{!isMobileScreen && documents.length === 2 && (
				<NotAvailableLowerAlertBox data-testid="two-doc-present">
					<AlertCard text={t('my-home.two-available-documents')} />
				</NotAvailableLowerAlertBox>
			)}
		</StyledBox>
	)
}

UploadedDocuments.propTypes = {
	documents: PropTypes.arrayOf(
		PropTypes.shape({
			documentName: PropTypes.string.isRequired,
			category: PropTypes.string.isRequired,
			uploadDate: PropTypes.string.isRequired,
		})
	),
}

export default UploadedDocuments
