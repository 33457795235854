import { Box, Typography } from '@mui/material'
import Button from 'components/common/Buttons/Button'
import { useNavigate } from 'react-router'

const NeedHelp = ({ content }) => {
	const navigate = useNavigate()
	const FAQRedirectHandler = () => {
		navigate(content['btn-redirect'])
	}
	return (
		<Box
			sx={{
				p: { xs: '64px 32px', lg: '64px 345px' },
				textAlign: 'center',
				bgcolor: 'primary.lightBlue',
			}}
		>
			<Typography component={'h2'} color="primary.main" variant="titleMedium">
				{content.heading}
			</Typography>
			<br />
			<Typography color="primary.main" variant="bodyMedium">
				{content.description}
			</Typography>
			<br />
			<Button
				onClick={FAQRedirectHandler}
				style={{ marginTop: '32px' }}
				variant={'primary'}
			>
				{content['btn-text']}
			</Button>
		</Box>
	)
}

export default NeedHelp
