/* eslint-disable array-callback-return */
// import { format, isValid } from 'date-fns'

/**
 * Converts an ISO date string to a formatted date string.
 * @param {string} isoDate - The ISO date string (e.g., "2023-10-30T19:45:22Z").
 * @returns {string} - The formatted date string (e.g., "October 30, 2023").
 */
export function formatToReadableDate(isoDate) {
	const date = new Date(isoDate)

	const options = { year: 'numeric', month: 'long', day: 'numeric' }
	return new Intl.DateTimeFormat('en-US', options).format(date)
}

// Example usage:
// const readableDate = formatToReadableDate('2023-10-30T19:45:22Z');
// console.log(readableDate); // Outputs: October 30, 2023

/**
 * 	A fucntion to format a date range
 *
 * @function
 *
 * @param {array} dateRange - An array of dates with a startDate and endDate
 * @param {string} divider - A string used to divide/seperate dates. Example: to - ,
 *
 * @returns a formated string ex. `2020-03-11 to 2022-01-01`
 */

// export const formatDateRange = (dateRange, divider = '-') => {
// 	let formattedDateRange

// 	dateRange?.map((date) => {
// 		if (isValid(date.startDate) && isValid(date.endDate)) {
// 			formattedDateRange = `${format(
// 				date.startDate,
// 				'yyyy-MM-dd'
// 			)} ${divider} ${format(date.endDate, 'yyyy-MM-dd')}`
// 		}
// 	})

// 	return formattedDateRange
// }

// export const validateDate = (value) => {
// 	if (!isValid(new Date(value))) {
// 		return false
// 	}

// 	return true
// }
