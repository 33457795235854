import { postRequest, putRequest } from 'utils/axiosClient'

const userLookup = async (requestData) => {
	const response = await postRequest('api/lookup', requestData)
	return response.data
}

const createToken = (requestData) => {
	const response = postRequest('authenticate/createToken', requestData)
	return response
}

const updateConsent = (requestData) => {
	const response = putRequest('api/consent', requestData)
	return response
}

export default { userLookup, createToken, updateConsent }
