import React from 'react'
import PropTypes from 'prop-types'
import { Box as MuiBox, styled } from '@mui/material'

const StyledBox = styled(MuiBox)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	height: 300,
	borderRadius: '12px',
	overflow: 'hidden',
	width: '100%',
	maxWidth: 'fit-content',
	[theme.breakpoints.up('lg')]: {
		maxWidth: 547,
	},
}))

const ImgContainer = ({ src, alt, ...props }) => {
	return (
		<StyledBox {...props}>
			<img src={src} alt={alt} />
		</StyledBox>
	)
}

ImgContainer.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
}

ImgContainer.defaultProps = {
	src: undefined,
	alt: undefined,
}

export default ImgContainer
