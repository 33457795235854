import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Box,
	Drawer as MuiDrawer,
	Typography,
	styled,
	useMediaQuery,
} from '@mui/material'
import parse from 'html-react-parser'

import { ReactComponent as CloseIcon } from 'assets/svgs/actions/close.svg'
import Button from 'components/common/Buttons/Button'
import IconButton from 'components/common/Buttons/IconButton'
import StatusTag from 'components/common/Chip/StatusTag'
import ImgContainer from 'components/common/ImgContainer'
import VideoContainer from 'components/common/VideoContainer'
import IconContainer from 'components/common/IconContainer'
import { convertVimeoURL } from 'utils/string.utils'
import { useNavigate } from 'react-router-dom'
import { MILESTONE_STATUS } from 'utils/constants'
import PreviewButton from './PreviewButton'

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
	// Targeting the scrollbar within the drawer
	'& ::-webkit-scrollbar': {
		width: '4px',
	},
	'& ::-webkit-scrollbar-track': {
		background: theme.palette.primary.lightBlue,
		borderRadius: '10px', // Adjust as needed
	},
	'& ::-webkit-scrollbar-thumb': {
		background: theme.palette.primary.main,
		borderRadius: '10px',
		// '&:hover': {
		// 	background: theme.palette.primary.main,
		// },
	},
}))

const BasicInfoBox = styled(Box)(({ theme }) => ({
	paddingTop: 56,
	paddingInline: 24,
	position: 'relative',

	'.milestone-drawer-cancel-button': {
		position: 'absolute',
		top: 24,
		right: 24,
	},

	[theme.breakpoints.up('lg')]: {
		paddingTop: 72,
		paddingInline: 48,
	},
}))

const AppendedContentBox = styled(Box)(({ theme }) => ({
	paddingTop: 64,
	paddingBottom: 48,
	paddingInline: 24,
	'.milestone-drawer-item-cta-button': {
		marginBottom: 16,
	},

	'ul, ol': {
		paddingInlineStart: '1.5em',
	},
	[theme.breakpoints.up('lg')]: {
		paddingBottom: 48,
		paddingInline: 48,
		'.milestone-drawer-item-cta-button': {
			maxWidth: 'fit-content',
		},
	},
}))

const Drawer = ({
	closeDrawer,
	content,
	milestoneIndex,
	milestoneText,
	statusText,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenPreview, setIsOpenPreview] = useState(false)
	const navigate = useNavigate()
	const drawerRef = useRef()

	const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	const isPreview = statusText === MILESTONE_STATUS.UPCOMING

	const previewMilestoneText = 'Preview Milestone'

	const drawerProps = [
		{
			anchor: 'bottom',
			PaperProps: {
				style: {
					height: '85%',
				},
			},
		},
		{
			anchor: 'right',
			PaperProps: {
				style: {
					width: 643,
				},
			},
		},
	]

	useEffect(() => {
		if (content !== undefined) {
			setIsOpen(true)
		} else {
			setIsOpenPreview(false)
			setIsOpen(false)
		}
	}, [content])

	if (content === undefined) {
		return null
	}

	const SingleContentDisplay = ({ item }) => (
		<Box display={'flex'} flexDirection={'column'} mb={4}>
			{item.VideoLink?.Href && (
				<VideoContainer
					mb={2}
					src={convertVimeoURL(item.VideoLink.Href)}
					text={item.VideoLink.Text}
				/>
			)}
			{item.Image && (
				<ImgContainer mb={2} src={item.Image.src} alt={item.Image.alt} />
			)}
			{item.Icon?.src && (
				<IconContainer mb={2} src={item.Icon.src} alt={item.Icon.alt} />
			)}
			{item.Title && (
				<Typography variant="titleSmall" color="primary.main" component={'h2'}>
					{item.Title}
				</Typography>
			)}
			{item.Text && (
				<Typography variant="bodySmall" color="primary.main">
					{parse(item.Text)}
				</Typography>
			)}
			{item.CTALabel && (
				<Button
					variant={'primary'}
					className="milestone-drawer-item-cta-button"
					onClick={() => {
						const href = item.CTALink?.Href
						if (href.startsWith('/')) navigate(href)
						else window.open(href, '_blank')
					}}
					// aria-label={item.CTALink?.Text}
				>
					{item.CTALabel}
				</Button>
			)}
		</Box>
	)

	return (
		<StyledDrawer
			open={isOpen}
			onClose={closeDrawer}
			ref={drawerRef}
			role="dialog"
			tabIndex={-1}
			aria-labelledby="milestone-drawer-title"
			aria-describedby="milestone-drawer-description"
			{...drawerProps[Number(isDesktopView)]}
		>
			<Box>
				<BasicInfoBox minHeight={isPreview && !isDesktopView ? '85vh' : ''}>
					<Box className="milestone-drawer-cancel-button">
						<IconButton
							onClick={closeDrawer}
							aria-label={'close'}
							color="primary"
						>
							<CloseIcon />
						</IconButton>
					</Box>

					{milestoneIndex !== null && !isDesktopView && (
						<Box mb={4}>
							<StatusTag label={statusText} />
						</Box>
					)}
					{milestoneIndex !== null && (
						<Box>
							<Typography variant="titleXSmall" color="secondary.main">
								{milestoneText} {milestoneIndex + 1}
							</Typography>
						</Box>
					)}
					<Box>
						<Typography
							id="milestone-drawer-title"
							variant="titleLarge"
							color="primary.main"
							component={'h1'}
						>
							{content.Title}
						</Typography>
					</Box>
					{!isDesktopView && (
						<Box mt={2}>
							<Typography
								id="milestone-drawer-description"
								variant="bodyMedium"
								color="primary.main"
							>
								{parse(content.Description)}
							</Typography>
						</Box>
					)}
					{isPreview && !isDesktopView && (
						<PreviewButton
							text={previewMilestoneText}
							onClick={() => {
								setIsOpenPreview(true)
								setTimeout(() => {
									const element =
										drawerRef.current.querySelector('.MuiDrawer-paper')
									const scrollPixel = element.clientHeight

									element.scrollTo({ top: scrollPixel, behavior: 'smooth' })
								}, 500)
							}}
						/>
					)}
				</BasicInfoBox>
				{content && (
					<AppendedContentBox
						hidden={!(!isPreview || isDesktopView || isOpenPreview)}
					>
						<Box>
							{content.MilestoneContent.map((item, index) => {
								return <SingleContentDisplay key={index} item={item} />
							})}
						</Box>
					</AppendedContentBox>
				)}
				{content.WhatIsNext && (
					<AppendedContentBox
						bgcolor={'primary.lightBlue'}
						hidden={!(!isPreview || isDesktopView || isOpenPreview)}
					>
						<Typography
							color="primary.main"
							variant="titleSmall"
							component={'h1'}
						>
							{'What’s Next?'}
						</Typography>
						<Typography color="primary.main" variant="bodySmall">
							{parse(content.WhatIsNext)}
						</Typography>
					</AppendedContentBox>
				)}
			</Box>
		</StyledDrawer>
	)
}

Drawer.propTypes = {
	isOpen: PropTypes.bool,
	closeDrawer: PropTypes.func.isRequired,
	content: PropTypes.object,
	milestoneIndex: PropTypes.number,
	milestoneText: PropTypes.string,
	statusText: PropTypes.string,
}

Drawer.defaultProps = {
	isOpen: false,
	content: undefined,
	milestoneText: '',
	statusText: undefined,
}

export default Drawer
