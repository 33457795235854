import React, { useRef } from 'react'
import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'
import { useLocation } from 'react-router-dom'
import { sendWebAnalyticsData } from 'services/webAnalyticsService'

// TODO: consolidate device params into breakpoints

const StyledButton = styled(MuiButton)(({ theme, ...props }) => {
	return {
		...theme.typography.linkNavigation,

		color: theme.palette.primary.main,
		borderBottom: '2px solid transparent',
		textTransform: 'uppercase', // Make the text all uppercase
		boxShadow: 'none', // removes the box-shadow MUI applies by default
		gap: '8px',
		padding: '8px',
		margin: '12px',
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			textDecoration: 'underline',
			textDecorationThickness: '2px',
			textUnderlineOffset: '8px',
		},
		'&:focus-visible': {
			borderRadius: '4px',
			boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
		},
		[theme.breakpoints.down('lg')]: {
			padding: '0px 8px 20px 8px',
			justifyContent: 'start',
			borderRadius: 0,
			borderBottom: `2px solid ${
				props.device === 'mobile' && props.path === props.route
					? theme.palette.primary.main
					: theme.palette.primary.lightBlue
			}`,
			'&:hover': {
				backgroundColor: theme.palette.common.white,
				textDecoration: 'none',
				borderBottom: `2px solid ${theme.palette.primary.main}`,
			},
		},
	}
})

const NavButton = ({ onClick, ...props }) => {
	const btnRef = useRef()
	const location = useLocation()
	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event)
		}
		if (props.analyticsid) {
			sendWebAnalyticsData(
				props.analyticstext,
				btnRef.current,
				props.analyticstargeturl,
				btnRef.current.classList,
				props.analyticsid
			)
		}
	}
	return (
		<StyledButton
			onClick={handleOnClick}
			ref={btnRef}
			style={{
				textDecoration:
					props.device === 'desktop' && location.pathname === props.route
						? 'underline'
						: '',
				textDecorationThickness: '2px',
				textUnderlineOffset: '8px',
			}}
			path={location.pathname}
			disableRipple
			variant={'text'}
			{...props}
		/>
	)
}

export default NavButton
