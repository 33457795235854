import { getRequest, postRequest } from 'utils/axiosClient'

const getDocuments = async (requestData) => {
	try {
		const response = await postRequest('api/documents', requestData)

		const documents = []
		const lotDocs = response.data.divisions[0].communities[0].lots[0].lotDocs
		if (lotDocs && Array.isArray(lotDocs)) {
			documents.push(...lotDocs)
		}

		const communityDocs =
			response.data.divisions[0].communities[0].communityDocs
		if (communityDocs && Array.isArray(communityDocs)) {
			documents.push(...communityDocs)
		}

		return { success: true, documents }
	} catch (e) {
		console.error(e)
		return { success: false, documents: undefined }
	}
}

// Not in used but keep for future reference
// eslint-disable-next-line no-unused-vars
const openDocumentInNewTab = async (serverRelativeUrl) => {
	try {
		const response = await getRequest(
			'api/downloadFile?serverRelativeUrl=' + serverRelativeUrl
		)

		let blob = new Blob([response.data], {
			type: 'application/octet-stream', //'application/pdf',
		})
		const blobUrl = URL.createObjectURL(blob)
		window.open(blobUrl, '_blank')

		URL.revokeObjectURL(blobUrl)

		return response
	} catch (e) {
		console.error(e)
		return undefined
	}
}

// const temp = '/sites/TestCGY/SWH/Lots/40111064/Test1.pdf'
// 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
// 'https://mattamycorp.sharepoint.com/:b:/s/TestCGY/EWQhOBSIAfVGgrlQJDJg6x4BJqb3yVhx2UuxBQhi81xVWA?download=1'
// 'https://mattamycorp.sharepoint.com/sites/TestCGY/SWH/Lots/40111064/Test.pdf?ga=1'

// const largePdfUrl =
// 	'http://localhost:8080/api/downloadFile?serverRelativeUrl=/sites/TestCGY/SWH/Lots/40111064/Test1.pdf'

// eslint-disable-next-line no-unused-vars
const downloadDocument = async (serverRelativeUrl, fileName, userEmail) => {
	try {
		let auth
		let token

		const idToken = sessionStorage.getItem('idToken')
		if (idToken) {
			auth = `Bearer ${idToken}`
		}
		const jwtToken = sessionStorage.getItem('jwtToken')
		if (jwtToken) {
			token = `${jwtToken}`
		}

		const encodedEmail = window.btoa(userEmail)

		const response = await fetch(
			process.env.REACT_APP_SERVER_URL + '/api/downloadFile',
			{
				method: 'POST',
				body: JSON.stringify({
					email: encodedEmail,
					serverRelativeUrl: serverRelativeUrl,
				}),
				headers: {
					Access_token: token,
					Authorization: auth,
					Accept: 'application/octet-stream',
					'Content-Type': 'application/json',
					'Ocp-Apim-Subscription-Key':
						process.env.REACT_APP_OPI_SUBSCRIPTION_KEY,
				},
			}
		)

		// Check if the response is successful
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`)
		}

		// Create a blob object from the response
		const blob = await response.blob()

		// // Create a URL for the blob object
		const blobUrl = window.URL.createObjectURL(blob)

		// Create a link element and click it to download the file
		const link = document.createElement('a')
		link.href = blobUrl
		link.download = fileName
		document.body.appendChild(link)
		link.click()

		URL.revokeObjectURL(blobUrl)
		document.body.removeChild(link)

		return { success: response.ok }
	} catch (e) {
		console.error(e)
		return { success: false }
	}
}

export default { getDocuments, downloadDocument }
