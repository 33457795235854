import React from 'react'
import HomeOverviewGrid from 'components/HomeOverviewGrid'
import { useLookupContext } from 'contexts/LookupContext'
import { useTextualContext } from 'contexts/TextualContext'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import NeedHelp from 'components/NeedHelp'
import PageHeading from 'components/PageHeading'

const HomePage = () => {
	const { t } = useTextualContext()
	const { firstName, lastName } = useLookupContext()

	const titleText = t('my-home.headline')
	const needHelpContent = t('my-home.need-help')
	return (
		<>
			<PageContentWrapper>
				<PageHeading titleText={`${titleText}${firstName} ${lastName}!`} />
				<HomeOverviewGrid />
			</PageContentWrapper>
			<NeedHelp content={needHelpContent} />
		</>
	)
}

export default HomePage
