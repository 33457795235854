import React from 'react'
import { useQuery } from '@apollo/client'
import { styled } from '@mui/system'
import { Box as MuiBox, Grid, Typography, useMediaQuery } from '@mui/material'
import { useLookupContext } from 'contexts/LookupContext'
import { useTextualContext } from 'contexts/TextualContext'

import UploadedDocuments from 'components/HomeOverviewGrid/UploadedDocuments'
import CompletionTracker from 'components/HomeOverviewGrid/CompletionTracker'
import TextIconLink from 'components/common/Links/TextIconLink'
import YourHome from './YourHome'
import YourCommunity from './YourCommunity'
import { ReactComponent as ArrowRight } from 'assets/svgs/actions/arrowRight.svg'

import { loader } from 'graphql.macro'
const SITECORE_COTNENT_QUERY = loader('./SitecoreContent.query.graphql')

const Box = MuiBox

const StyledBox = styled(MuiBox)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	// alignItems: 'stretch',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: 24,
	height: '100%',
	'.display-home-item-content': {
		flexGrow: 2,
		width: '100%',
		height: '100%',
	},
	[theme.breakpoints.up('lg')]: {
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'.display-home-item-content': {
			order: 1,
		},
	},
}))

const DisplayHome = () => {
	const { selectedDivision, selectedCommunity, selectedLot, documents } =
		useLookupContext()
	const { t } = useTextualContext()
	const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	const isLookupFetched = !!selectedLot

	const { data } = useQuery(SITECORE_COTNENT_QUERY, {
		skip: !isLookupFetched,
		variables: {
			divisionCode: selectedDivision?.divisionMpcCd,
			communityMPC: selectedCommunity?.marketingMpcCd
				? selectedCommunity.marketingMpcCd
				: selectedCommunity?.communityMpcCd,
			planName: selectedLot?.model,
			elevationName: selectedLot?.elevation,
		},
	})

	// console.log('DisplayHome sitecore', loading, error, data)

	const firstItem = {
		titleText: t('my-home.overall-completion-heading'),
		linkText: t('my-home.overall-completion-link-text'),
	}

	const secondItem = {
		titleText: t('my-home.uploaded-documents-heading'),
		linkText: t('my-home.uploaded-documents-link-text'),
	}

	const thirdItem = {
		titleText: t('my-home.home-n-community-heading'),
		linkText: t('my-home.home-n-community-link-text'),
	}

	const isYourDocumentsAvailable = documents !== undefined
	const YourDocuments = isYourDocumentsAvailable
		? documents
				.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate))
				.slice(0, 3)
		: undefined

	const yourHomeData = selectedLot
	const yourHomeImageLink = data?.planQuery.elevationImage

	const yourCommunityCityState = data?.communityQuery.cityState
	const yourCommunityName = data?.communityQuery.communityName
	const yourCommunityDescription = data?.communityQuery.heroCopy
	const yourCommunityImageLink = data?.communityQuery.heroImage
	const yourCommunityUrl = data?.communityQuery.communityURL

	const viewCommunityIconLinkContent = (
		<>
			{yourCommunityUrl && (
				<TextIconLink
					iconType={<ArrowRight />}
					iconPosition={'after'}
					variant="primary"
					target="_blank"
					to={yourCommunityUrl}
					analyticsid={thirdItem.linkText.replaceAll(' ', '-').toLowerCase()}
					analyticstargeturl={yourCommunityUrl}
					analyticstext={thirdItem.linkText}
				>
					{thirdItem.linkText}
				</TextIconLink>
			)}
		</>
	)

	return (
		<Grid style={{ marginTop: 0 }} container spacing={8}>
			{/* First Item */}
			<Grid item xs={12} lg={7.5}>
				<StyledBox>
					<Typography
						component={'h2'}
						color="primary.main"
						variant="titleMedium"
					>
						{firstItem.titleText}
					</Typography>
					{!matches && (
						<Box className="display-home-item-content">
							<CompletionTracker />
						</Box>
					)}
					<TextIconLink
						variant="primary"
						iconType={<ArrowRight />}
						iconPosition={'after'}
						to={'/myJourney'}
						analyticsid={firstItem.linkText.replaceAll(' ', '-').toLowerCase()}
						analyticstargeturl={'/myJourney'}
						analyticstext={firstItem.linkText}
					>
						{firstItem.linkText}
					</TextIconLink>
					{matches && (
						<Box className="display-home-item-content">
							<CompletionTracker />
						</Box>
					)}
				</StyledBox>
			</Grid>

			{/* Second Item */}
			<Grid item xs={12} lg={4.5}>
				<StyledBox>
					<Typography
						component={'h2'}
						color="primary.main"
						variant="titleMedium"
					>
						{secondItem.titleText}
					</Typography>
					{!matches && (
						<Box className="display-home-item-content">
							<UploadedDocuments documents={YourDocuments} />
						</Box>
					)}
					{(!isYourDocumentsAvailable || YourDocuments?.length > 0) && (
						<TextIconLink
							role="link"
							variant="primary"
							iconType={<ArrowRight />}
							iconPosition={'after'}
							to="myDocuments"
							analyticsid={secondItem.linkText
								.replaceAll(' ', '-')
								.toLowerCase()}
							analyticstargeturl={'/myDocuments'}
							analyticstext={secondItem.linkText}
						>
							{secondItem.linkText}
						</TextIconLink>
					)}
					{matches && (
						<Box className="display-home-item-content">
							<UploadedDocuments documents={YourDocuments} />
						</Box>
					)}
				</StyledBox>
			</Grid>

			{/* Third Item */}
			<Grid item xs={12}>
				<Grid container spacing={[3, 3, 3, 8]} alignItems={'stretch'}>
					<Grid item xs={12} lg={3}>
						<Typography
							component={'h2'}
							color="primary.main"
							variant="titleMedium"
						>
							{thirdItem.titleText}
						</Typography>
						{matches && <Box mt={3}>{viewCommunityIconLinkContent}</Box>}
					</Grid>
					<Grid item xs={12} lg={4.5}>
						<StyledBox>
							<Box className="display-home-item-content">
								<YourHome data={yourHomeData} image={yourHomeImageLink} />
							</Box>
						</StyledBox>
					</Grid>
					<Grid item xs={12} lg={4.5}>
						<StyledBox>
							<Box className="display-home-item-content">
								<YourCommunity
									division={yourCommunityCityState}
									name={yourCommunityName}
									description={yourCommunityDescription}
									image={yourCommunityImageLink}
								/>
							</Box>
							{!matches && viewCommunityIconLinkContent}
						</StyledBox>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default DisplayHome
