import React, { createContext, useContext } from 'react'
import { ApolloProvider } from '@apollo/client'
import client from 'utils/apolloClient'
import contentData from 'assets/locales/en.json'

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

if (process.env.NODE_ENV === 'development') {
	// Adds messages only in a dev environment
	loadDevMessages()
	loadErrorMessages()
}

export const TextualContext = createContext()

export const TextualProvider = ({ children }) => {
	// const [content] = useState(contentData)

	return (
		<ApolloProvider client={client}>
			<TextualContext.Provider value={contentData}>
				{children}
			</TextualContext.Provider>
		</ApolloProvider>
	)
}

const getContent = (path, content) => {
	const keys = path.split('.')
	let result = content

	for (const key of keys) {
		if (!result) return null
		result = result[key]
	}

	return result
}

export const useTextualContext = () => {
	const context = useContext(TextualContext)

	const t = (path) => {
		return getContent(path, context)
	}

	return { t }
}
