import { Box, styled } from '@mui/material'

const ErrorContentWrapper = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(4),
	marginBottom: theme.spacing(10),
	paddingInline: theme.spacing(2),
	[theme.breakpoints.up('lg')]: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(8),
		paddingInline: theme.spacing(15),
	},

	maxWidth: 1440,
	marginInline: 'auto',
	width: '100%',
}))

export default ErrorContentWrapper
