import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Popper as MuiPopper, Paper, styled } from '@mui/material'
import RadioGroup from '../RadioGroup'
import DropdownButton from '../Buttons/DropdownButton'

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
	width: '100%',
	zIndex: 1,
	'.MuiPaper-root': {
		marginBlock: 8,
		backgroundColor: theme.palette.secondary.main,
		boxShadow: 'none',
		padding: '32px 16px 32px 20px',
		borderRadius: '12px',
	},
	'.MuiFormControlLabel-root': {
		color: theme.palette.common.white,

		svg: {
			color: theme.palette.common.white,
		},
	},
}))

const RadioDropdown = ({ options, selectedValue, onChange, placeholder }) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const open = Boolean(anchorEl)

	const currentOption = options.filter(
		(option) => option.value === selectedValue
	)

	const currentOptionLabel =
		currentOption.length > 0 ? currentOption[0].label : undefined

	const handleSelectionChange = (event, value) => {
		onChange(event, value)
		setAnchorEl(null)
	}

	return (
		<Box position={'relative'}>
			<DropdownButton expanded={open} onClick={handleClick}>
				{currentOptionLabel || placeholder}
			</DropdownButton>
			<StyledPopper
				open={open}
				anchorEl={anchorEl}
				placement="bottom-start"
				disablePortal={true}
				modifiers={[
					{
						name: 'flip',
						enabled: false,
						options: {
							altBoundary: true,
							rootBoundary: 'viewport',
							padding: 8,
						},
					},
					{
						name: 'preventOverflow',
						enabled: false,
						options: {
							altAxis: false,
							altBoundary: false,
							tether: false,
							rootBoundary: 'viewport',
							padding: 8,
						},
					},
				]}
			>
				<Paper>
					<RadioGroup
						options={options}
						selectedValue={selectedValue}
						onChange={handleSelectionChange}
					/>
				</Paper>
			</StyledPopper>
		</Box>
	)
}

RadioDropdown.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedValue: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
}

RadioDropdown.defaultProps = {
	selectedValue: '',
	placeholder: 'Please select',
}

export default RadioDropdown
