import { createContext, useReducer, useContext, useEffect } from 'react'
import { useAppContext } from 'contexts/AppContext'
import { useTextualContext } from 'contexts/TextualContext'

import LookupReducer from './LookupReducer'
import userService from 'services/userService'
import documentService from 'services/documentService'

import { useMsal } from '@azure/msal-react'
// import { useNavigate } from 'react-router'
import { EventType } from '@azure/msal-browser'
import { splitName } from 'utils/string.utils'

// Initial state
const initialValue = {
	uniqueUserId: '',
	firstName: '',
	lastName: '',
	email: '',
	divisions: [],
	showTutorial: false,
	legalContentFlags: {
		privacyPolicy: 'no',
		termsAndCondition: 'no',
		viewTutorial: 'no',
	},
	selectedDivision: undefined,
	selectedCommunity: undefined,
	selectedLot: undefined,
	preRequestBody: undefined,
	documents: [],

	loading: true,
	milestoneLoading: true,
	userLoggedIn: false,
}

// Create context
export const LookupContext = createContext(initialValue)

// Create context to update context value
export const LookupDispatchContext = createContext(initialValue)

const getEstateInfo = (estates) => {
	try {
		// Validate the data structure
		if (!estates || !Array.isArray(estates.divisions)) {
			throw new Error(
				'Estates format is incorrect or the division array property is missing.'
			)
		}

		// Accessing the first divisions item as per MVP scope
		const division = estates.divisions[0]
		if (!division || !Array.isArray(division.communities)) {
			throw new Error('Community array property is missing or not an array.')
		}

		// Accessing the first community item  as per MVP scope
		const community = division.communities[0]
		if (!community || !Array.isArray(community.lots)) {
			throw new Error('Lots array property is missing or not an array.')
		}

		// Accessing the first lot item  as per MVP scope
		const lot = community.lots[0]
		if (!lot) {
			throw new Error('Lots array property has 0 length.')
		}

		// Below lot selection logics moved to backend
		// const earliest = community.lots.reduce((earliestObj, currentObj) => {
		// 	const currentSoldDate = new Date(currentObj.soldDate)
		// 	const earliestSoldDate = new Date(earliestObj.soldDate)

		// 	return currentSoldDate < earliestSoldDate ? currentObj : earliestObj
		// }, community.lots[0])

		// const lot = earliest

		return {
			division: {
				divisionMpcCd: division.divisionMpcCd,
			},
			community: {
				communityMpcCd: community.communityMpcCd,
				marketingMpcCd: community.marketingMpcCd,
			},
			lot,
		}
	} catch (error) {
		console.error(error.message)
		return {
			division: undefined,
			community: undefined,
			lot: undefined,
		}
	}
}

// Provider component
export const LookupProvider = ({ children }) => {
	const { showLoader, hideLoader, showAlert } = useAppContext()

	const [state, dispatch] = useReducer(LookupReducer, initialValue)
	const { instance } = useMsal()
	const { t } = useTextualContext()

	// const navigate = useNavigate()

	// Wrap object with useMemo
	// const temp = useMemo(() => ({ state, dispatch }), [state, dispatch])

	useEffect(() => {
		const accounts = instance.getAllAccounts()
		if (accounts.length > 0) {
			instance.setActiveAccount(accounts[0])
		}

		let tempIdToken

		const fetchData = async () => {
			try {
				const {
					uniqueUserId,
					customerName: base64CustomerName,
					email: base64Email,
					estates,
					ppTimestamp,
					privacyPolicy,
					tcTimestamp,
					termsAndCondition,
					viewTutorial,
				} = await userService.userLookup({
					authCd: sessionStorage.getItem('authCode'),
					email: instance
						.getActiveAccount()
						.idTokenClaims.emails.map((email) => window.btoa(email)), // encode to base64
				})

				const customerName = window.atob(base64CustomerName)
				const email = window.atob(base64Email)

				const { firstName, lastName } = splitName(customerName)
				const showTutorial = viewTutorial.toLowerCase() !== 'yes' ? true : false
				const legalContentData = {
					ppTimestamp,
					privacyPolicy,
					tcTimestamp,
					termsAndCondition,
					viewTutorial,
				}
				dispatch({
					type: 'UPDATE_BASIC_DATA',
					payload: {
						uniqueUserId,
						firstName,
						lastName,
						email,
						divisions: estates?.divisions ?? [],
						showTutorial,
						userLoggedIn: true,
						legalContentData,
					},
				})

				const { division, community, lot } = getEstateInfo(estates)

				if (lot) {
					const requestData = {
						uniqueUserId: uniqueUserId,
						// add email attribute // encode to base64
						email: window.btoa(email),
						divisions: [
							{
								divisionMpcCd: division.divisionMpcCd,
								communities: [
									{
										communityMpcCd: community.communityMpcCd,
										marketingMpcCd: community.marketingMpcCd,
										lots: [
											{
												lotJobID: lot.lotJobID,
												purchasedStatus: lot.purchasedStatus,
											},
										],
									},
								],
							},
						],
					}

					dispatch({
						type: 'UPDATE_PRE_REQUEST_BODY',
						payload: requestData,
					})

					dispatch({
						type: 'UPDATE_SELECTED_LOT',
						payload: { division, community, lot },
					})

					const { success, documents } =
						await documentService.getDocuments(requestData)

					dispatch({
						type: 'UPDATE_DOCUMENT_ARRAY',
						payload: success ? documents ?? [] : undefined,
					})
				} else {
					sessionStorage.clear()
					instance.logoutRedirect({
						postLogoutRedirectUri: '/error/notFound',
						idTokenHint: tempIdToken,
						onRedirectNavigate: () => {
							// Return false if you would like to stop navigation after local logout
							return true
						},
					})
				}

				dispatch({ type: 'LOAD_COMPLETE' })
			} catch (err) {
				sessionStorage.clear()
				instance.logoutRedirect({
					postLogoutRedirectUri: '/error',
					idTokenHint: tempIdToken,
					onRedirectNavigate: () => {
						// Return false if you would like to stop navigation after local logout
						return true
					},
				})
			}
		}

		instance.addEventCallback(async (event) => {
			// set active account after redirect
			if (
				event.eventType === EventType.LOGIN_SUCCESS &&
				event.payload.account
			) {
				const account = event.payload.account
				instance.setActiveAccount(account)

				const idToken = event.payload.idToken
				tempIdToken = idToken
				sessionStorage.setItem('idToken', idToken)
				await userService
					.createToken({
						userName: window.btoa(account.username), // encode to base64
					})
					.then((response) => {
						sessionStorage.setItem('jwtToken', response.data.jwttoken)
						fetchData()
					})
					.catch(() => {
						sessionStorage.clear()
						instance.logoutRedirect({
							postLogoutRedirectUri: '/error',
							idTokenHint: idToken,
							onRedirectNavigate: () => {
								// Return false if you would like to stop navigation after local logout
								return true
							},
						})
					})
			} else if (
				event.eventType === EventType.LOGIN_FAILURE &&
				event.error.errorMessage.indexOf('Error: user_cancelled_authorize')
			) {
				await instance.loginRedirect({
					prompt: 'select_account',
				})
			}
		})

		// handle auth redired/do all initial setup for msal
		instance
			.handleRedirectPromise()
			// eslint-disable-next-line no-unused-vars
			.then(async (response) => {
				// Check if user signed in
				const account = instance.getActiveAccount()
				const jwtToken = sessionStorage.getItem('jwtToken')
				if (!account) {
					// redirect anonymous user to login page
					await instance.loginRedirect({
						prompt: 'select_account',
					})
				} else if (jwtToken) {
					fetchData()
				}
			})
			.catch((err) => {
				// TODO: Handle errors
				console.log('promise error', err)
			})
	}, [])

	useEffect(() => {
		showLoader()
	}, [])

	useEffect(() => {
		const scheduledMaintenanceAlertTitle = t(
			'service-message.scheduled-maintenance.alert-title'
		)
		const scheduledMaintenanceAlertMessage = t(
			'service-message.scheduled-maintenance.alert-message'
		)
		const scheduledMaintenanceStartDate = t(
			'service-message.scheduled-maintenance.start-date'
		)
		const scheduledMaintenanceEndDate = t(
			'service-message.scheduled-maintenance.end-date'
		)

		const isFlaged =
			sessionStorage.getItem('mhcpServiceMessageFlag') !== 'false'

		if (
			isFlaged &&
			scheduledMaintenanceStartDate &&
			scheduledMaintenanceEndDate &&
			Date.now() >= new Date(scheduledMaintenanceStartDate) &&
			Date.now() <= new Date(scheduledMaintenanceEndDate)
		) {
			showAlert(
				'info',
				scheduledMaintenanceAlertTitle,
				scheduledMaintenanceAlertMessage
			)
		}
	}, [])

	useEffect(() => {
		if (state.uniqueUserId && state.uniqueUserId !== '') {
			setTimeout(() => {
				hideLoader()
			}, 30000)
		}
	}, [state.uniqueUserId])

	useEffect(() => {
		if (state.loading || state.milestoneLoading) {
			// showLoader()
			// setTimeout(() => {
			// 	hideLoader()
			// }, 30000)
		} else {
			hideLoader()
		}
	}, [state.loading, state.milestoneLoading])

	return (
		<>
			{state.userLoggedIn && (
				<LookupContext.Provider
					value={{
						...state,
					}}
				>
					<LookupDispatchContext.Provider value={dispatch}>
						{children}
					</LookupDispatchContext.Provider>
				</LookupContext.Provider>
			)}
		</>
	)
}

// Context hook
export const useLookupContext = () => {
	const context = useContext(LookupContext)
	return context
}
