import { styled } from '@mui/system'
import { Chip as MuiChip } from '@mui/material'

const CustomChip = styled(MuiChip)(({ theme, variant }) => {
	// Varients
	let textColor, bgColor, borderColor
	switch (variant) {
		case 'unselected':
			textColor = theme.palette.primary.main
			bgColor = theme.palette.common.white
			borderColor = theme.palette.primary.main
			break
		case 'disabled':
			textColor = theme.palette.functional.disabledGrey
			bgColor = theme.palette.common.white
			borderColor = theme.palette.functional.disabledGrey
			break
		case 'selected':
		default:
			textColor = theme.palette.common.white
			bgColor = theme.palette.secondary.main
			borderColor = theme.palette.secondary.main
			break
	}

	// Styling
	return {
		...theme.typography.button,

		textAlign: 'center',
		color: textColor,
		backgroundColor: bgColor,
		border: '2px solid',
		borderColor: borderColor,
		padding: '24px 18px',
		borderRadius: 12,
		gap: 8,
		cursor: 'pointer',
		textTransform: 'uppercase',

		'&:focus': {
			backgroundColor: bgColor,
		},
		'&:focus::before': {
			content: '""',
			position: 'absolute',
			top: -3,
			right: -3,
			bottom: -3,
			left: -3,
			boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
			borderRadius: '4px',
		},

		'&:hover': {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary.main,
			borderColor: theme.palette.secondary.main,
		},

		'& .MuiChip-label': {
			paddingTop: '5px',
			lineHeight: 5,
		},

		'&.Mui-disabled': {
			opacity: 1,
		},
	}
})

const Chip = ({ ...props }) => {
	return <CustomChip {...props}></CustomChip>
}

export default Chip
