import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card as MuiCard, Typography, styled } from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/svgs/actions/info.svg'

const StyledCard = styled(MuiCard)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',

	borderRadius: 8,
	padding: '12px 16px 12px 16px',
	paddingInline: 40,
	backgroundColor: theme.palette.primary.lightBlue,
	boxShadow: 'none',

	border: `2px solid ${theme.palette.secondary.main}`,
	height: '100%',

	path: {
		fill: theme.palette.secondary.main,
	},
}))

const AlertCard = ({ text }) => {
	return (
		<StyledCard>
			<Box display={'flex'} gap={2}>
				<InfoIcon />
				<Typography
					variant="bodySmall"
					component={'div'}
					width={'fit-content'}
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				></Typography>
			</Box>
		</StyledCard>
	)
}

AlertCard.propTypes = {
	text: PropTypes.string.isRequired,
}

export default AlertCard
