import React from 'react'
import PropTypes from 'prop-types'
import {
	Radio,
	RadioGroup as MuiRadioGroup,
	FormControlLabel,
	Typography,
	styled,
} from '@mui/material'

import { ReactComponent as RadioDefault } from 'assets/svgs/radioDefault.svg'
import { ReactComponent as RadioSelected } from 'assets/svgs/radioSelected.svg'
// import { ReactComponent as RadioDisabled } from 'assets/svgs/radioDisabled.svg'

const StyledRadioGroup = styled(MuiRadioGroup)({
	gap: 16,
	'.MuiFormControlLabel-root': {
		marginLeft: 0,
	},
})

const RadioGroup = ({ options, selectedValue, onChange }) => (
	<StyledRadioGroup onChange={onChange} value={selectedValue}>
		{options.map((option) => (
			<FormControlLabel
				key={option.value}
				value={option.value}
				control={
					<Radio
						icon={<RadioDefault width={16} height={16} />}
						checkedIcon={<RadioSelected width={16} height={16} />}
						// disabledIcon={<RadioDisabled width={16} height={16} />}
					/>
				}
				label={<Typography variant="linkFilter">{option.label}</Typography>}
			/>
		))}
	</StyledRadioGroup>
)

RadioGroup.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedValue: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

export default RadioGroup
