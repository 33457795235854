import React, { useState, useEffect, useContext } from 'react'
import { useLookupContext } from 'contexts/LookupContext'
import { useTextualContext } from 'contexts/TextualContext'

import { styled } from '@mui/system'
import { Modal, Box, Typography, useMediaQuery } from '@mui/material'
import IconButton from 'components/common/Buttons/IconButton'
import Button from 'components/common/Buttons/Button'
import { ReactComponent as CloseIcon } from 'assets/svgs/actions/close.svg'
import TutorialWelcome from 'assets/images/TutorialWelcome.png'
import TutorialHome from 'assets/images/TutorialHome.png'
import TutorialJourney from 'assets/images/TutorialJourney.png'
import TutorialDocuments from 'assets/images/TutorialDocuments.png'
import TutorialMobileHome from 'assets/images/TutorialHomeMobile.png'
import TutorialMobileJourney from 'assets/images/TutorialJourneyMobile.png'
import TutorialMobileDocuments from 'assets/images/TutorialDocumentsMobile.png'
import { LookupDispatchContext } from 'contexts/LookupContext/LookupState'
import userService from 'services/userService'
import { useAppContext } from 'contexts/AppContext'

const StyledModal = styled(Modal)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	outline: 'none',
})

const StyledBox = styled(Box)(({ theme }) => ({
	position: 'relative',
	backgroundColor: 'white',
	textAlign: 'center',
	overflowY: 'auto',
	overflowX: 'hidden',

	width: '100%',
	height: '100vh',
	borderRadius: '0px',
	outline: 'none',

	'.tutorial-walkthrough-cancel-button': {
		position: 'absolute',
		top: 8,
		right: 8,
		borderRadius: '50%',
		backgroundColor: 'white',
		// '&.tutorial-walkthrough-welcome': {
		// },
	},

	'.tutorial-walkthrough-tutorial-image': {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		height: 402,

		borderBottom: '2px solid var(--primary-light-blue, #D8E5ED)',
		background:
			'var(--extra-radial-background, radial-gradient(320.86% 308.93% at 62.88% -223.93%, #0063C6 0%, #FFF 100%))',
		'& img': {
			maxWidth: 293,
		},
	},

	'.tutorial-walkthrough-welcome-text': {
		display: 'flex',
		flexDirection: 'column',
		gap: 40,
		paddingTop: 64,
		paddingInline: 40,
	},

	'.tutorial-walkthrough-tutorial-text': {
		display: 'flex',
		flexDirection: 'column',
		gap: 40,
		paddingTop: 64,
		paddingInline: 40,
		'#tutorial-description': {
			minHeight: 26 * 4, // line height = 26
		},
	},

	'.tutorial-walkthrough-carousel': {
		display: 'flex',
		justifyContent: 'center',
	},

	[theme.breakpoints.up('lg')]: {
		display: 'flex',
		flexDirection: 'column',
		width: '750px',
		height: 'auto',
		minHeight: '732px',
		borderRadius: '12px',

		'& img': {
			width: '100%',
			borderRadius: '12px 12px 0px 0px',
		},

		'.tutorial-walkthrough-cancel-button': {
			top: 16,
			right: 16,
		},

		'.tutorial-walkthrough-tutorial-image': {
			'& img': {
				maxWidth: 583,
			},
		},

		'.tutorial-walkthrough-welcome-text': {
			gap: 32,
			paddingTop: 44,
			paddingBottom: 56,
			paddingInline: 120,
		},

		'.tutorial-walkthrough-tutorial-text': {
			padding: 64,
			'#tutorial-description': {
				minHeight: 'auto',
			},
		},
	},
}))

const TutorialWalkthrough = () => {
	const { t } = useTextualContext()
	const { showTutorial, legalContentFlags, email, uniqueUserId } =
		useLookupContext()
	const dispatch = useContext(LookupDispatchContext)
	const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'))
	const { setConsetGiven } = useAppContext()
	const [open, setOpen] = useState(false)
	const [currentTutorial, setCurrentTutorial] = useState(0)

	useEffect(() => {
		if (showTutorial) {
			setOpen(true)
		}
	}, [showTutorial])

	const tutorials = [
		{
			...t('tutorial.welcome'),
			image: TutorialWelcome,
		},
		{
			...t('tutorial.my-home'),
			image: matches ? TutorialHome : TutorialMobileHome,
		},
		{
			...t('tutorial.my-journey'),
			image: matches ? TutorialJourney : TutorialMobileJourney,
		},
		{
			...t('tutorial.my-documents'),
			image: matches ? TutorialDocuments : TutorialMobileDocuments,
		},
	]

	const handleNext = () => {
		// finish
		if (currentTutorial === tutorials.length - 1) handleClose()

		setCurrentTutorial((prev) => (prev + 1) % tutorials.length)
	}

	const handleBack = () => {
		// skip
		if (currentTutorial === 0) handleClose()

		setCurrentTutorial(
			(prev) => (prev - 1 + tutorials.length) % tutorials.length
		)
	}

	const updateConsent = async () => {
		const reqData = {
			uniqueUserId,
			// add email attribute and encode to base64
			email: window.btoa(email),
			privacyPolicy: legalContentFlags.privacyPolicy,
			termsAndCondition: legalContentFlags.termsAndCondition,
			viewTutorial: 'YES',
		}
		await userService.updateConsent(reqData)
		setConsetGiven(true)
	}

	const handleClose = () => {
		if (legalContentFlags.viewTutorial.toLowerCase() === 'no') updateConsent()
		dispatch({ type: 'SHOW_TUTORIAL', showTutorial: false })
		setOpen(false)
	}

	const tutorial = tutorials[currentTutorial]
	const carouselContent = (
		<Box className="tutorial-walkthrough-carousel" aria-hidden="true">
			{tutorials.slice(1).map((_, index) => (
				<Box
					key={index}
					bgcolor={
						index + 1 === currentTutorial ? 'primary.main' : 'primary.grey'
					}
					width="12px"
					height="12px"
					borderRadius="50%"
					mx="9px"
				/>
			))}
		</Box>
	)

	const welcomeContent = (
		<>
			<Box className="tutorial-walkthrough-cancel-button tutorial-walkthrough-welcome">
				<IconButton onClick={handleClose} aria-label={'close'} color="primary">
					<CloseIcon />
				</IconButton>
			</Box>
			{/* Image used as part of page design: empty alt text */}
			<Box display={'flex'} justifyContent={'center'} minHeight={'402px'}>
				<img
					src={tutorial.image}
					alt=""
					width={'100%'}
					style={{ objectFit: 'cover' }}
				/>
			</Box>
			<Box className="tutorial-walkthrough-welcome-text">
				<Box textAlign={'center'}>
					<Typography
						id="tutorial-title"
						variant={'titleXLarge'}
						color={'primary.main'}
						component={'h1'}
					>
						{tutorial.title}
					</Typography>
					<Typography
						id="tutorial-description"
						variant={matches ? 'bodyXLarge' : 'bodyLarge'}
						color={'primary.main'}
						component={'p'}
					>
						{tutorial.description}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent={'center'}
					alignItems="center"
					gap={2}
				>
					<Button
						onClick={handleBack}
						variant={'secondary'}
						analyticsid={
							'tutorial-' +
							tutorial['button-secondary'].replaceAll(' ', '-').toLowerCase()
						}
						analyticstargeturl={''}
						analyticstext={tutorial['button-secondary']}
					>
						{tutorial['button-secondary']}
					</Button>
					<Button
						onClick={handleNext}
						variant={'primary'}
						analyticsid={
							'tutorial-' +
							tutorial['button-primary'].replaceAll(' ', '-').toLowerCase()
						}
						analyticstargeturl={''}
						analyticstext={tutorial['button-primary']}
					>
						{tutorial['button-primary']}
					</Button>
				</Box>
			</Box>
		</>
	)

	const tutorialContent = (
		<>
			<Box className="tutorial-walkthrough-cancel-button">
				<IconButton onClick={handleClose} aria-label={'close'} color="primary">
					<CloseIcon />
				</IconButton>
			</Box>
			<Box className="tutorial-walkthrough-tutorial-image">
				<img src={tutorial.image} alt="" />
			</Box>
			<Box className="tutorial-walkthrough-tutorial-text">
				<Box textAlign={'left'}>
					<Typography
						id="tutorial-title"
						variant={matches ? 'titleMedium' : 'titleSmall'}
						color={'primary.main'}
						component={'h1'}
						mb={1}
					>
						{tutorial.title}
					</Typography>
					<Typography
						id="tutorial-description"
						variant={matches ? 'bodyMedium' : 'bodySmall'}
						color={'primary.main'}
						component={'div'}
					>
						{tutorial.description}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent={'space-between'}
					alignItems="center"
					gap={2}
				>
					<Button
						onClick={handleBack}
						variant={'secondary'}
						analyticsid={
							'tutorial-' +
							tutorial['button-secondary'].replaceAll(' ', '-').toLowerCase()
						}
						analyticstargeturl={''}
						analyticstext={tutorial['button-secondary']}
					>
						{tutorial['button-secondary']}
					</Button>
					{matches && carouselContent}
					<Button
						onClick={handleNext}
						variant={'primary'}
						analyticsid={
							'tutorial-' +
							tutorial['button-primary'].replaceAll(' ', '-').toLowerCase()
						}
						analyticstargeturl={''}
						analyticstext={tutorial['button-primary']}
					>
						{tutorial['button-primary']}
					</Button>
				</Box>
				{!matches && carouselContent}
			</Box>
		</>
	)

	return (
		<StyledModal
			open={open}
			role="dialog"
			onClose={handleClose}
			aria-labelledby="tutorial-title"
			aria-describedby="tutorial-description"
		>
			<StyledBox
				aria-labelledby="tutorial-title"
				aria-describedby="tutorial-description"
			>
				{currentTutorial === 0 && welcomeContent}
				{currentTutorial > 0 && tutorialContent}
			</StyledBox>
		</StyledModal>
	)
}

export default TutorialWalkthrough
