import { useQuery } from '@apollo/client'
import { Box, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material'
import { useLookupContext } from 'contexts/LookupContext'
import { useTextualContext } from 'contexts/TextualContext'
import { ReactComponent as PhoneIcon } from 'assets/svgs/actions/circlePhone.svg'
import { ReactComponent as EmailIcon } from 'assets/svgs/actions/circleEmail.svg'
import { ReactComponent as LocationIcon } from 'assets/svgs/actions/circleLocation.svg'
import { ReactComponent as CalenderIcon } from 'assets/svgs/actions/circleCalender.svg'
import Button from 'components/common/Buttons/Button'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import PageHeading from 'components/PageHeading'

import { loader } from 'graphql.macro'
import styled from '@emotion/styled'
const SITECORE_CONTACTUS_QUERY = loader('./SitecoreContactus.query.graphql')

const StyledGrid = styled(Grid)(({ theme }) => ({
	svg: {
		float: 'left',
		marginRight: '16px',
		path: {
			fill: theme.palette.secondary.main,
		},
	},
	'.customer-care-heading': {
		marginTop: '64px',
	},
	'.pointer': {
		cursor: 'pointer',
	},
	'.contact-us-item': {
		marginTop: '24px',
		display: 'flex',
		wordBreak: 'break-word',
	},
	'.schedule': {
		marginLeft: '40px',
	},

	[theme.breakpoints.down('lg')]: {
		'& .customer-care-heading, .sales-office': {
			marginTop: '40px',
		},
		'.product-overiew': {
			padding: '104px 16px 48px 16px',
			borderRadius: '0',
			width: '100%',
		},
	},
}))

const ProductOverviewBox = styled(Box)(({ theme }) => ({
	'.product-overiew': {
		backgroundColor: theme.palette.primary.main,
		width: '91%',
		padding: '100px 575px 100px 88px',
		borderRadius: '12px',
	},
	'.product-overview-image img': {
		width: '588px',
		position: 'absolute',
		top: '0',
		right: '0',
		borderRadius: '12px',
	},
	[theme.breakpoints.down('lg')]: {
		'.product-overiew': {
			padding: '104px 16px 48px 16px',
			borderRadius: '0',
			width: '100%',
		},
		'.product-overview-image': {
			padding: '0 16px',
		},
		'.product-overview-image img': {
			position: 'absolute',
			top: '64px',
			left: '16px',
			width: 'calc(100% - 32px)',
		},
		'.product-overview-image-mobile': {
			position: 'absolute',
			top: '64px',
			left: '16px',
			width: 'calc(100% - 32px)',
			borderRadius: '12px',
		},
	},
}))

const Contactus = () => {
	const { selectedDivision, selectedCommunity } = useLookupContext()
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
	const isLookupFetched = !!selectedCommunity
	const { data } = useQuery(SITECORE_CONTACTUS_QUERY, {
		fetchPolicy: 'no-cache',
		skip: !isLookupFetched,
		variables: {
			divisionCode: selectedDivision?.divisionMpcCd,
			communityMPC: selectedCommunity?.marketingMpcCd
				? selectedCommunity?.marketingMpcCd
				: selectedCommunity?.communityMpcCd,
		},
	})
	const { t } = useTextualContext()

	const handleEmailRedirect = (email) => {
		window.open('mailto:' + email)
	}

	const handlePhoneRedirect = (phone) => {
		window.open('tel:' + phone)
	}

	return (
		<PageContentWrapper>
			{data && (
				<>
					<PageHeading
						pb={8}
						titleText={data.communityQuery.contactPageTitle}
						descriptionText={data.communityQuery.contactPageText}
					/>
					<Box>
						<StyledGrid container>
							<Grid
								style={{ marginBottom: isDesktop ? '32px' : '24px' }}
								item
								xs={12}
							>
								<Typography
									component={'h2'}
									color="primary.main"
									variant={isDesktop ? 'titleLarge' : 'titleMedium'}
								>
									{data.communityQuery.communityName}
								</Typography>
							</Grid>
							<Grid className="customer-care" item xs={12} lg={5}>
								<Typography
									component={'h3'}
									color="primary.main"
									variant="titleSmall"
								>
									{data.communityQuery.customerCare?.[0]?.Title ||
										t('contact-us.customercare-heading')}
								</Typography>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-Testid="customercare-phone"
									onClick={() =>
										handlePhoneRedirect(
											data.communityQuery.customerCare?.[0]?.PhoneNumberCall
										)
									}
								>
									<PhoneIcon></PhoneIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.customerCare?.[0]?.PhoneNumberCall}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-Testid="customercare-email"
									onClick={() =>
										handleEmailRedirect(
											data.communityQuery.customerCare?.[0]?.Email
										)
									}
								>
									<EmailIcon></EmailIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.customerCare?.[0]?.Email}
									</Typography>
								</div>
								<div className="customer-care-heading">
									<Typography
										component={'h3'}
										color="primary.main"
										variant="titleSmall"
									>
										{t('contact-us.warranty-heading')}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-Testid="customercare-email-warranty"
									onClick={() =>
										handleEmailRedirect(
											data.communityQuery.customerCare?.[1]?.Email
										)
									}
								>
									<EmailIcon></EmailIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.customerCare?.[1]?.Email}
									</Typography>
								</div>
								<div className="customer-care-heading">
									<Typography
										component={'h3'}
										color="primary.main"
										variant="titleSmall"
									>
										{data.communityQuery.designStudios?.[0]?.DesignStudioName}
									</Typography>
								</div>
								<div className="contact-us-item">
									<LocationIcon></LocationIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.designStudios?.[0]?.Direction},{' '}
										{data.communityQuery.designStudios?.[0]?.City},{' '}
										{data.communityQuery.designStudios?.[0]?.State},{' '}
										{data.communityQuery.designStudios?.[0]?.ZipCode}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-testid="DesignStudio-phone-btn"
									onClick={() =>
										handlePhoneRedirect(
											data.communityQuery.designStudios?.[0]?.Phone
										)
									}
								>
									<PhoneIcon></PhoneIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.designStudios?.[0]?.Phone}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-testid="designStudio-btn"
									onClick={() =>
										handleEmailRedirect(
											data.communityQuery.designStudios?.[0]?.EmailAddress
										)
									}
								>
									<EmailIcon></EmailIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.designStudios?.[0]?.EmailAddress}
									</Typography>
								</div>
							</Grid>
							<Grid className="sales-office" item xs={12} lg={5}>
								<Typography
									component={'h3'}
									className="sales-office-heading"
									color="primary.main"
									variant="titleSmall"
								>
									{t('contact-us.sales-office-heading')}
								</Typography>
								<div className="contact-us-item">
									<LocationIcon></LocationIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.salesCenters?.[0]?.Address1},{' '}
										{data.communityQuery.salesCenters?.[0]?.City},{' '}
										{data.communityQuery.salesCenters?.[0]?.State},{' '}
										{data.communityQuery.salesCenters?.[0]?.Zipcode}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-testid="salesCenter-phone-btn"
									onClick={() =>
										handlePhoneRedirect(
											data.communityQuery.salesCenters?.[0]?.PhoneNumberCall
										)
									}
								>
									<PhoneIcon></PhoneIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.salesCenters?.[0]?.PhoneNumberCall}
									</Typography>
								</div>
								<div
									className="contact-us-item pointer"
									role="button"
									tabIndex="0"
									data-testid="salesCenter-email-btn"
									onClick={() =>
										handleEmailRedirect(
											data.communityQuery.salesPerson?.[0]?.Email
										)
									}
								>
									<EmailIcon></EmailIcon>
									<Typography color="primary.main" variant="bodySmall">
										{data.communityQuery.salesPerson?.[0]?.Email}
									</Typography>
								</div>
								<div className="contact-us-item">
									<CalenderIcon></CalenderIcon>
									<Typography color="primary.main" variant="titleXSmall">
										{data.communityQuery.salesCenters?.[0]?.OpenNow
											? t('contact-us.open-now')
											: t('contact-us.closed-now')}
									</Typography>
								</div>
								<div className="schedule">
									{data.communityQuery.salesCenters?.[0]?.Schedule.length > 0 &&
										data.communityQuery.salesCenters?.[0]?.Schedule.map(
											(i, key) => {
												return (
													<Grid display={'flex'} key={key}>
														<Grid item xs={4.5} lg={3}>
															<Typography
																style={{ minWidth: '90px' }}
																color="primary.main"
																variant="bodySmall"
															>
																{i.day}
															</Typography>
														</Grid>
														<Grid item xs={7.5} lg={9}>
															<Typography
																style={{ minWidth: '90px' }}
																color="primary.main"
																variant="bodySmall"
															>
																{i.displayText ? i.displayText : i.hours}
															</Typography>
														</Grid>
													</Grid>
												)
											}
										)}
								</div>
							</Grid>
						</StyledGrid>
					</Box>
					<ProductOverviewBox
						sx={{
							pt: { xs: '314px', lg: '64px' },
							m: { xs: '0 -16px', lg: '64px 8px 0 8px' },
							position: 'relative',
						}}
					>
						{isDesktop ? (
							<div className="product-overview-image">
								<img
									src={data.communityQuery.heroImage.src}
									alt={data.communityQuery.heroImage.alt}
								/>
							</div>
						) : (
							<CardMedia
								className="product-overview-image-mobile"
								component="img"
								height="314"
								image={data.communityQuery.heroImage.src}
								alt={data.communityQuery.heroImage.alt}
							/>
						)}

						<div className="product-overiew">
							<div className="product-overview-text">
								<Typography
									component={'h2'}
									color="common.white"
									variant="titleMedium"
								>
									{t('contact-us.product-overview-title')}
								</Typography>
								<br />
								<Typography color="common.white" variant="bodySmall">
									{data.communityQuery.heroCopy}
								</Typography>
								<br />
								<Button
									width={isDesktop ? 'auto' : '100%'}
									style={{ marginTop: '24px' }}
									variant={'secondary-dark'}
									href={data.communityQuery.communityURL}
									target="_blank"
								>
									{t('contact-us.learn-more-btn')}
								</Button>
							</div>
						</div>
					</ProductOverviewBox>
				</>
			)}
		</PageContentWrapper>
	)
}

export default Contactus
