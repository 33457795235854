import React from 'react'
import { useTextualContext } from 'contexts/TextualContext'
import DocumentDashboard from 'components/DocumentDashboard'
import NeedHelp from 'components/NeedHelp'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import PageHeading from 'components/PageHeading'

const MyDocuments = () => {
	const { t } = useTextualContext()

	const titleText = t('my-documents.headline')
	const descriptionText = t('my-documents.description')
	const needHelpContent = t('my-documents.need-help')

	return (
		<>
			<PageContentWrapper>
				<PageHeading titleText={titleText} descriptionText={descriptionText} />
				<DocumentDashboard />
			</PageContentWrapper>
			<NeedHelp content={needHelpContent} />
		</>
	)
}

export default MyDocuments
