import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
	ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
	// Handle errors globally
	console.error('graphQLErrors', graphQLErrors)
	console.error('networkError', networkError)
})

// HTTP Link
const httpLink = createHttpLink({
	uri: process.env.REACT_APP_SITECOCRE_GRAPHQL_URL,
	headers: {
		// If required, set up authentication headers
	},
})

// TODO: if want to use Batch HTTP Link to enable batch operation
// const httpLink = new BatchHttpLink({
//   uri: process.env.REACT_APP_SITECOCRE_GRAPHQL_URL,
//   batchMax: 10, // default value
//   batchInterval: 10, // default value
// });

// Apollo Client
const client = new ApolloClient({
	link: ApolloLink.from([errorLink, httpLink]),
	cache: new InMemoryCache(),
	// Include additional client-side state management setup if needed
})

export default client
