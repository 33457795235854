import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { ReactComponent as FacebookIcon } from 'assets/svgs/social/facebook.svg'
import { ReactComponent as InstagramIcon } from 'assets/svgs/social/instagram.svg'
import { ReactComponent as LinkedInIcon } from 'assets/svgs/social/linkedIn.svg'
import { ReactComponent as PinterestIcon } from 'assets/svgs/social/pinterest.svg'
import { ReactComponent as YoutubeIcon } from 'assets/svgs/social/youtube.svg'
import Button from 'components/common/Buttons/Button'
import { useTextualContext } from 'contexts/TextualContext'
import TextIconLink from 'components/common/Links/TextIconLink'
import { LookupDispatchContext } from 'contexts/LookupContext/LookupState'
import { useContext } from 'react'
import PageContentWrapper from '../Content/PageContentWrapper'

const InfoDetails = styled(Grid)(({ theme }) => ({
	marginBottom: '64px',
	'.mattamy-btn': {
		display: 'block',
		backgroundColor: 'transparent',
	},
	'.mattamy-btn:hover': {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.common.white,
		borderColor: theme.palette.secondary.main,
	},
	'.mattamy-btn:focus::before': {
		boxShadow: `0 0 0 2px ${theme.palette.common.white}`,
	},
	'.footer-link': {
		display: 'block',
		marginTop: '16px',
	},
	'.social-link': {
		margin: '24px 0',
	},
	'.social-link a': {
		marginRight: '16px',
	},
	'.connect-underline': {
		width: '100%',
	},
	a: {
		color: theme.palette.common.white,
		textDecoration: 'none',
		width: 'fit-content',
	},
	hr: {
		width: '30%',
		height: '2px',
		border: 'none',
		margin: '1px 0',
		backgroundColor: theme.palette.secondary.lightTorquise,
	},
	[theme.breakpoints.down('lg')]: {
		'.connect-with-us': {
			marginTop: '64px',
		},
		hr: {
			width: '120px',
		},
		'.connect-underline': {
			width: '276px',
		},
	},
}))

const LegalContent = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.functional.footerGrey,
	borderTop: `2px solid ${theme.palette.primary.grey}`,
	textAlign: 'center',
	color: theme.palette.common.white,
	'.copyrights, .legal': {
		padding: '40px 0',
		width: '100%',
	},
	'.legal a span': {
		padding: '0 4px !important',
	},
	a: {
		color: theme.palette.common.white,
		textDecoration: 'none',
		whiteSpace: 'pre',
	},
	[theme.breakpoints.down('lg')]: {
		'.copyrights': {
			padding: '16px 0 40px 0',
		},
		'.legal': {
			padding: '40px 0 16px 0',
		},
		'.legal span': {
			whiteSpace: 'pre-wrap',
		},
	},
	[theme.breakpoints.up('lg')]: {
		'.legal': {
			justifyContent: 'right !important',
		},
	},
}))

const handleRedirect = (url) => {
	window.open(url, '_blank')
}

export const Footer = () => {
	const { t } = useTextualContext()
	const dispatch = useContext(LookupDispatchContext)
	const showTutorialHandler = () => {
		dispatch({ type: 'SHOW_TUTORIAL', showTutorial: true })
	}
	const legalContent = t('footer.legal').filter(
		(item) => item.link.indexOf('error') === -1
	)
	return (
		<Box
			component="footer"
			sx={{
				backgroundColor: 'functional.footerGrey',
				color: 'common.white',
				pt: { xs: '40px', lg: '64px' },
			}}
		>
			<PageContentWrapper
				style={{
					margin: '0 auto',
				}}
			>
				<InfoDetails justifyContent="flex-start" container>
					<Grid item xs={5.2} lg={4}>
						<Typography
							variant="titleSmall"
							data-testid={'my-mattamy-headline'}
							component={'h1'}
						>
							{t('footer.my-mattamy.headline')}
						</Typography>
						<hr />
						{Array.isArray(t('footer.my-mattamy.links')) &&
							t('footer.my-mattamy.links').map((value, key) => (
								<TextIconLink
									key={key}
									className="footer-link"
									role="link"
									to={value.link}
									state={value.data ? t(value.data) : ''}
									aria-label={value.ariaLabel}
									variant="inline-link-dark"
									analyticsid={
										value.name.replaceAll(' ', '-').toLowerCase() + '-footer'
									}
									analyticstargeturl={value.link}
									analyticstext={value.name}
								>
									{value.name}
								</TextIconLink>
							))}
					</Grid>
					<Grid item xs={5.2} lg={4}>
						<Typography
							variant="titleSmall"
							data-testid={'support-headline'}
							component={'h1'}
						>
							{t('footer.support.headline')}
						</Typography>
						<hr />
						{Array.isArray(t('footer.support.links')) &&
							t('footer.support.links').map((value, key) => (
								<TextIconLink
									key={key}
									className="footer-link"
									role="link"
									to={value.link ? value.link : '#'}
									onClick={value.name === 'Tutorial' ? showTutorialHandler : ''}
									state={value.data ? t(value.data) : ''}
									aria-label={value.ariaLabel}
									variant="inline-link-dark"
									analyticsid={
										value.name.replaceAll(' ', '-').toLowerCase() + '-footer'
									}
									analyticstargeturl={value.link}
									analyticstext={value.name}
								>
									{value.name}
								</TextIconLink>
							))}
					</Grid>
					<Grid className="connect-with-us" item xs={12} lg={4}>
						<Typography
							variant="titleSmall"
							data-testid={'connect-with-us-headline'}
							component={'h1'}
						>
							{t('footer.connect-with-us.headline')}
						</Typography>
						<hr className="connect-underline" />
						<div className="social-link">
							<Link
								to={t('footer.connect-with-us.social.facebook')}
								target="_blank"
								aria-label="Visit our account on Facebook"
							>
								<FacebookIcon role="button" />
							</Link>
							<Link
								to={t('footer.connect-with-us.social.instagram')}
								target="_blank"
								aria-label="Visit our account on Instagram"
								role="button"
							>
								<InstagramIcon />
							</Link>
							<Link
								to={t('footer.connect-with-us.social.youtube')}
								target="_blank"
								aria-label="Visit our account on Youtube"
								role="button"
							>
								<YoutubeIcon />
							</Link>
							<Link
								to={t('footer.connect-with-us.social.pinterest')}
								target="_blank"
								aria-label="Visit our account on Pinterest"
								role="button"
							>
								<PinterestIcon />
							</Link>
							<Link
								to={t('footer.connect-with-us.social.linkedIn')}
								target="_blank"
								aria-label="Visit our account on LinkedIn"
								role="button"
							>
								<LinkedInIcon />
							</Link>
						</div>
						<Button
							className="mattamy-btn"
							variant="primary"
							data-testid={'mattamy-btn-redirect'}
							onClick={() =>
								handleRedirect(
									t('footer.connect-with-us.website-redirect.link')
								)
							}
							aria-label={t(
								'footer.connect-with-us.website-redirect.ariaLabel'
							)}
						>
							{t('footer.connect-with-us.website-redirect.name')}
						</Button>
					</Grid>
				</InfoDetails>

				<LegalContent justifyContent="space-between" container item>
					<Grid
						item
						container
						className="copyrights"
						direction="row"
						alignItems="center"
						justifyContent={{
							xs: 'center',
							lg: 'flex-start',
						}}
						xs={12}
						sm={12}
						md={12}
						lg={5}
						order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
					>
						<Typography variant="linkFilter">
							{t('footer.copyright')}
						</Typography>
					</Grid>
					<Grid
						item
						container
						className="legal"
						direction="row"
						alignItems="center"
						justifyContent={{
							xs: 'center',
							lg: 'flex-end',
						}}
						xs={12}
						sm={12}
						md={12}
						lg={7}
						order={{ xs: 1, sm: 1, md: 1, lg: 2 }}
					>
						{legalContent.length > 0 &&
							legalContent.map((value, key) => (
								<span key={key}>
									<TextIconLink
										role="link"
										to={value.link}
										state={value.content ? value.content : ''}
										aria-label={value.ariaLabel}
										variant="inline-link-dark"
									>
										{value.name}
									</TextIconLink>
									{key < legalContent.length - 1 ? (
										<span style={{ margin: '0 8px' }}>|</span>
									) : (
										''
									)}
								</span>
							))}
					</Grid>
				</LegalContent>
			</PageContentWrapper>
		</Box>
	)
}
