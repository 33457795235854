const LookupReducer = (state, action) => {
	switch (action.type) {
		case 'UPDATE_BASIC_DATA':
			return {
				...state,
				...action.payload,
			}
		case 'UPDATE_SELECTED_LOT':
			return {
				...state,
				selectedLot: action.payload.lot,
				selectedCommunity: action.payload.community,
				selectedDivision: action.payload.division,
			}
		case 'UPDATE_PRE_REQUEST_BODY':
			return {
				...state,
				preRequestBody: action.payload,
			}
		case 'UPDATE_DOCUMENT_ARRAY':
			return {
				...state,
				documents: action.payload,
			}
		case 'LOAD_COMPLETE':
			return {
				...state,
				loading: false,
			}
		case 'MILESTONE_LOAD_COMPLETE':
			return {
				...state,
				milestoneLoading: action.loading,
			}
		case 'SHOW_TUTORIAL':
			return {
				...state,
				showTutorial: action.showTutorial,
			}
		case 'UPDATE_LEGAL_CONTENT':
			return {
				...state,
				legalContentFlags: { ...state.legalContentFlags, ...action },
			}
		// case 'UPDATE_TERMS_AND_CONDITIONS':
		// 	return {
		// 		...state,
		// 		showLegalContent: action.showLegalContent,
		// 	}
		// case 'UPDATE_PRIVACY_POLICY':
		// 	return {
		// 		...state,
		// 		showLegalContent: action.showLegalContent,
		// 	}
		default:
			return state
	}
}

export default LookupReducer
