import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Container, CssBaseline, ThemeProvider } from '@mui/material'
import basicTheme from './theme'
import AppRoutes from 'Routes'
import { MsalProvider } from '@azure/msal-react'
import { AppProvider } from 'contexts/AppContext'
import { TextualProvider } from 'contexts/TextualContext'
import Loading from 'components/layout/Loading'

const App = ({ instance }) => (
	<main>
		<ErrorBoundary fallback={<div>Something went wrong</div>}>
			<MsalProvider instance={instance}>
				<ThemeProvider theme={basicTheme}>
					<AppProvider>
						<TextualProvider>
							<Loading />
							<CssBaseline />
							<Container disableGutters maxWidth={false}>
								<AppRoutes />
							</Container>
						</TextualProvider>
					</AppProvider>
				</ThemeProvider>
			</MsalProvider>
		</ErrorBoundary>
	</main>
)

export default App
