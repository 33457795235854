import React, { useEffect, useState } from 'react'
import { Drawer, Modal, styled, useMediaQuery } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { useIdleTimer } from 'react-idle-timer'
import IdleTimerContent from './IdleTimerContent'

const StyledDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
	},
})

const SessionTimeoutWarning = () => {
	const timeoutSecond = 15 * 60

	// eslint-disable-next-line no-unused-vars
	const [state, setState] = useState('Active')
	const [count, setCount] = useState(0)
	const [remaining, setRemaining] = useState(timeoutSecond)

	const { instance } = useMsal()
	const [showWarning, setShowWarning] = useState(false)
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))

	const onIdle = () => {
		setState('Idle')
		handleLogout()
	}

	const onActive = () => {
		setState('Active')
	}

	const onAction = () => {
		setCount(count + 1)
	}

	const onPrompt = () => {
		setState('Prompted')
		setShowWarning(true)
	}

	const { getRemainingTime, reset: resetIdleTimer } = useIdleTimer({
		onIdle,
		onActive,
		onAction,
		onPrompt,
		timeout: timeoutSecond * 1000,
		promptBeforeIdle: timeoutSecond * 0.4 * 1000,
		throttle: 500,
	})

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000))
		}, 500)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const handleStay = () => {
		setShowWarning(false)
		resetIdleTimer() // Reset the timer
	}

	const handleLogout = () => {
		const idToken = sessionStorage.getItem('idToken')

		sessionStorage.clear()
		instance.logoutRedirect({
			idTokenHint: idToken,
			onRedirectNavigate: () => {
				return true
			},
		})
	}

	const content = (
		<IdleTimerContent
			handleStay={handleStay}
			handleLogout={handleLogout}
			remaining={remaining}
		/>
	)

	return (
		<>
			{/* <p>Current State: {state}</p>
			<p>Remaining Seconds: {remaining}</p>
			<p>Action Events: {count}</p> */}
			{isMobile ? (
				<StyledDrawer
					anchor="bottom"
					open={showWarning}
					onClose={(event, reason) => {
						if (reason === 'escapeKeyDown') handleStay(event)
					}}
					aria-labelledby="session-timeout-title"
					aria-describedby="session-timeout-description"
				>
					<div role="dialog">{content}</div>
				</StyledDrawer>
			) : (
				<Modal
					open={showWarning}
					onClose={(event, reason) => {
						if (reason === 'escapeKeyDown') handleStay(event)
					}}
					aria-labelledby="session-timeout-title"
					aria-describedby="session-timeout-description"
				>
					<div role="dialog">{content}</div>
				</Modal>
			)}
		</>
	)
}

export default SessionTimeoutWarning
