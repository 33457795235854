import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Button, Tooltip, Popper as MuiPopper } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ReactComponent as SortUpper } from 'assets/svgs/actions/sortUpper.svg'
import { ReactComponent as SortLower } from 'assets/svgs/actions/sortLower.svg'
import { SORT_ORDER } from 'utils/constants'

const StyledButton = styled(Button)(({ theme }) => ({
	color: theme.palette.common.white,
	minWidth: 'auto',
	paddingBlock: 2,
	'&.active': {
		color: theme.palette.secondary.lightTorquise,
	},

	'&:focus::before': {
		content: '""',
		position: 'absolute',
		width: 14,
		height: 8,
		boxShadow: `0 0 0 2px ${theme.palette.common.white}`,
		borderRadius: '1px',
	},
}))

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
	height: '50px',
	backgroundColor: theme.palette.secondary.main,
	borderRadius: '12px',
	// margin: 0,
	'&.MuiTooltip-popper': {
		// margin: 24,
		// padding: '16px 20px 16px 20px',
	},
	'.MuiTooltip-tooltip': {
		...theme.typography.linkFilter,
		backgroundColor: 'transparent',
		margin: 20,
	},
}))

const TableHeaderSorter = ({ options, currentState, onStateChange }) => {
	const [hoverPart, setHoverPart] = useState(null)
	const [tooltipText, setTooltipText] = useState(null)
	const [ascText, setAscText] = useState('')
	const [dscText, setDscText] = useState('')

	const handleSortChange = (direction) => {
		if (currentState !== direction) {
			onStateChange(direction)
		}
	}

	useEffect(() => {
		if (hoverPart) {
			if (hoverPart === SORT_ORDER.ASC) setTooltipText(ascText)
			else if (hoverPart === SORT_ORDER.DSC) setTooltipText(dscText)
		}
	}, [hoverPart])

	useEffect(() => {
		if (options) {
			const ascOption = options.filter(
				(option) => option.order === SORT_ORDER.ASC
			)

			if (ascOption.length > 0) setAscText(ascOption[0].label)

			const dscOption = options.filter(
				(option) => option.order === SORT_ORDER.DSC
			)

			if (dscOption.length > 0) setDscText(dscOption[0].label)
		}
	}, [])

	return (
		<Tooltip
			title={tooltipText}
			PopperComponent={StyledPopper}
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [22, 22],
						},
					},
				],
			}}
		>
			<Stack justifyContent={'center'}>
				<StyledButton
					className={currentState === SORT_ORDER.ASC ? 'active' : ''}
					onClick={() => handleSortChange(SORT_ORDER.ASC)}
					onMouseEnter={() => setHoverPart(SORT_ORDER.ASC)}
					onMouseLeave={() => setHoverPart(null)}
					aria-label={ascText}
				>
					<SortUpper />
				</StyledButton>
				<StyledButton
					className={currentState === SORT_ORDER.DSC ? 'active' : ''}
					onClick={() => handleSortChange(SORT_ORDER.DSC)}
					onMouseEnter={() => setHoverPart(SORT_ORDER.DSC)}
					onMouseLeave={() => setHoverPart(null)}
					aria-label={dscText}
				>
					<SortLower />
				</StyledButton>
			</Stack>
		</Tooltip>
	)
}

TableHeaderSorter.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			order: PropTypes.oneOf([
				SORT_ORDER.DEFAULT,
				SORT_ORDER.ASC,
				SORT_ORDER.DSC,
			]),
		})
	).isRequired,
	currentState: PropTypes.oneOf([
		SORT_ORDER.DEFAULT,
		SORT_ORDER.ASC,
		SORT_ORDER.DSC,
	]).isRequired,
	onStateChange: PropTypes.func.isRequired,
}

export default TableHeaderSorter
