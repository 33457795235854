import React from 'react'
import { Box } from '@mui/material'
import { useTextualContext } from 'contexts/TextualContext'
import MilestoneDashboard from 'components/MilestoneDashboard'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import PageHeading from 'components/PageHeading'

const MyJourney = () => {
	const { t } = useTextualContext()

	const titleText = t('my-journey.headline')
	const descriptionText = t('my-journey.description')

	return (
		<Box position={'relative'}>
			<PageContentWrapper>
				<PageHeading titleText={titleText} descriptionText={descriptionText} />
				<MilestoneDashboard />
			</PageContentWrapper>
		</Box>
	)
}

export default MyJourney
