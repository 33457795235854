import React, { createContext, useContext, useState } from 'react'

// Create context
export const AppContext = createContext()

// Provider component
export const AppProvider = ({ children }) => {
	const [globalAlertState, setGlobalAlertState] = useState({
		open: false,
		title: '',
		message: '',
		type: 'info', // 'error', 'info', 'success'
	})

	const [consentGiven, setConsetGiven] = useState(false)

	const [pageAlertState, setPageAlertState] = useState({
		open: false,
		title: '',
		message: '',
		type: 'info', // 'error', 'info', 'success'
	})

	const [globalLoadingState, setglobalLoadingState] = useState(false)
	const [appTimerStart, setAppTimerStart] = useState()

	const minLoadingDuration = 2000

	// loader functions
	const showLoader = () => {
		setAppTimerStart(Date.now())

		setglobalLoadingState(true)
	}

	const hideLoader = () => {
		const elapsedTime = Date.now() - appTimerStart
		const remainingTime = Math.max(0, minLoadingDuration - elapsedTime)

		setTimeout(() => {
			setglobalLoadingState(false)
		}, remainingTime)
	}

	// alert functions
	const showAlert = (type, title, message) =>
		setGlobalAlertState({ open: true, title, message, type })

	const hideAlert = () => {
		sessionStorage.setItem('mhcpServiceMessageFlag', 'false')
		setGlobalAlertState((prev) => ({ ...prev, open: false }))
	}

	const showPageAlert = (type, title, message) =>
		setPageAlertState({ open: true, title, message, type })

	const hidePageAlert = () =>
		setPageAlertState((prev) => ({ ...prev, open: false }))

	return (
		<AppContext.Provider
			value={{
				globalAlertState,
				showAlert,
				hideAlert,
				pageAlertState,
				showPageAlert,
				hidePageAlert,
				globalLoadingState,
				showLoader,
				hideLoader,
				consentGiven,
				setConsetGiven,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

// Context hook
export const useAppContext = () => {
	const context = useContext(AppContext)
	return context
}
