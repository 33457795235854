import React, { createContext, useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
const SITECORE_COTNENT_QUERY = loader('./SitecoreContent.query.graphql')

import milestoneService from 'services/milestoneService'
import { useLookupContext } from 'contexts/LookupContext'

import { MILESTONE_STATUS, SITECORE_HOMETYPE_MATCH } from 'utils/constants'
import { getLotProductTypeKey } from 'utils/string.utils'
import { LookupDispatchContext } from 'contexts/LookupContext/LookupState'

// Create context
export const MilestoneContext = createContext()

// Provider component
export const MilestoneProvider = ({ children }) => {
	const dispatch = useContext(LookupDispatchContext)
	const [state, setState] = useState({
		sitecoreResponse: undefined,
		backendResponse: undefined,
		activeIndex: undefined,
		isInProgress: undefined,
		completionTracker: undefined,
	})
	const { selectedDivision, selectedCommunity, selectedLot, preRequestBody } =
		useLookupContext()

	const isLookupFetched = !!selectedLot

	const { loading, error, data } = useQuery(SITECORE_COTNENT_QUERY, {
		skip: !isLookupFetched,
		variables: {
			divisionCode: selectedDivision?.divisionMpcCd,
			communityMPC: selectedCommunity?.marketingMpcCd
				? selectedCommunity.marketingMpcCd
				: selectedCommunity?.communityMpcCd,
		},
	})

	// console.log('Milestone sitecore', loading, error, data)

	const updateMilestoneCompleteStatus = (loading) => {
		dispatch({ type: 'MILESTONE_LOAD_COMPLETE', loading: loading })
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (error) throw Error('Sitecore error: ' + error)

				const lotProductTypeKey = getLotProductTypeKey(selectedLot.productType)
				const targetHomeType = SITECORE_HOMETYPE_MATCH[lotProductTypeKey]
				const milestoneHomeTypes =
					data?.milestoneQuery.milestoneHomeTypes.filter((value) =>
						value.HomeTypes.includes(targetHomeType)
					)

				const milestoneInfo = milestoneHomeTypes[0].MilestoneInfo?.slice() ?? []
				// Temp fix for Home Sweet Home Milestone
				const updatedMilestoneInfo = structuredClone(milestoneInfo)

				const mattamyUniversityIndex = updatedMilestoneInfo.findIndex(
					(value) => value.Title === 'Mattamy University'
				)
				updatedMilestoneInfo.splice(mattamyUniversityIndex, 1)
				// Temp fix end
				// create request body
				const requestBodyMilestones = updatedMilestoneInfo.map(
					(value, index) => {
						return {
							milestoneSeq: index + 1,
							milestoneJdeCd: index > 1 ? value.ConstructionCode : null,
							milestoneJdeName: value.JdeName ? value.JdeName : '',
							milestoneName: value.Title,
						}
					}
				)
				const requestData = { ...preRequestBody }
				delete requestData.divisions[0].communities[0].marketingMpcCd

				const temp = requestData.divisions[0].communities[0].lots[0]
				requestData.divisions[0].communities[0].lots[0] = {
					...temp,
					milestones: [...requestBodyMilestones],
				}
				// console.log(requestData)

				// fetch request

				const response = await milestoneService.getMilestones(requestData)

				const milestones =
					response.divisions[0].communities[0].lots[0].milestones
				const completionTracker =
					response.divisions[0].communities[0].lots[0].completionTracker

				const inProgressMilestones = milestones.filter(
					(milestone) =>
						milestone.milestoneStatus.toUpperCase() ===
						MILESTONE_STATUS.IN_PROGRESS
				)

				let activeIndex = null
				let isInProgress = false
				if (inProgressMilestones.length > 0) {
					activeIndex = parseInt(inProgressMilestones[0]?.milestoneSeq)
					isInProgress = true
				}

				if (!isInProgress) {
					const completedMilestones = milestones.filter(
						(milestone) =>
							milestone.milestoneStatus.toUpperCase() ===
							MILESTONE_STATUS.COMPLETED
					)

					activeIndex = parseInt(completedMilestones.length + 1)
				}

				setState({
					sitecoreResponse: milestoneInfo,
					backendResponse: milestones,
					activeIndex,
					isInProgress,
					completionTracker: completionTracker,
				})
				updateMilestoneCompleteStatus(false)
			} catch (err) {
				updateMilestoneCompleteStatus(false)
				console.error(err)
			}
		}

		if (isLookupFetched && !loading) fetchData()
	}, [loading])

	return (
		<MilestoneContext.Provider
			value={{
				...state,
			}}
		>
			{children}
		</MilestoneContext.Provider>
	)
}

// Context hook
export const useMilestoneContext = () => {
	const context = useContext(MilestoneContext)
	return context
}
