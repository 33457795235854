import { LOT_PRODUCT_TYPE } from './constants'

/**
 * Splits a name string into first and last names.
 * @param {string} nameString - The name string to split.
 * @returns {Object} An object with 'firstName' and 'lastName' properties, or an object with default values if the format is invalid.
 */
const splitName = (nameString) => {
	const parts = nameString.split(', ')
	if (parts.length === 2) {
		const [lastName, firstName] = parts
		return {
			firstName,
			lastName,
		}
	} else {
		return {
			firstName: nameString,
			lastName: '',
		}
	}
}

/**
 * Replace a pattern of space followed by brackets with a hyphen and the content inside the brackets
 * @param {string} input - The elevation name string to split.
 * @returns {string} output.
 */
const transformElevationNameString = (input = '') => {
	return input.replace(/\s\(([^)]+)\)/, '-$1')
}

/**
 * Splits a name string into long and short term.
 * @param {string} nameString - The name string to split.
 * @returns {Object} An object with 'longName' and 'shortName' properties, or an object with default values if the format is invalid.
 */
const splitElevationName = (nameString) => {
	const parts = transformElevationNameString(nameString).split('-')
	if (parts.length === 2) {
		const [longName, shortName] = parts
		return {
			longName,
			shortName,
		}
	} else {
		return {
			longName: nameString,
			shortName: '',
		}
	}
}

/**
 * Combines the first name and last name to get a full name for display.
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @returns {string} The full name.
 */
const getDisplayFullName = (firstName, lastName) => {
	if (firstName && lastName) {
		return `${firstName} ${lastName}`
	} else {
		return firstName || lastName
	}
}

/**
 * Get the key from LOT_PRODUCT_TYPE based on the provided string.
 * @param {string} str - The input string to check against LOT_PRODUCT_TYPE values.
 * @returns {string|null} - The key from LOT_PRODUCT_TYPE if a match is found, otherwise null.
 */
const getLotProductTypeKey = (str) => {
	// Convert the input string to lowercase for case-insensitive comparison
	const strLowerCase = str.toLowerCase()

	// Iterate through the keys of LOT_PRODUCT_TYPE
	for (const key in LOT_PRODUCT_TYPE) {
		// Check if the lowercase value of the key is present in the lowercase input string
		if (strLowerCase.includes(LOT_PRODUCT_TYPE[key])) {
			// Return the key if a match is found
			return key
		}
	}

	// Return default value if no match is found
	return 'DETACHED'
}

/**
 * Converts a Vimeo video URL to a player URL with specific parameters.
 *
 * @param {string} inputURL - The input Vimeo video URL.
 * @returns {string} - The converted player URL.
 */
const convertVimeoURL = (inputURL) => {
	// Check if the input is a valid Vimeo URL
	const vimeoRegex = /https:\/\/vimeo\.com\/(\d+)\/([a-zA-Z0-9_-]+)\?share=copy/
	const match = inputURL.match(vimeoRegex)

	if (!match) {
		return inputURL
	}

	// Extract video ID and hash from the matched regex groups
	const videoID = match[1]
	const hash = match[2]

	// Construct the player URL with the extracted parameters
	const playerURL = `https://player.vimeo.com/video/${videoID}?h=${hash}`

	return playerURL
}

/**
 * Strip HTML tags from the provided text.
 * @param {string} text - The input text containing HTML tags.
 * @returns {string} - The text with HTML tags removed.
 */
const stripHtmlTags = (text) => {
	// Create a temporary element (a div) to parse and clean the HTML content
	const tempElement = document.createElement('div')
	tempElement.innerHTML = text

	// Return the text content of the div, which is now free of HTML tags
	return tempElement.textContent || tempElement.innerText
}

/**
 * Strip the file extension from the provided filename.
 * @param {string} filename - The input filename with extension.
 * @returns {string} - The filename without the file extension.
 */
const stripFileExtension = (filename) => {
	// Use the lastIndexOf method to find the last occurrence of the dot (.)
	// and extract the substring before it, representing the filename without the extension
	const lastDotIndex = filename.lastIndexOf('.')
	return lastDotIndex !== -1 ? filename.slice(0, lastDotIndex) : filename
}

const convertToKebabCase = (inputStr) => {
	return inputStr
		.trim()
		.toLowerCase()
		.replace(/[^a-z0-9 -]/g, '') // Remove any character that is not alphanumeric, space, or hyphen
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
}

export {
	splitName,
	splitElevationName,
	getDisplayFullName,
	getLotProductTypeKey,
	convertVimeoURL,
	stripHtmlTags,
	stripFileExtension,
	convertToKebabCase,
}
