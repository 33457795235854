import { styled } from '@mui/material/styles'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useTextualContext } from 'contexts/TextualContext'
import MHLogo from 'assets/images/MH.png'

const LoaderIcon = styled(Box)(({ theme }) => ({
	'&.loader-shape': {
		position: 'relative',
		marginLeft: '48%',
		marginTop: '50px',
		height: '145px',
	},
	'&.loader-shape div': {
		backgroundColor: '#D9D9D9',
		backgroundSize: '200% 100%',
	},
	'&.loader-shape .top-1': {
		position: 'absolute',
		width: '107.23px',
		height: '12.18px',
		borderRadius: '8px',
		transform: 'rotate(139.91deg)',
		top: 0,
		left: '-73px',
		backgroundSize: '200% 100%',
		backgroundImage: `linear-gradient(to right, #D9D9D9 50%, ${theme.palette.secondary.main} 50%)`,
		animation: 'progressbar2 5s ease infinite',
		zIndex: 30,
	},
	'&.loader-shape .top-2': {
		position: 'absolute',
		width: '107.23px',
		height: '12.18px',
		borderRadius: '8px',
		transform: 'rotate(-139.91deg)',
		backgroundSize: '200% 100%',
		backgroundImage: `linear-gradient(to right, #D9D9D9 50%, ${theme.palette.secondary.main} 50%)`,
		animation: 'progressbar1 5s ease infinite',
		zIndex: '40',
	},
	'&.loader-shape .left': {
		position: 'absolute',
		width: '113px',
		height: '12px',
		borderRadius: '8px',
		transform: 'rotate(90deg)',
		top: '82.5px',
		left: '-113px',
		backgroundSize: '200% 100%',
		backgroundImage: `linear-gradient(to right, #D9D9D9 50%, ${theme.palette.secondary.main} 50%)`,
		animation: 'progressbar3 5s ease infinite',
		zIndex: '20',
	},
	'&.loader-shape .right': {
		position: 'absolute',
		width: '113px',
		height: '12px',
		borderRadius: '8px',
		transform: 'rotate(-90deg)',
		top: '82px',
		left: '34px',
		backgroundSize: '200% 100%',
		backgroundImage: `linear-gradient(to right, #D9D9D9 50%, ${theme.palette.secondary.main} 50%)`,
		animation: 'progressbar 5s ease infinite',
		zIndex: '50',
	},
	'&.loader-shape .bottom': {
		position: 'absolute',
		height: '12px',
		width: '158px',
		borderRadius: '8px',
		top: '133px',
		left: '-62px',
		transform: 'rotate(0deg)',
		backgroundSize: '200% 100%',
		backgroundImage: `linear-gradient(to right, #D9D9D9 50%, ${theme.palette.secondary.main} 50%)`,
		animation: 'progressbar4 5s ease infinite',
		zIndex: '10',
	},
	'@-webkit-keyframes progressbar': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'20%, 100%': {
			backgroundPosition: '-100% 1%',
		},
	},
	'@-webkit-keyframes progressbar1': {
		'0%,20%': {
			backgroundPosition: '0 0',
		},
		'40%, 100%': {
			backgroundPosition: '-100% 0',
		},
	},
	'@-webkit-keyframes progressbar2': {
		'0%, 40%': {
			backgroundPosition: '0 0',
		},
		'60%, 100%': {
			backgroundPosition: '-100% 0',
		},
	},
	'@-webkit-keyframes progressbar3': {
		'0%, 60%': {
			backgroundPosition: '0 0',
		},
		'80%, 100%': {
			backgroundPosition: '-100% 0',
		},
	},
	'@-webkit-keyframes progressbar4': {
		'0%,80%': {
			backgroundPosition: '0 0',
		},
		'90%, 100%': {
			backgroundPosition: '-100% 0',
		},
	},
	[theme.breakpoints.down('lg')]: {
		'&.loader-shape': {
			marginTop: '45px',
		},
	},
}))

const Loader = () => {
	const { t } = useTextualContext()
	const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	return (
		<div
			style={{
				width: 'auto',
				height: 325,
				position: 'relative',
				textAlign: 'center',
				justifyContent: 'center',
			}}
		>
			<img
				style={{
					marginTop: isMobileScreen ? '100px' : '110px',
					marginLeft: '-30px',
					position: 'absolute',
				}}
				role="presentation"
				src={MHLogo}
			/>
			<LoaderIcon className="loader-shape">
				<div className="top-1"></div>
				<div className="top-2"></div>
				<div className="left"></div>
				<div className="right"></div>
				<div className="bottom"></div>
			</LoaderIcon>
			<Typography
				className="loading-message"
				color="primary.main"
				component={'div'}
				variant={'titleSmall'}
				style={{
					textAlign: 'center',
					padding: '0px 80px 0px 100px',
					marginTop: '40px',
				}}
			>
				{t('common.loading')}
			</Typography>
		</div>
	)
}

export default Loader
