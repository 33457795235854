import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, useMediaQuery } from '@mui/material'
import MilestoneBadge from 'components/MilestoneMap/MilestoneBadge'
import MilestoneText from 'components/MilestoneMap/MilestoneText'

import { ReactComponent as MilestonePath } from 'assets/svgs/milestone/milestonePath.svg'
import { ReactComponent as MilestonePathMobile } from 'assets/svgs/milestone/milestonePathMobile.svg'
import { ReactComponent as MilestoneLastPath } from 'assets/svgs/milestone/milestoneLastPath.svg'
import { ReactComponent as MilestoneLastPathMobile } from 'assets/svgs/milestone/milestoneLastPathMobile.svg'

import BirthdayAnimation from 'assets/images/Birthday.gif'
import { MILESTONE_ICON_MATCH, MILESTONE_STATUS } from 'utils/constants'
import { convertToKebabCase } from 'utils/string.utils'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/system'

// Temp fix for Home Sweet Home Milestone
const MilestoneLastPathLine = styled(MilestoneLastPath)(({ ...props }) => ({
	zIndex: -1,
	position: 'absolute',
	transition: 'stroke 1500ms',
	transitionDelay: '500ms',
	top: -150,
	right: 55,
	line: {
		stroke: props.strokeColor,
	},
}))

const MilestoneLastPathMobileLine = styled(MilestoneLastPathMobile)(
	({ ...props }) => ({
		zIndex: -1,
		position: 'absolute',
		transition: 'stroke 1500ms',
		transitionDelay: '500ms',
		top: -60,
		right: 42,
		line: {
			stroke: props.strokeColor,
		},
	})
)
// Temp fix End

const MilestoneMap = ({
	milestones,
	activeIndex,
	isInProgress,
	onViewMilestoneDetails,
}) => {
	const { hash } = useLocation()
	const imgRef = useRef(null)

	const [hovered, setHovered] = useState()
	// eslint-disable-next-line no-unused-vars
	const [activeMilestoneIndex, setActiveMilestoneIndex] = useState(activeIndex)

	// TODO: confirm animation confetti is gonna be removed
	const [isCelebrating, setIsCelebrating] = useState(
		activeMilestoneIndex >= milestones.length - 1
	)

	const onFocusHandler = (value) => {
		setHovered(value)
	}

	useEffect(() => {
		const handleScroll = () => {
			const targetElement = imgRef.current
			if (!targetElement) return

			const rect = targetElement.getBoundingClientRect()
			const isElementInViewport =
				rect.top <= window.innerHeight && rect.bottom >= 0

			if (isElementInViewport) {
				// Element is in the viewport, trigger your timeout event here
				setTimeout(() => {
					setIsCelebrating(false)
				}, 3000)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	const alignments = [
		{ justifyContent: 'center' },
		{ justifyContent: 'flex-end' },
		{ justifyContent: 'center' },
		{ justifyContent: 'flex-start' },
	]

	const milestoneCircleWidth = isDesktopView ? 128 : 98
	const milestonePathStrokeWidth = isDesktopView ? 48 : 32

	const pathTransformations = [
		{
			transform: 'scale(-1, -1)',
			bottom: (milestoneCircleWidth - milestonePathStrokeWidth) / 2,
			right: milestoneCircleWidth / 2,
		},
		{
			transform: 'scale(1, 1)',
			bottom: milestoneCircleWidth / 2,
			right: (milestoneCircleWidth - milestonePathStrokeWidth) / 2,
		},
		{
			transform: 'scale(1, -1)',
			bottom: (milestoneCircleWidth - milestonePathStrokeWidth) / 2,
			left: milestoneCircleWidth / 2,
		},
		{
			transform: 'scale(-1, 1)',
			bottom: milestoneCircleWidth / 2,
			left: (milestoneCircleWidth - milestonePathStrokeWidth) / 2,
		},
	]

	const scrollToMiddle = (element) => {
		if (!element) return

		const elementRect = element.getBoundingClientRect()
		const absoluteElementTop = window.scrollY + elementRect.top
		const middlePosition =
			absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2

		setTimeout(() => {
			window.scrollTo({ top: middlePosition, behavior: 'smooth' })
		}, 500)
	}

	useEffect(() => {
		if (hash) {
			const section = document.getElementById(hash.substring(1))
			scrollToMiddle(section)
		}
	}, [])

	return (
		<Box
			display="flex"
			flexDirection="column"
			mt={isDesktopView ? 25 : 12}
			justifyContent="center"
			alignItems="center"
			paddingInlineStart={0}
			component={'ol'}
		>
			{milestones.map((milestone, index) => {
				// Temp fix for Home Sweet Home Milestone
				let currentStatus = ''
				if (index !== milestones.length - 1) {
					currentStatus =
						activeMilestoneIndex === index && isInProgress
							? MILESTONE_STATUS.IN_PROGRESS
							: activeMilestoneIndex > index
								? MILESTONE_STATUS.COMPLETED
								: MILESTONE_STATUS.UPCOMING
				} else {
					const secondLastMilestoneStatus =
						activeMilestoneIndex === index - 1 && isInProgress
							? MILESTONE_STATUS.IN_PROGRESS
							: activeMilestoneIndex > index - 1
								? MILESTONE_STATUS.COMPLETED
								: MILESTONE_STATUS.UPCOMING
					if (secondLastMilestoneStatus === MILESTONE_STATUS.COMPLETED) {
						currentStatus = MILESTONE_STATUS.COMPLETED
					} else {
						currentStatus = MILESTONE_STATUS.UPCOMING
					}
				}
				// Temp fix End
				return (
					<Box
						key={index}
						id={convertToKebabCase(milestone.Title)}
						display="flex"
						justifyContent={
							index < milestones.length - 1
								? alignments[index % alignments.length].justifyContent
								: alignments[(index - 1) % alignments.length].justifyContent
						}
						component={'li'}
						width={isDesktopView ? 876 : 352} // (398-24+64)*2 (143-16)*2+98 Adjust as necessary
						mb={isDesktopView ? 20 : 8}
						onMouseEnter={() => setHovered(index)}
						onMouseLeave={() => setHovered(null)}
					>
						<div style={{ position: 'relative' }}>
							{/* Milestone Icon */}
							<MilestoneBadge
								milestoneIcon={MILESTONE_ICON_MATCH[milestone.Title.trim()]}
								hovered={hovered === index}
								currentStatus={currentStatus}
								currentIndex={index}
								isDesktopView={isDesktopView}
								onViewMilestoneDetails={onViewMilestoneDetails}
								isLastMileStone={index === milestones.length - 1}
							/>
							{/* Celerating */}
							{index === milestones.length - 2 && isCelebrating && (
								<img
									src={BirthdayAnimation}
									ref={imgRef}
									style={{
										width: '452px',
										height: '326px',
										top: '-100px', // 326/2-64
										left: '-162px', // 452/2-64
										position: 'absolute',
										angle: '-180 deg',
									}}
								/>
							)}
							{/* Text */}
							<MilestoneText
								onFocusHandler={onFocusHandler}
								title={milestone.Title}
								description={milestone.Description}
								hovered={hovered === index}
								currentStatus={currentStatus}
								currentIndex={index}
								isDesktopView={isDesktopView}
								onViewMilestoneDetails={onViewMilestoneDetails}
								isLastMileStone={index === milestones.length - 1}
							/>
							{/* Path */}
							{index !== 0 &&
								index < milestones.length - 1 &&
								isDesktopView && (
									<MilestonePath
										style={{
											...pathTransformations[
												index % pathTransformations.length
											],
											stroke:
												activeMilestoneIndex < index ? '#97999B' : '#002D72',
											zIndex: -1,
											position: 'absolute',
											transition: 'stroke 1500ms',
											transitionDelay: '500ms',
										}}
										aria-hidden="true"
										role="presentation"
									/>
								)}
							{index !== 0 &&
								// Temp fix for Home Sweet Home Milestone
								index === milestones.length - 1 &&
								isDesktopView && (
									<MilestoneLastPathLine
										aria-hidden="true"
										role="presentation"
										strokeColor={
											currentStatus !== MILESTONE_STATUS.COMPLETED
												? '#97999B'
												: '#002D72'
										}
									/>
									// Temp fix end
								)}
							{index !== 0 &&
								index < milestones.length - 1 &&
								!isDesktopView && (
									<MilestonePathMobile
										style={{
											...pathTransformations[
												index % pathTransformations.length
											],
											stroke:
												activeMilestoneIndex < index ? '#97999B' : '#002D72',
											zIndex: -1,
											position: 'absolute',
											transition: 'stroke 1500ms',
											transitionDelay: '500ms',
										}}
										aria-hidden="true"
										role="presentation"
									/>
								)}
							{index !== 0 &&
								// Temp fix for Home Sweet Home Milestone
								index === milestones.length - 1 &&
								!isDesktopView && (
									<MilestoneLastPathMobileLine
										aria-hidden="true"
										role="presentation"
										strokeColor={
											currentStatus !== MILESTONE_STATUS.COMPLETED
												? '#97999B'
												: '#002D72'
										}
									/>
									// Temp fix end
								)}
						</div>
					</Box>
				)
			})}
		</Box>
	)
}

MilestoneMap.propTypes = {
	milestones: PropTypes.arrayOf(
		PropTypes.shape({
			Title: PropTypes.string.isRequired,
			Description: PropTypes.string.isRequired,
		})
	).isRequired,
	activeIndex: PropTypes.number,
	isInProgress: PropTypes.bool,
	onViewMilestoneDetails: PropTypes.func.isRequired,
}

MilestoneMap.defaultProps = {}

export default MilestoneMap
