import ControlledAccordion from 'components/common/Accordian/ControlledAccordian'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useTextualContext } from 'contexts/TextualContext'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import PageHeading from 'components/PageHeading'

const AccountSupportPage = () => {
	const { t } = useTextualContext()
	const location = useLocation()
	let content = location.state

	if (!content && location.pathname === '/error/accountSupport') {
		content = t('account-support')
	} else if (!content && location.pathname === '/FAQ') {
		content = t('footer.FAQ')
	}
	const accordianData = content.questions
	return (
		<>
			<PageContentWrapper>
				<PageHeading
					data-testid="headline"
					titleText={content.headline}
					descriptionText={content.description}
				/>
				<Box
					sx={{
						mt: { xs: '64px' },
					}}
				>
					<ControlledAccordion
						accordianData={accordianData}
					></ControlledAccordion>
				</Box>
			</PageContentWrapper>
		</>
	)
}

export default AccountSupportPage
