import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { sendWebAnalyticsData } from 'services/webAnalyticsService'

const StyledButton = styled(Button)(({ theme, variant }) => {
	let textColor, hoverTextColor, textTransform, boxShadow

	switch (variant) {
		case 'primary-dark':
			textColor = theme.palette.common.white
			hoverTextColor = theme.palette.common.white
			textTransform = 'uppercase'
			boxShadow = theme.palette.common.white
			break
		case 'primary':
		default:
			textColor = theme.palette.secondary.main
			hoverTextColor = theme.palette.primary.main
			textTransform = 'uppercase'
			boxShadow = theme.palette.secondary.main
			break
	}

	return {
		padding: 0,
		color: textColor,
		textTransform: textTransform,
		outline: 'none',
		justifyContent: 'flex-start',
		boxShadow: 'none',
		position: 'relative',
		gap: 4,
		minWidth: 'unset',

		'& .MuiTypography-root': {
			// have to remove space below text from particular typography type
			marginBottom: -4,
		},

		'& path': {
			fill: textColor,
		},

		'&:hover': {
			textDecoration: 'underline',
			textUnderlinePosition: 'under',
			textUnderlineOffset: -2,
			color: hoverTextColor,
			'& path': {
				fill: hoverTextColor,
			},
			backgroundColor: 'transparent',
		},
		'&:focus-visible:not(:hover)::before': {
			content: '""',
			position: 'absolute',
			top: -2,
			right: -2,
			bottom: -2,
			left: -2,
			boxShadow: `0 0 0 2px ${boxShadow}`,
			borderRadius: '2px',
		},
	}
})

const IconLink = ({
	children,
	iconType,
	iconPosition,
	onClick,
	shrink,
	...props
}) => {
	const btnRef = useRef()

	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event)
		}
		if (props.analyticsid) {
			sendWebAnalyticsData(
				props.analyticstext,
				btnRef.current,
				props.analyticstargeturl,
				btnRef.current.classList,
				props.analyticsid
			)
		}
	}
	return (
		<StyledButton onClick={handleOnClick} ref={btnRef} disableRipple {...props}>
			{iconPosition === 'before' ? iconType : ''}
			{shrink ? '' : <Typography variant={'button'}>{children}</Typography>}
			{iconPosition === 'after' ? iconType : ''}
		</StyledButton>
	)
}

IconLink.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	iconType: PropTypes.any,
	iconPosition: PropTypes.oneOf(['before', 'after', null]),
	shrink: PropTypes.bool,
	analyticsid: PropTypes.string,
	analyticstargeturl: PropTypes.string,
	analyticstext: PropTypes.string,
}

IconLink.defaultProps = {
	iconType: null,
	iconPosition: null,
	shrink: false,
}

export default IconLink
