import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/system'
import {
	Box,
	Card as MuiCard,
	CardContent,
	CardMedia,
	Typography,
	Divider as MuiDivider,
} from '@mui/material'

import { useTextualContext } from 'contexts/TextualContext'
import AlertCard from 'components/common/AlertCard'
import { splitElevationName } from 'utils/string.utils'
import { LOT_DETAIL_ZERO_COUNT_TEXT } from 'utils/constants'

const NotAvailableAlertBox = styled(Box)(({ theme }) => ({
	height: 388,
	[theme.breakpoints.up('lg')]: {
		height: 539,
	},
}))

const StyledCard = styled(MuiCard)({
	height: '100%',
	borderRadius: 8,
})

const StyledDivider = styled(MuiDivider)(({ theme }) => ({
	border: '1.5px solid',
	borderColor: theme.palette.secondary.lightTorquise,
	height: '16px',
	alignSelf: 'center',
	backgroundColor: theme.palette.secondary.lightTorquise,
	marginInline: theme.spacing(2),
}))

const YourHome = ({ data, image }) => {
	const { t } = useTextualContext()
	const [state, setState] = useState([])

	if (data === null) {
		return (
			<NotAvailableAlertBox>
				<AlertCard text={t('my-home.error-my-home-text')} />
			</NotAvailableAlertBox>
		)
	}

	const { longName: elevationDisplayName } = splitElevationName(data.elevation)

	useEffect(() => {
		const optionalDisplayList = []

		if (data.halfBathType !== LOT_DETAIL_ZERO_COUNT_TEXT.halfBathType)
			optionalDisplayList.push(data.halfBathType)

		if (data.garageType !== LOT_DETAIL_ZERO_COUNT_TEXT.garageType)
			optionalDisplayList.push(
				`${data.garageType} ${t('my-home.home-n-community-garage-text')}`
			)

		setState(optionalDisplayList)
	}, [data])

	return (
		<StyledCard>
			<CardMedia
				component="img"
				height="296"
				image={image?.src}
				alt={image?.alt}
			/>
			<CardContent>
				<Box p={2}>
					<Typography
						variant="linkFilter"
						color={'secondary.main'}
						component={'div'}
						mb={0.5}
					>
						{elevationDisplayName} | {data.model}
					</Typography>
					<Typography
						variant="labelCard"
						color={'primary.main'}
						component={'div'}
						fontSize={'26px'}
						lineHeight={'39px'}
						mb={1}
					>
						{data.street}
					</Typography>
					<Box display="flex" flexDirection="column" gap={1}>
						{/* First Row */}
						<Box display="flex">
							<Typography
								variant="bodySmall"
								color={'primary.main'}
								flex="1"
								maxWidth="120px"
							>
								{Math.trunc(data.size)} Sq. Ft.
							</Typography>
							<StyledDivider orientation="vertical" flexItem />
							<Typography variant="bodySmall" color={'primary.main'} flex="1">
								{data.productType}
							</Typography>
						</Box>

						{/* Second Row */}
						<Box display="flex">
							<Typography
								variant="bodySmall"
								color={'primary.main'}
								flex="1"
								maxWidth="120px"
							>
								{data.noOfBedrooms}
							</Typography>
							<StyledDivider orientation="vertical" flexItem />
							<Typography variant="bodySmall" color={'primary.main'} flex="1">
								{data.fullBathType}
							</Typography>
						</Box>

						{/* Optional Row */}
						{state.length > 0 && (
							<Box display="flex">
								{state.map((item, index) => (
									<React.Fragment key={index}>
										{index > 0 && (
											<StyledDivider orientation="vertical" flexItem />
										)}
										<Typography
											variant="bodySmall"
											color={'primary.main'}
											flex="1"
											maxWidth="120px"
										>
											{item}
										</Typography>
									</React.Fragment>
								))}
							</Box>
						)}
					</Box>

					{/* Declaimer */}
					<Typography
						variant="bodyXSmall"
						color={'primary.darkGrey'}
						component={'div'}
						mt={4}
						fontStyle={'italic'}
					>
						{t('my-home.home-n-community-declaimer')}
					</Typography>
				</Box>
			</CardContent>
		</StyledCard>
	)
}

YourHome.propTypes = {
	data: PropTypes.shape({
		lotJobID: PropTypes.string.isRequired,
		purchasedStatus: PropTypes.string.isRequired,
		soldDate: PropTypes.string.isRequired,
		cancelledDate: PropTypes.string.isRequired,
		closedDate: PropTypes.string.isRequired,
		productType: PropTypes.string.isRequired,
		noOfBedrooms: PropTypes.string.isRequired,
		fullBathType: PropTypes.string.isRequired,
		halfBathType: PropTypes.string.isRequired,
		garageType: PropTypes.string.isRequired,
		size: PropTypes.string.isRequired,
		model: PropTypes.string.isRequired,
		elevation: PropTypes.string.isRequired,
		stories: PropTypes.string.isRequired,
		street: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		province: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		postalCode: PropTypes.string.isRequired,
		phase: PropTypes.string.isRequired,
		hblot: PropTypes.string.isRequired,
	}).isRequired,
	image: PropTypes.shape({
		src: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}),
}

YourHome.defaultProps = {
	image: null,
}

export default YourHome
