import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import Alert from 'components/common/Alert'
import MilestoneMap from 'components/MilestoneMap'
import MattamyUniversity from 'components/MattamyUniversity'
import MilestoneDrawer from 'components/MilestoneDrawer'

import { useTextualContext } from 'contexts/TextualContext'
import { useMilestoneContext } from 'contexts/MilestoneContext'
import { MILESTONE_STATUS } from 'utils/constants'

const MilestoneDashboard = () => {
	const { t } = useTextualContext()
	const { sitecoreResponse, activeIndex, isInProgress } = useMilestoneContext()
	const [drawerContentMilestoneIndex, setDrawerContentMilestoneIndex] =
		useState()
	const [drawerContentMilestoneStatus, setDrawerContentMilestoneStatus] =
		useState()
	const [drawerContent, setDrawerContent] = useState()

	if (sitecoreResponse === undefined || activeIndex === undefined) {
		return (
			<Box mt={8} flexDirection={'column'} display={'flex'} gap={2}>
				<Typography color="primary.main" variant="titleMedium">
					{t('my-journey.error')}
				</Typography>

				<Alert
					type={'info'}
					title={t('my-journey.error-alert-title')}
					message={t('my-journey.error-alert-message')}
				/>
			</Box>
		)
	}

	const milestoneInfo = sitecoreResponse?.slice()

	// Temp fix for Home Sweet Home Milestone
	const mattamyUniversityIndex = milestoneInfo.findIndex(
		(value) => value.Title === 'Mattamy University'
	)
	const mattamyUniversity = milestoneInfo[mattamyUniversityIndex]
	milestoneInfo.splice(mattamyUniversityIndex, 1)
	const mattamyUniversityTitle = mattamyUniversity?.Title

	const milestones = milestoneInfo

	const openDrawer = (index = null) => {
		setDrawerContentMilestoneIndex(index)

		if (index === null) {
			setDrawerContentMilestoneStatus()
			setDrawerContent(mattamyUniversity)
		} else {
			const content = milestoneInfo[index]
			setDrawerContentMilestoneStatus(
				activeIndex - 1 === index && isInProgress
					? MILESTONE_STATUS.IN_PROGRESS
					: activeIndex - 1 > index
						? MILESTONE_STATUS.COMPLETED
						: MILESTONE_STATUS.UPCOMING
			)
			setDrawerContent(content)
		}
	}

	const closeDrawer = () => {
		setDrawerContentMilestoneIndex(undefined)
		setDrawerContent(undefined)
	}

	return (
		<>
			<MattamyUniversity
				title={mattamyUniversityTitle}
				buttonText={t('my-journey.learn-more-button-text')}
				onClick={() => openDrawer()}
			/>

			<MilestoneMap
				milestones={milestones}
				activeIndex={activeIndex - 1}
				isInProgress={isInProgress}
				onViewMilestoneDetails={(index) => openDrawer(index)}
			/>

			<MilestoneDrawer
				closeDrawer={closeDrawer}
				content={drawerContent}
				milestoneText={t('my-journey.milestone-text')}
				milestoneIndex={drawerContentMilestoneIndex}
				statusText={drawerContentMilestoneStatus}
			/>
		</>
	)
}

export default MilestoneDashboard
