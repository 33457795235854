import { Box, styled } from '@mui/material'

const ContentWrapper = styled(Box)(({ theme }) => ({
	paddingInline: theme.spacing(2),
	[theme.breakpoints.up('md')]: {
		paddingInline: theme.spacing(15),
	},
	[theme.breakpoints.up('lg')]: {
		paddingInline: theme.spacing(2),
	},

	maxWidth: 1232,
	marginInline: 'auto',
	width: '100%',
}))

export default ContentWrapper
