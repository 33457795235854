import { ReactComponent as WelcomeToYourNewHome } from 'assets/svgs/iconography/milestone/WelcomeToYourNewHome.svg'
import { ReactComponent as DesignYourNewHome } from 'assets/svgs/iconography/milestone/DesignYourNewHome.svg'
import { ReactComponent as ConstructionHasStarted } from 'assets/svgs/iconography/milestone/ConstructionHasStarted.svg'
import { ReactComponent as TakingShape } from 'assets/svgs/iconography/milestone/TakingShape.svg'
import { ReactComponent as YourRoofIsOn } from 'assets/svgs/iconography/milestone/YourRoofIsOn.svg'
import { ReactComponent as FinalTouches } from 'assets/svgs/iconography/milestone/FinalTouches.svg'
import { ReactComponent as YourNewHomeOrientation } from 'assets/svgs/iconography/milestone/YourNewHomeOrientation.svg'
import { ReactComponent as GetReadytoClose } from 'assets/svgs/iconography/milestone/GetReadytoClose.svg'
import { ReactComponent as MoveIn } from 'assets/svgs/iconography/milestone/MoveIn.svg'
import { ReactComponent as HomeSweetHome } from 'assets/svgs/iconography/milestone/HomeSweetHome.svg'

export const CATEGORY_FILTER_OPTIONS = {
	ALL: 'ALL',
	HOME_PURCHASE: 'Home Purchase',
	DESIGN_SELECTION: 'Design Selections',
	CONSTRUCTION: 'Construction',
	CUSTOMER_CARE_N_WARRANTY: 'Customer Care & Warranty',
	CLOSING: 'Closing',
	PHOTOS: 'Photos',
	OTHERS: 'OTHERS',
}

export const SORT_ORDER = {
	DEFAULT: '',
	ASC: 'ASC',
	DSC: 'DSC',
}

export const LOT_PRODUCT_TYPE = {
	DETACHED: 'detached',
	SEMI_DETACHED: 'semi detached',
	TOWNHOUSES: 'townhome',
}

export const SITECORE_HOMETYPE_MATCH = {
	DETACHED: 'Detached',
	SEMI_DETACHED: 'Attached',
	TOWNHOUSES: 'Attached',
}

export const MILESTONE_STATUS = {
	IN_PROGRESS: 'IN PROGRESS',
	COMPLETED: 'COMPLETED',
	UPCOMING: 'UPCOMING',
}

export const MILESTONE_ICON_MATCH = {
	'Welcome to Your New Home': <WelcomeToYourNewHome />,
	'Personalize Your New Home': <DesignYourNewHome />,
	'Construction Has Started': <ConstructionHasStarted />,
	'Taking Shape': <TakingShape />,
	'Your Roof Is On': <YourRoofIsOn />,
	'Final Touches': <FinalTouches />,
	'Your New Home Orientation': <YourNewHomeOrientation />,
	'Get Ready to Close': <GetReadytoClose />,
	'Move In': <MoveIn />,
	// Temp fix for Home Sweet Home Milestone
	'Home Sweet Home': <HomeSweetHome />,
}

export const LOT_DETAIL_ZERO_COUNT_TEXT = {
	halfBathType: 'No Partial Baths',
	garageType: 'No Garage',
}
