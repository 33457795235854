import React from 'react'
import {
	Box,
	Card as MuiCard,
	CircularProgress,
	Typography,
	useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/system'
import Button from 'components/common/Buttons/Button'
import { useMilestoneContext } from 'contexts/MilestoneContext'
import { useTextualContext } from 'contexts/TextualContext'
import { useNavigate } from 'react-router-dom'
import AlertCard from 'components/common/AlertCard'
import { convertToKebabCase } from 'utils/string.utils'

const NotAvailableAlertBox = styled(Box)(({ theme }) => ({
	height: 388,
	[theme.breakpoints.up('lg')]: {
		height: 344,
	},
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
	borderRadius: '12px',
	boxShadow: '0px 0px 20px 0px #0000001F',
	'.MuiCircularProgress-colorPrimary': {
		color: theme.palette.primary.lightBlue,
	},

	paddingBlock: 48,
	paddingInline: 24,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: 48,
	[theme.breakpoints.up('lg')]: {
		paddingInline: 48,
		flexDirection: 'row',
	},
}))

const HomeProgress = () => {
	const { t } = useTextualContext()
	const { backendResponse, isInProgress, completionTracker } =
		useMilestoneContext()
	const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	const navigate = useNavigate()

	let tagText = null,
		completedMilestoneMessage = null,
		inProgressMileStone = null
	const milestoneCompletedMessages = t(
		'my-home.completion-tracker.milestone-completed-message'
	)

	if (backendResponse === undefined) {
		return (
			<NotAvailableAlertBox data-testid="no-backend-response">
				<AlertCard text={t('my-home.error-milestone-text')} />
			</NotAvailableAlertBox>
		)
	}

	if (isInProgress != undefined) {
		const lastCompletedMilestone = backendResponse.findLastIndex(
			(i) => i.milestoneStatus === 'Completed'
		)
		// eslint-disable-next-line no-unused-vars
		const completedMileStones = lastCompletedMilestone + 1
		// eslint-disable-next-line no-unused-vars
		const totalMilestones = backendResponse.length - 1
		tagText = eval(t('my-home.completion-tracker.tag-text'))
		completedMilestoneMessage =
			milestoneCompletedMessages &&
			milestoneCompletedMessages.find(
				(i) =>
					i.milestone == backendResponse[lastCompletedMilestone].milestoneSeq
			)
		const inProgressMileStoneIndex = backendResponse.findIndex(
			(i) => i.milestoneStatus === 'In Progress'
		)
		if (inProgressMileStoneIndex !== -1) {
			inProgressMileStone = backendResponse[inProgressMileStoneIndex]
		} else {
			inProgressMileStone = backendResponse[lastCompletedMilestone]
		}
	}

	return (
		<>
			{isInProgress != undefined && (
				<StyledCard>
					{/* Progress Circle */}
					<Box position="relative" height={248}>
						{/* Trail Circle */}
						<CircularProgress
							// aria-label="Competion Tracket Progress"
							variant="determinate"
							value={100}
							size={248}
							color="primary"
						/>
						{/* Actual Progress Circle */}
						<Box position="absolute" top="0" left="0">
							<CircularProgress
								// aria-label="Competion Tracket Progress"
								variant="determinate"
								value={
									completionTracker.length > 0
										? JSON.parse(completionTracker)
										: 0
								}
								size={248}
								color="secondary"
							/>
						</Box>
						{/* Actual Progress Number */}
						<Box
							aria-valuenow={
								(completionTracker.length > 0
									? JSON.parse(completionTracker)
									: 0) + '%'
							}
							aria-label="Competion Tracket Progress"
							position="absolute"
							display={'flex'}
							justifyContent={'center'}
							width={248}
							top={80}
							textAlign="center"
						>
							<Typography
								variant="titleXLarge"
								color={'primary.main'}
								fontSize={'98px'}
								lineHeight={'114px'}
							>
								{completionTracker.length > 0
									? JSON.parse(completionTracker)
									: 0}
							</Typography>
							<Typography
								variant="titleXLarge"
								fontSize={'60px'}
								marginTop={'7px'}
								marginLeft={'5px'}
								lineHeight={'70px'}
								color={'primary.main'}
							>
								%
							</Typography>
						</Box>
						<Box position="absolute" width={248} top={167} textAlign="center">
							<Typography variant="labelTag" color={'primary.main'}>
								COMPLETE
							</Typography>
						</Box>
					</Box>

					{/* Typography and Button */}
					<Box display="flex" flexDirection="column">
						<Typography
							fontSize={'14px'}
							fontWeight={'normal'}
							lineHeight={'18px'}
							variant="bodySmall"
							color={'secondary.main'}
							mb={0.5}
						>
							{tagText}
						</Typography>
						<Typography
							style={{ textTransform: 'uppercase' }}
							variant="titleMedium"
							color={'primary.main'}
							fontSize={'26px'}
							lineHeight={'39px'}
						>
							{completedMilestoneMessage && completedMilestoneMessage.message}
						</Typography>
						<Typography
							variant="bodySmall"
							color={'primary.main'}
							marginTop={'8px'}
							marginBottom={'24px'}
						>
							{inProgressMileStone.milestoneStatus}:{' '}
							{inProgressMileStone.milestoneName}
						</Typography>
						<Button
							data-testid="milestone-btn"
							width={isMobileScreen ? '100%' : 'fit-content'}
							onClick={() =>
								navigate(
									`/myJourney#${convertToKebabCase(
										inProgressMileStone.milestoneName
									)}`
								)
							}
						>
							{t('my-home.completion-tracker.button-text')}
						</Button>
					</Box>
				</StyledCard>
			)}
		</>
	)
}

export default HomeProgress
