export const typography = {
	titleXLarge: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 60,
		fontWeight: 'normal',
		lineHeight: '70px',
		letterSpacing: '-0.02em',
	},
	titleLarge: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 46,
		fontWeight: 'normal',
		lineHeight: '54px',
		letterSpacing: '-0.02em',
	},
	titleMedium: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 36,
		fontWeight: 'normal',
		lineHeight: '42px',
		letterSpacing: '-0.01em',
	},
	titleSmall: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 22,
		fontWeight: 'normal',
		lineHeight: '30px',
		letterSpacing: '-0.02em',
	},
	titleXSmall: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 18,
		fontWeight: 'normal',
		lineHeight: '28px',
		letterSpacing: 0,
	},
	bodyXLarge: {
		fontFamily: 'GraphieBook',
		fontSize: 26,
		fontWeight: 350,
		lineHeight: '36px',
		letterSpacing: '0em',
	},
	bodyLarge: {
		fontFamily: 'GraphieBook',
		fontSize: 22,
		fontWeight: 350,
		lineHeight: '36px',
		letterSpacing: '0em',
	},
	bodyMedium: {
		fontFamily: 'GraphieRegular',
		fontSize: 20,
		fontWeight: 350,
		lineHeight: '28px',
		letterSpacing: '0em',
	},
	bodySmall: {
		fontFamily: 'GraphieRegular',
		fontSize: 16,
		fontWeight: 350,
		lineHeight: '26px',
		letterSpacing: '0em',
	},
	bodyXSmall: {
		fontFamily: 'GraphieRegular',
		fontSize: 12,
		fontWeight: 350,
		lineHeight: '16px',
		letterSpacing: '0em',
	},
	labelForm: {
		fontFamily: 'GraphieRegular',
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: '0em',
	},
	labelTag: {
		fontFamily: 'GraphieSemiBold',
		fontSize: 12,
		fontWeight: 'normal',
		lineHeight: '16px',
		letterSpacing: '0em',
		textTransform: 'uppercase',
	},
	labelCard: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 32,
		fontWeight: 'normal',
		lineHeight: '32px',
		letterSpacing: '-0.005em',
		textTransform: 'uppercase',
	},
	button: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 16,
		fontWeight: 'normal',
		lineHeight: '20px',
		letterSpacing: '0.025em',
		textTransform: 'uppercase',
	},
	textLink: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 20,
		fontWeight: 'normal',
		lineHeight: '22px',
		letterSpacing: '0em',
		textTransform: 'uppercase',
	},
	linkFilter: {
		fontFamily: 'GraphieSemiBold',
		fontSize: 14,
		fontWeight: 'normal',
		lineHeight: '18px',
		letterSpacing: '0em',
	},
	linkNavigation: {
		fontFamily: 'TradeGothicBoldCondensed',
		fontSize: 17,
		fontWeight: 'normal',
		lineHeight: '26px',
		letterSpacing: '0em',
		textTransform: 'uppercase',
	},
}
