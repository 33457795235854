import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { sendWebAnalyticsData } from 'services/webAnalyticsService'

const StyledLink = styled(Link)(({ theme, variant }) => {
	let textColor, hoverTextColor, textTransform, boxShadow

	switch (variant) {
		case 'primary-dark':
			textColor = theme.palette.common.white
			hoverTextColor = theme.palette.common.white
			textTransform = 'uppercase'
			boxShadow = theme.palette.common.white
			break
		case 'inline-link':
			textColor = theme.palette.primary.main
			hoverTextColor = theme.palette.primary.main
			textTransform = 'none'
			boxShadow = theme.palette.primary.main
			break
		case 'inline-link-dark':
			textColor = theme.palette.common.white
			hoverTextColor = theme.palette.common.white
			textTransform = 'none'
			boxShadow = theme.palette.common.white
			break
		case 'breadCrumb':
			textColor = theme.palette.primary.main
			hoverTextColor = theme.palette.primary.main
			textTransform = 'none'
			boxShadow = theme.palette.primary.main
			break
		case 'legal-link':
			textColor = theme.palette.secondary.main
			hoverTextColor = theme.palette.secondary.main
			textTransform = 'none'
			boxShadow = theme.palette.secondary.main
			break
		case 'body-link':
			textColor = 'currentColor'
			hoverTextColor = 'currentColor'
			textTransform = 'none'
			boxShadow = 'currentColor'
			break
		case 'primary':
		default:
			textColor = theme.palette.secondary.main
			hoverTextColor = theme.palette.primary.main
			textTransform = 'uppercase'
			boxShadow = theme.palette.secondary.main
			break
	}

	return {
		padding: 0,
		color: textColor,
		textTransform: textTransform,
		textDecoration: 'none',
		outline: 'none',
		justifyContent: 'flex-start',
		// boxShadow: 'none',
		position: 'relative',
		'& svg': {
			verticalAlign: 'top',
		},
		'& path': {
			fill: textColor,
		},

		'&:hover': {
			textDecoration: 'underline',
			textUnderlinePosition: 'under',
			color: hoverTextColor,
			'& path': {
				fill: hoverTextColor,
			},
		},
		'&:focus:not(:hover)::before': {
			content: '""',
			position: 'absolute',
			top: -2,
			right: -2,
			bottom: -2,
			left: -2,
			boxShadow: `0 0 0 2px ${boxShadow}`,
			borderRadius: '2px',
		},
	}
})

const TextIconLink = ({
	children,
	iconType,
	iconPosition,
	onClick,
	...props
}) => {
	const btnRef = useRef()

	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event)
		}
		if (props.analyticsid) {
			sendWebAnalyticsData(
				props.analyticstext,
				btnRef.current,
				props.analyticstargeturl,
				btnRef.current.classList,
				props.analyticsid
			)
		}
	}
	return (
		<StyledLink
			onClick={handleOnClick}
			ref={btnRef}
			variant={props.variant}
			{...props}
		>
			{iconPosition === 'before' ? iconType : ''}
			<Typography
				variant={
					props.variant === 'body-link' || props.variant === 'legal-link'
						? 'bodySmall'
						: (props.variant && props.variant === 'inline-link') ||
							  props.variant === 'inline-link-dark'
							? 'linkFilter'
							: 'button'
				}
			>
				{children}
			</Typography>
			{iconPosition === 'after' ? iconType : ''}
		</StyledLink>
	)
}

TextIconLink.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	iconType: PropTypes.any,
	iconPosition: PropTypes.oneOf(['before', 'after', null]),
	analyticsid: PropTypes.string,
	analyticstargeturl: PropTypes.string,
	analyticstext: PropTypes.string,
}

TextIconLink.defaultProps = {
	iconType: null,
	iconPosition: null,
}

export default TextIconLink
