import { Box as MuiBox, Typography, styled } from '@mui/material'
import TextIconLink from 'components/common/Links/TextIconLink'
import PageContentWrapper from 'components/layout/Content/PageContentWrapper'
import { useTextualContext } from 'contexts/TextualContext'
import { useLocation } from 'react-router-dom'

const StyledBox = styled(MuiBox)(({ theme }) => ({
	'ul, ol': {
		color: theme.palette.primary.main,
		paddingInlineStart: '1.5em',
	},

	ol: {
		li: {
			...theme.typography.bodySmall,
		},
	},
}))

const LegalContentPage = ({ redirectLink, isContentUpdated = false }) => {
	const location = useLocation()
	const { t } = useTextualContext()
	const footerContent = t('footer.legal')
	let content = null
	if (!redirectLink) {
		content = footerContent.find(
			(page) => location.pathname.indexOf(page.link) !== -1
		).content
	} else {
		content = footerContent.find((page) => redirectLink === page.link).content
	}

	return (
		<PageContentWrapper
			sx={{
				mt: !redirectLink ? 'auto' : { xs: '0', lg: '0' },
				mb: !redirectLink ? 'auto' : { xs: '32px', lg: '32px' },
				paddingInline: !redirectLink ? 'auto' : { xs: '0', lg: '32px' },
			}}
		>
			<StyledBox
				sx={{
					pr: {
						xs: '16px',
						lg: !redirectLink ? '376px' : '16px',
					},
				}}
			>
				{content &&
					content.length > 0 &&
					content.map((val, key) => {
						if (val.type === 'heading' && val.typography !== 'bodySmall') {
							if (val['heading-type'] === 'footer-content' && !redirectLink) {
								return (
									<Typography
										key={key}
										color="primary.main"
										role="heading"
										variant={val.typography}
										aria-level={val['aria-level']}
										dangerouslySetInnerHTML={{
											__html: val.value,
										}}
									></Typography>
								)
							} else if (
								val['heading-type'] === 'dialog-content' &&
								redirectLink &&
								!isContentUpdated
							) {
								return (
									<Typography
										key={key}
										color="primary.main"
										role="heading"
										variant={val.typography}
										aria-level={val['aria-level']}
										dangerouslySetInnerHTML={{
											__html: val.value,
										}}
									></Typography>
								)
							} else if (
								val['heading-type'] === 'dialog-content-updated' &&
								redirectLink &&
								isContentUpdated
							) {
								return (
									<Typography
										key={key}
										color="primary.main"
										role="heading"
										variant={val.typography}
										aria-level={val['aria-level']}
										dangerouslySetInnerHTML={{
											__html: val.value,
										}}
									></Typography>
								)
							}
						} else if (
							val.type === 'heading' &&
							val.typography === 'bodySmall'
						) {
							return (
								<div key={key}>
									<Typography
										style={{ fontWeight: '700', textTransform: 'upperCase' }}
										key={key}
										color="primary.main"
										role="heading"
										variant={val.typography}
										aria-level={val['aria-level']}
										dangerouslySetInnerHTML={{
											__html: val.value,
										}}
									>
										{/* {val.value} */}
									</Typography>
								</div>
							)
						} else if (val.type === 'paragraph') {
							return (
								<p
									key={key}
									style={{
										marginBottom:
											content[key + 1] && content[key + 1].type === 'heading'
												? '3rem'
												: 'auto',
									}}
								>
									<Typography
										color="primary.main"
										variant={val.typography}
										dangerouslySetInnerHTML={{
											__html: val.value,
										}}
									>
										{/* {val.value} */}
									</Typography>
								</p>
							)
						} else if (val.type === 'linklist') {
							return (
								<ul
									key={key}
									role="list"
									style={{
										marginBottom:
											content[key + 1] && content[key + 1].type === 'heading'
												? '3rem'
												: 'auto',
									}}
								>
									{val.value.map((l, i) => (
										<li key={i}>
											<TextIconLink
												style={{
													textDecoration: 'underline',
													textUnderlinePosition: 'under',
												}}
												color="primary.main"
												role="listitem"
												to={l.to}
												variant="legal-link"
											>
												{l.text}
											</TextIconLink>
										</li>
									))}
								</ul>
							)
						} else if (val.type === 'unorderedList') {
							return (
								<ul
									key={key}
									role="list"
									style={{
										marginBottom:
											content[key + 1] && content[key + 1].type === 'heading'
												? '3rem'
												: 'auto',
									}}
								>
									{val.value.map((l, i) => (
										<li key={i}>
											<Typography
												style={{
													fontWeight: '400',
												}}
												key={key}
												color="primary.main"
												role="listitem"
												variant={val.typography}
												dangerouslySetInnerHTML={{
													__html: l.text,
												}}
											>
												{/* {l.text} */}
											</Typography>
										</li>
									))}
								</ul>
							)
						} else if (val.type === 'orderedList') {
							const renderList = (items, level = 0) => {
								// Limit to 3 levels
								if (level >= 4) return null

								return (
									<ol type={level === 1 ? 'a' : level === 2 ? 'i' : '1'}>
										{items.map((item, index) => (
											<li
												key={index}
												style={{
													marginBottom: level === 0 ? '1rem' : 'auto',
												}}
											>
												<Typography
													style={{
														fontWeight: '400',
													}}
													key={key}
													color="primary.main"
													role="listitem"
													variant={val.typography}
													dangerouslySetInnerHTML={{
														__html: item.text,
													}}
												>
													{/* {item.text} */}
												</Typography>
												{item.subItems &&
													item.subItems.length > 0 &&
													renderList(item.subItems, level + 1)}
											</li>
										))}
									</ol>
								)
							}

							return renderList(val.value)
						}
					})}
			</StyledBox>
		</PageContentWrapper>
	)
}

export default LegalContentPage
