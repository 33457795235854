import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Loader from 'components/common/Loader'
import { useAppContext } from 'contexts/AppContext'

const StyledLoading = styled(Box)(({ theme }) => ({
	'&.loading-screen': {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		backgroundColor: theme.palette.common.white,
		display: 'grid',
		placeItems: 'center',
		height: '100vh',
	},
	zIndex: 1000,
}))
const Loading = () => {
	const { globalLoadingState } = useAppContext()
	return (
		<>
			{globalLoadingState && (
				<StyledLoading className="loading-screen">
					<Loader />
				</StyledLoading>
			)}
		</>
	)
}

export default Loading
