import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
// import parse from 'html-react-parser'

import StatusTag from 'components/common/Chip/StatusTag'
import IconLink from 'components/common/Links/IconLink'
import { ReactComponent as ArrowRight } from 'assets/svgs/actions/arrowRight.svg'
import { MILESTONE_STATUS } from 'utils/constants'
import { useTextualContext } from 'contexts/TextualContext'
import { stripHtmlTags } from 'utils/string.utils'

const textTransformation = [
	{
		textAlign: 'left',
		transformOrigin: 'bottom left',
	},
	{
		textAlign: 'right',
		transformOrigin: 'bottom right',
	},
	{
		textAlign: 'right',
		transformOrigin: 'bottom right',
	},
	{
		textAlign: 'left',
		transformOrigin: 'bottom left',
	},
]
// Temp fix for Home Sweet Home Milestone
const textTransformationLast = [
	{
		textAlign: 'center',
		transformOrigin: 'center center',
	},
]

const textTransformationLastMobile = [
	{
		textAlign: 'right',
		transformOrigin: 'center center',
	},
]
// Temp fix end

const transformDuration = 600

const bounceEffect = 'cubic-bezier(0.68, 0.55, 0.27, 1.55)'

const StyledOutermostBox = styled(Box)(({ theme, ...props }) => {
	const transformations = [
		{
			bottom: 110,
			left: 170,
		},
		{
			bottom: 0,
			right: 170,
		},
		{
			bottom: 110,
			right: 170,
		},
		{
			bottom: 0,
			left: 170,
		},
	]
	// Temp fix for Home Sweet Home Milestone
	const lastMilestoneTransformations = [
		{
			top: 110,
			right: -160,
		},
	]
	// Temp fix end
	const mobileTransformations = [
		{
			bottom: 80,
			left: 108,
			width: 124,
		},
		{
			bottom: 20,
			right: 108,
			width: 221,
		},
		{
			bottom: 80,
			right: 108,
			width: 124,
		},
		{
			bottom: 20,
			left: 108,
			width: 221,
		},
	]

	const transitions = [
		'translate(0px, -30px)',
		'translate(-30px, 0px)',
		'translate(0px, -30px)',
		'translate(30px, 30px)',
	]

	const lastMileStoneTranslations = ['translate(0px, 100px)']

	return {
		display: 'inline-flex',
		flexDirection: 'column',
		flexWrap: true,
		position: 'absolute',
		justifyContent: 'center',
		...mobileTransformations[props.currentindex % mobileTransformations.length],
		height: 60,
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.up('lg')]: {
			// Temp fix for Home Sweet Home Milestone
			...(props.isLastMileStone
				? lastMilestoneTransformations[0]
				: transformations[props.currentindex % transformations.length]),
			transform:
				props.hovered && !props.isLastMileStone
					? transitions[props.currentindex % transformations.length]
					: props.hovered && props.isLastMileStone
						? lastMileStoneTranslations[0]
						: '',
			// Temp fix end
			transition: `transform ${transformDuration}ms ${bounceEffect}`,
			width: 460,
			height: 148,
			'&:hover': {
				cursor: 'default',
			},
		},
	}
})

const StyledTagBox = styled(Box)(({ theme, ...props }) => ({
	marginBottom: 8,
	display: props.status === MILESTONE_STATUS.IN_PROGRESS ? 'block' : 'none',
	...textTransformation[props.currentindex % textTransformation.length],
	[theme.breakpoints.up('lg')]: {
		// marginBottom: 20, // origin design: 16
		transition: `transform ${transformDuration}ms ${bounceEffect}, opacity 1000ms ease-in-out`,
		// transform: props.hovered ? 'translate(0px, -20px)' : 'translate(0px)',
		textAlign: props.isLastMileStone ? 'center' : 'auto',
		display:
			props.hovered === 0 && props.status !== MILESTONE_STATUS.IN_PROGRESS
				? 'none'
				: 'block',
		visibility:
			props.status === MILESTONE_STATUS.IN_PROGRESS || props.hovered
				? 'visible'
				: 'hidden',
		opacity: !props.delay ? 1 : 0,
	},
}))

// Include Temp fix for Home Sweet Home Milestone
const StyledTitleBox = styled(Box)(({ theme, ...props }) => ({
	marginBottom: 8,
	...(!props.isLastMileStone
		? textTransformation[props.currentindex % textTransformation.length]
		: textTransformationLastMobile[0]),
	[theme.breakpoints.up('lg')]: {
		...(!props.isLastMileStone
			? textTransformation[props.currentindex % textTransformation.length]
			: textTransformationLast[0]),
		marginBottom: 4,
		transition: `transform ${transformDuration}ms ${bounceEffect}`,
		transform: props.hovered ? 'scale(2)' : 'scale(1)',
	},
}))
// Temp fix end

// Include Temp fix for Home Sweet Home Milestone
const StyledDescriptionBox = styled(Box)(({ theme, ...props }) => ({
	display: 'none',
	[theme.breakpoints.up('lg')]: {
		...(!props.isLastMileStone
			? textTransformation[props.currentindex % textTransformation.length]
			: textTransformationLast[0]),
		transition: props.hovered
			? `transform ${transformDuration}ms ${bounceEffect}, max-height ${transformDuration}ms ${bounceEffect}`
			: `transform ${transformDuration}ms, max-height ${transformDuration}ms`,
		transform: props.hovered ? 'scale(1)' : 'scale(0)',
		maxHeight: props.hovered ? '120px' : '0px',
		marginBottom: props.hovered ? 8 : 0, // origin design: 4
		display: 'block',
	},
}))

// Include Temp fix for Home Sweet Home Milestone
const StyledButtonBox = styled(Box)(({ theme, ...props }) => ({
	display: 'block',
	...textTransformation[props.currentindex % textTransformation.length],
	[theme.breakpoints.up('lg')]: {
		...(!props.isLastMileStone
			? textTransformation[props.currentindex % textTransformation.length]
			: textTransformationLast[0]),
		display: 'block',
	},
}))

const MilestoneText = ({
	title,
	description,
	hovered,
	currentIndex,
	currentStatus,
	isDesktopView,
	onViewMilestoneDetails,
	onFocusHandler,
	isLastMileStone,
}) => {
	const { t } = useTextualContext()
	const onFocusTextHandler = (index) => {
		onFocusHandler(index)
	}

	const statusText = currentStatus

	const linkText =
		currentStatus === MILESTONE_STATUS.UPCOMING
			? isDesktopView
				? t('my-journey.preview-milestone-button-text')
				: t('my-journey.preview-button-text')
			: t('my-journey.learn-more-button-text')

	return (
		<StyledOutermostBox
			aria-describedby="mileStoneTitle, mileStoneStatus"
			hovered={hovered | 0}
			isLastMileStone={isLastMileStone}
			currentindex={currentIndex}
			onClick={
				isDesktopView ? null : () => onViewMilestoneDetails(currentIndex)
			}
		>
			<StyledTitleBox
				currentindex={currentIndex}
				hovered={hovered | 0}
				isLastMileStone={isLastMileStone}
			>
				<Typography
					id="mileStoneTitle"
					variant="titleSmall"
					color={
						currentStatus !== MILESTONE_STATUS.UPCOMING
							? 'primary.main'
							: 'primary.darkGrey'
					}
					component="h2"
				>
					{title}
				</Typography>
			</StyledTitleBox>
			{!isLastMileStone && (
				// Include Temp fix for Home Sweet Home Milestone
				<StyledTagBox
					id="mileStoneStatus"
					currentindex={currentIndex}
					status={currentStatus}
					isLastMileStone={isLastMileStone}
					hovered={hovered | 0}
				>
					<StatusTag label={statusText} />
				</StyledTagBox>
				// Temp fix end
			)}
			<StyledDescriptionBox
				currentindex={currentIndex}
				hovered={hovered | 0}
				isLastMileStone={isLastMileStone}
			>
				<Typography
					variant="bodySmall"
					color={
						currentStatus !== MILESTONE_STATUS.UPCOMING
							? 'primary.main'
							: 'primary.darkGrey'
					}
				>
					{stripHtmlTags(description)}
				</Typography>
			</StyledDescriptionBox>
			<StyledButtonBox
				currentindex={currentIndex}
				hovered={hovered | 0}
				isLastMileStone={isLastMileStone}
			>
				<IconLink
					variant="primary"
					iconType={<ArrowRight />}
					iconPosition={'after'}
					aria-haspopup="dialog"
					onFocus={() => onFocusTextHandler(currentIndex)}
					onClick={() => onViewMilestoneDetails(currentIndex)}
				>
					{linkText}
				</IconLink>
			</StyledButtonBox>
		</StyledOutermostBox>
	)
}

MilestoneText.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	hovered: PropTypes.bool,
	currentIndex: PropTypes.number.isRequired,
	currentStatus: PropTypes.oneOf([
		MILESTONE_STATUS.IN_PROGRESS,
		MILESTONE_STATUS.COMPLETED,
		MILESTONE_STATUS.UPCOMING,
	]).isRequired,
	isDesktopView: PropTypes.bool,
	onViewMilestoneDetails: PropTypes.func.isRequired,
}

MilestoneText.defaultProps = {
	title: 'Milestone Name',
	description: '',
	hovered: false,
	isDesktopView: false,
}

export default MilestoneText
