import React from 'react'
import { Stack } from '@mui/material'
import Alert from 'components/common/Alert'
import { useAppContext } from 'contexts/AppContext'

const SnackBar = () => {
	const { globalAlertState, hideAlert, pageAlertState, hidePageAlert } =
		useAppContext()

	const { open, type, title, message } = globalAlertState

	const {
		open: pOpen,
		type: pType,
		title: pTitle,
		message: pMessage,
	} = pageAlertState

	return (
		<>
			{open && (
				<Stack alignItems={'center'} margin={2}>
					<Alert
						type={type}
						title={title}
						message={message}
						onClose={hideAlert}
					/>
				</Stack>
			)}
			{pOpen && (
				<Stack alignItems={'center'} margin={2}>
					<Alert
						type={pType}
						title={pTitle}
						message={pMessage}
						onClose={hidePageAlert}
					/>
				</Stack>
			)}
		</>
	)
}

export default SnackBar
