import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import { Button as MuiButton } from '@mui/material'
import { ReactComponent as DropdownClosed } from 'assets/svgs/actions/dropdownClosed.svg'
import { ReactComponent as DropdownExpanded } from 'assets/svgs/actions/dropdownExpanded.svg'
import { sendWebAnalyticsData } from 'services/webAnalyticsService'
import { useRef } from 'react'

const StyledButton = styled(MuiButton)(({ theme }) => {
	const textColor = theme.palette.secondary.main
	const bgColor = theme.palette.common.white
	const borderColor = theme.palette.secondary.main

	return {
		...theme.typography.button,

		minWidth: '172px',
		height: '56px',
		padding: '18px 12px 14px 20px',
		borderRadius: '12px',
		border: '1px solid',
		justifyContent: 'space-between',

		color: textColor,
		backgroundColor: bgColor,
		borderColor: borderColor,
		textTransform: 'uppercase',
		boxShadow: 'none', // removes the box-shadow MUI applies by default
		'&:focus::before': {
			content: '""',
			position: 'absolute',
			top: -2,
			right: -2,
			bottom: -2,
			left: -2,
			boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
			borderRadius: '4px',
		},
		'&:hover': {
			color: bgColor,
			backgroundColor: textColor,
			borderColor: borderColor,
			border: '1px solid',
			boxShadow: 'none',
		},
		'& path': {
			fill: 'currentColor',
		},
	}
})

const DropdownButton = ({ expanded, onClick, ...props }) => {
	const btnRef = useRef()
	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event)
		}
		if (props.analyticsid) {
			sendWebAnalyticsData(
				props.children,
				btnRef.current,
				props.analyticstargeturl,
				btnRef.current.classList,
				props.analyticsid
			)
		}
	}
	return (
		<StyledButton
			onClick={handleOnClick}
			ref={btnRef}
			disableRipple
			endIcon={expanded ? <DropdownExpanded /> : <DropdownClosed />}
			fullWidth
			{...props}
		/>
	)
}

DropdownButton.propTypes = {
	expanded: PropTypes.bool,
}

DropdownButton.defaultProps = {
	expanded: false,
}

export default DropdownButton
