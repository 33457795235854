import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as CollegeOrUniversity } from 'assets/svgs/iconography/collegeOrUniversity.svg'
import IconLink from 'components/common/Links/IconLink'
import { ReactComponent as ArrowRight } from 'assets/svgs/actions/arrowRight.svg'
import { ReactComponent as BackIcon } from 'assets/svgs/actions/back.svg'
import { ReactComponent as NextIcon } from 'assets/svgs/actions/next.svg'
import IconButton from 'components/common/Buttons/IconButton'
import { useTheme } from '@emotion/react'

const StyledBox = styled(Box)(({ theme }) => ({
	padding: '16px 24px 16px 24px',
	borderRadius: '8px',
	gap: '16px',
	backgroundColor: theme.palette.primary.main,
	boxShadow: '0px 0px 20px 0px #0000001F',
	display: 'flex',
	cursor: 'pointer',
	zIndex: 1,

	width: 'auto',
	height: '96px',
	bottom: 8,
	left: 8,
	right: 32,
	position: 'fixed',
	[theme.breakpoints.up('lg')]: {
		// width: '290px',
		top: 300,
		left: 24,
		bottom: 'auto',
		position: 'sticky',
	},
}))

const OutermostBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	[theme.breakpoints.up('lg')]: {
		top: 600,
		left: 24,
		height: '75%',
	},
}))

const StyledMinimizeButton = styled(Box)(({ theme, minimize }) => ({
	borderRadius: minimize ? '8px' : '0 8px 8px 0',
	backgroundColor: minimize
		? theme.palette.primary.main
		: theme.palette.primary.lightBlue,
	boxShadow: '0px 0px 20px 0px #0000001F',
	display: 'flex',
	cursor: 'pointer',
	zIndex: 1,
	height: '80px',
	marginRight: minimize ? 0 : '-48px',
	marginTop: minimize ? '8px' : '-8px',
	position: 'fixed',
	[theme.breakpoints.up('lg')]: {
		top: 300,
		bottom: 'auto',
		position: 'sticky',
	},
	[theme.breakpoints.down('lg')]: {
		// top: 300,
		bottom: minimize ? '8px' : 'auto',
		position: minimize ? 'fixed' : 'absolute',
		right: minimize ? 'auto' : '25px',
		left: minimize ? '8px' : 'auto',
	},
}))

const MinimizeButton = ({ minimize, handleMinimize }) => {
	const theme = useTheme()
	return (
		<StyledMinimizeButton
			minimize={minimize}
			aria-label={`${
				minimize ? 'Maximize' : 'Minimize'
			} Mattamy University Button`}
		>
			<IconButton
				style={{ padding: 0 }}
				onClick={() => handleMinimize(!minimize)}
			>
				{minimize ? (
					<NextIcon color={theme.palette.common.white} />
				) : (
					<BackIcon color={theme.palette.primary.main} />
				)}
			</IconButton>
		</StyledMinimizeButton>
	)
}

const MattamyUniversity = ({ title, buttonText, onClick }) => {
	let [minimize, setMinimize] = useState(false)

	const handleMinimize = (minValue) => {
		setMinimize(minValue)
	}
	if (title === '') {
		return null
	}

	return (
		<OutermostBox>
			{!minimize && (
				<StyledBox>
					<Box
						width={64}
						height={64}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'center'}
						borderRadius={'50%'}
						position="relative"
						backgroundColor={'primary.lightBlue'}
					>
						<CollegeOrUniversity width={48} height={48} />
					</Box>
					<Box pt={1}>
						<div>
							<Typography variant="titleSmall" color="common.white">
								{title}
							</Typography>
						</div>
						<div>
							<IconLink
								onClick={onClick}
								variant="primary-dark"
								iconType={<ArrowRight />}
								iconPosition={'after'}
							>
								{buttonText}
							</IconLink>
						</div>
					</Box>
					<MinimizeButton minimize={minimize} handleMinimize={handleMinimize} />
				</StyledBox>
			)}
			{minimize && (
				<MinimizeButton minimize={minimize} handleMinimize={handleMinimize} />
			)}
		</OutermostBox>
	)
}

MattamyUniversity.propTypes = {
	title: PropTypes.string,
	buttonText: PropTypes.string,
	onClick: PropTypes.func.isRequired,
}

MattamyUniversity.defaultProps = {
	title: '',
	buttonText: '',
}

export default MattamyUniversity
