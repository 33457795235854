import React from 'react'
import PropTypes from 'prop-types'
import { Box as MuiBox, styled } from '@mui/material'
import Chip from 'components/common/Chip/Chip'
import { sendWebAnalyticsData } from 'services/webAnalyticsService'

const StyledBox = styled(MuiBox)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: 8,

	[theme.breakpoints.down('lg')]: {
		'.MuiChip-root': {
			flexGrow: 1,
			flexBasis: '45%',
			'& .MuiChip-label': {
				display: 'block',
				whiteSpace: 'normal',
				lineHeight: 1,
				margin: -32,
			},
		},
	},
}))

const RadioButtonGroup = ({ filterOptions, activeFilter, onFilterChange }) => {
	const sendAnalyticsData = (event, item) => {
		sendWebAnalyticsData(
			item.label,
			event.target,
			'',
			event.target.classList,
			'document-filter-' + item.id.replaceAll(' ', '-')
		)
	}
	return (
		<StyledBox>
			{filterOptions.map((item, index) => {
				return (
					<Chip
						key={index}
						onClick={(event) => {
							onFilterChange(item.id)
							sendAnalyticsData(event, item)
						}}
						label={item.label + ' (' + item.count + ')'}
						aria-selected={activeFilter === item.id ? true : false}
						variant={
							item.count === 0
								? 'disabled'
								: activeFilter === item.id
									? 'selected'
									: 'unselected'
						}
						disabled={item.count === 0}
					></Chip>
				)
			})}
		</StyledBox>
	)
}

RadioButtonGroup.propTypes = {
	filterOptions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			count: PropTypes.number,
		})
	).isRequired,
	activeFilter: PropTypes.string.isRequired,
	onFilterChange: PropTypes.func.isRequired,
	ref: PropTypes.element,
}

export default RadioButtonGroup
