import { Outlet, useParams } from 'react-router-dom'
import NavigationBar from '../NavigationBar/NavigationBar'
import TutorialWalkthrough from 'components/TutorialWalkthrough'
import IdleTimer from 'components/layout/IdleTimer'
import SnackBar from 'components/layout/SnackBar'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { useEffect } from 'react'
import { LookupProvider } from 'contexts/LookupContext'
import { Footer } from '../Footer'
import { MilestoneProvider } from 'contexts/MilestoneContext'
import LegalContentDialog from 'pages/LegalContentDialog'
import { useAppContext } from 'contexts/AppContext'

export const Content = () => {
	let { authCode } = useParams()
	const { consentGiven } = useAppContext()
	useEffect(() => {
		if (authCode) {
			sessionStorage.setItem('authCode', authCode)
		}
	}, [])

	return (
		<>
			<LookupProvider>
				<AuthenticatedTemplate>
					<MilestoneProvider>
						<NavigationBar />
						{consentGiven && (
							<>
								<SnackBar />
								<Outlet />
								<Footer />
							</>
						)}
						<IdleTimer />
						<TutorialWalkthrough />
						<LegalContentDialog />
					</MilestoneProvider>
				</AuthenticatedTemplate>
			</LookupProvider>
		</>
	)
}
