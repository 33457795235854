import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as MilestoneIconCircleBorder } from 'assets/svgs/milestone/milestoneIconCircleBorder.svg'
import { ReactComponent as MilestoneIconCompleted } from 'assets/svgs/milestone/milestoneIconCompleted.svg'
import { ReactComponent as MilestoneStartHere } from 'assets/svgs/milestone/startHere.svg'
import { MILESTONE_STATUS } from 'utils/constants'

const transformDuration = 600

const bounceEffect = 'cubic-bezier(0.68, 0.55, 0.27, 1.55)'

const StyledOutermostBox = styled(Box)(({ theme, ...props }) => ({
	width: '98px',
	height: '98px',
	'&:hover': {
		cursor: 'pointer',
	},
	[theme.breakpoints.up('lg')]: {
		transition: `transform ${transformDuration}ms ${bounceEffect}`,
		transform: props.hovered ? 'scale(1.5)' : 'scale(1)',
		width: '128px',
		height: '128px',
		'&:hover': {
			cursor: 'default',
		},
	},
}))

const StyledInnerBox = styled(Box)(({ theme, ...props }) => ({
	position: 'absolute',
	'& path': {
		// stroke:
		// 	props.status === MILESTONE_STATUS.UPCOMING
		// 		? theme.palette.primary.darkGrey
		// 		: '',
		fill:
			props.status === MILESTONE_STATUS.UPCOMING
				? theme.palette.primary.darkGrey
				: '',
		transition: 'fill 1500ms',
		transitionDelay: '1000ms',
	},
	// display: props.activemilestoneindex > props.currentindex ? 'none' : 'unset',
	svg: {
		width: '64px',
		height: '64px',
	},
	top: '16px',
	left: '16px',
	[theme.breakpoints.up('lg')]: {
		svg: {
			width: '80px',
			height: '80px',
		},
		top: '24px',
		left: '24px',
	},
}))

const StyledCompletedBox = styled(Box)(({ theme }) => ({
	transition: 'opacity 1500ms',
	position: 'absolute',
	svg: {
		width: '98px',
		height: '98px',
	},
	top: '0px',
	[theme.breakpoints.up('lg')]: {
		svg: {
			width: '128px',
			height: '128px',
		},
		top: '0px',
	},
}))

const StyledOuterBox = styled(Box)(({ theme, ...props }) => ({
	stroke:
		props.status !== MILESTONE_STATUS.IN_PROGRESS
			? theme.palette.primary.darkGrey
			: theme.palette.secondary.lightTorquise,
	transition: 'stroke 1500ms',
	transitionDelay: '1000ms',
	svg: {
		width: '98px',
		height: '98px',
	},
	[theme.breakpoints.up('lg')]: {
		svg: {
			width: '128px',
			height: '128px',
		},
	},
}))

const NumberContainer = styled(Box)(({ theme, ...props }) => ({
	width: '32px',
	height: '32px',
	borderRadius: '50%', // Make it a circle
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'center',
	backgroundColor:
		props.status === MILESTONE_STATUS.UPCOMING
			? theme.palette.primary.darkGrey
			: theme.palette.secondary.lightTorquise,
	transition: 'background-color 1500ms',
	transitionDelay: '1000ms',
	color:
		props.status === MILESTONE_STATUS.UPCOMING
			? theme.palette.common.white
			: theme.palette.primary.main,
	position: 'absolute',
	bottom: '-10px',
	left: '50%',
	transform: 'translateX(-50%)',
}))

const StartHereContainer = styled(Box)(({ theme }) => ({
	transform: 'translateX(-50%) translateY(-30%)',
	position: 'absolute',
	[theme.breakpoints.up('lg')]: {
		transform: 'translateX(-50%) translateY(-25%) scale(1.2)',
	},
}))

const MilestoneBadge = ({
	milestoneIcon,
	hovered,
	currentIndex,
	currentStatus,
	isDesktopView,
	onViewMilestoneDetails,
	isLastMileStone,
}) => {
	return (
		<StyledOutermostBox
			hovered={hovered | 0}
			onClick={
				isDesktopView ? null : () => onViewMilestoneDetails(currentIndex)
			}
		>
			{currentIndex === 0 && (
				<StartHereContainer aria-hidden="true" role="presentation">
					<MilestoneStartHere />
				</StartHereContainer>
			)}
			<StyledOuterBox
				status={currentStatus}
				aria-hidden="true"
				role="presentation"
			>
				<MilestoneIconCircleBorder />
			</StyledOuterBox>
			{currentStatus !== MILESTONE_STATUS.COMPLETED && (
				<StyledInnerBox
					status={currentStatus}
					aria-hidden="true"
					role="presentation"
				>
					{milestoneIcon}
				</StyledInnerBox>
			)}
			{currentStatus === MILESTONE_STATUS.COMPLETED && (
				<StyledCompletedBox aria-hidden="true" role="presentation">
					{<MilestoneIconCompleted />}
				</StyledCompletedBox>
			)}
			{!isLastMileStone && (
				// Temp fix for Home Sweet Home Milestone
				<NumberContainer status={currentStatus}>
					<Typography variant="titleXSmall">{currentIndex + 1}</Typography>
				</NumberContainer>
				// Temp fix end
			)}
		</StyledOutermostBox>
	)
}

MilestoneBadge.propTypes = {
	milestoneIcon: PropTypes.element.isRequired,
	hovered: PropTypes.bool,
	currentIndex: PropTypes.number.isRequired,
	currentStatus: PropTypes.oneOf([
		MILESTONE_STATUS.IN_PROGRESS,
		MILESTONE_STATUS.COMPLETED,
		MILESTONE_STATUS.UPCOMING,
	]).isRequired,
	isDesktopView: PropTypes.bool,
	onViewMilestoneDetails: PropTypes.func.isRequired,
}

MilestoneBadge.defaultProps = {
	hovered: false,
	isDesktopView: false,
}

export default MilestoneBadge
