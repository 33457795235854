export const sendWebAnalyticsData = (text, element, url, classes, id) => {
	const dataLayerObj = {
		event: 'gtm.click',
		'gtm.elementText': text,
		'gtm.element': element,
		'gtm.elementUrl': url ? url : '',
		'gtm.elementClasses': classes ? classes.toString() : null,
		'gtm.elementId': id,
	}
	window.dataLayer.push(dataLayerObj)
}
