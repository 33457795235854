import { postRequest } from 'utils/axiosClient'

const getMilestones = async (requestData) => {
	try {
		const response = await postRequest('api/milestones', requestData)

		return response.data
	} catch (e) {
		console.error(e)
		return undefined
	}
}

export default { getMilestones }
