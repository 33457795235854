import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useLookupContext } from 'contexts/LookupContext'
import { useTextualContext } from 'contexts/TextualContext'

import TableViewer from 'components/TableViewer'
import IconLink from 'components/common/Links/IconLink'
import RadioButtonGroup from 'components/common/RadioButtonGroup'
import Alert from 'components/common/Alert'
import DocumentService from 'services/documentService'

import { ReactComponent as DownloadIcon } from 'assets/svgs/actions/download.svg'
import { CATEGORY_FILTER_OPTIONS, SORT_ORDER } from 'utils/constants'
import { stripFileExtension } from 'utils/string.utils'
import { formatToReadableDate } from 'utils/date.utils'

const DocumentDashboard = () => {
	const { t } = useTextualContext()
	const { documents, loading, email: userEmail } = useLookupContext()

	const [data, setData] = useState([])
	const [isApiError, setIsApiError] = useState(false)
	const [filteredData, setFilteredData] = useState([])

	const [activeFilter, setActiveFilter] = useState(CATEGORY_FILTER_OPTIONS.ALL)
	const [activeSort, setActiveSort] = useState('uploadDate')
	const [activeOrder, setActiveOrder] = useState(SORT_ORDER.DSC)

	const [alertType, setAlertType] = useState()
	const [alertTitle, setAlertTitle] = useState()
	const [alertMessage, setAlertMessage] = useState()

	const preDefinedFilterOptions = [
		{
			id: CATEGORY_FILTER_OPTIONS.ALL,
			label: t('my-documents.category-filter-options.ALL'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.HOME_PURCHASE,
			label: t('my-documents.category-filter-options.HOME_PURCHASE'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.DESIGN_SELECTION,
			label: t('my-documents.category-filter-options.DESIGN_SELECTION'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.CONSTRUCTION,
			label: t('my-documents.category-filter-options.CONSTRUCTION'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.CUSTOMER_CARE_N_WARRANTY,
			label: t('my-documents.category-filter-options.CUSTOMER_CARE_N_WARRANTY'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.CLOSING,
			label: t('my-documents.category-filter-options.CLOSING'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.PHOTOS,
			label: t('my-documents.category-filter-options.PHOTOS'),
		},
		{
			id: CATEGORY_FILTER_OPTIONS.OTHERS,
			label: t('my-documents.category-filter-options.OTHERS'),
		},
	]

	const categoryStringList = Object.values(CATEGORY_FILTER_OPTIONS)

	const [filterOptions, setFilterOptions] = useState(preDefinedFilterOptions)

	const columns = [
		{
			field: 'documentName',
			fieldType: 'String',
			width: '45%',
			headerName: t('my-documents.name'),
			sortable: true,
			sortLabel: {
				ASC: t('my-documents.sort-name-asc-label'),
				DSC: t('my-documents.sort-name-dsc-label'),
			},
			renderCell: (row) => stripFileExtension(row.documentName),
		},
		{
			field: 'category',
			fieldType: 'String',
			width: '20%',
			headerName: t('my-documents.category'),
		},
		{
			field: 'uploadDate',
			fieldType: 'Date',
			width: '20%',
			headerName: t('my-documents.date-uploaded'),
			sortable: true,
			sortLabel: {
				ASC: t('my-documents.sort-upload-date-asc-label'),
				DSC: t('my-documents.sort-upload-date-dsc-label'),
			},
			renderCell: (row) => formatToReadableDate(row.uploadDate),
		},
	]

	const setTableViewerAlert = (type, title = '', message = '') => {
		setAlertType(type)
		setAlertTitle(title)
		setAlertMessage(message)
	}

	const processFilteredData = () => {
		let newData = [...data]
		if (activeFilter) {
			if (activeFilter === CATEGORY_FILTER_OPTIONS.ALL) {
				// no need to filter
			} else if (activeFilter === CATEGORY_FILTER_OPTIONS.OTHERS) {
				newData = newData.filter(
					(doc) => !categoryStringList.includes(doc.category)
				)
			} else {
				newData = newData.filter((doc) => doc.category === activeFilter)
			}
		}

		if (activeSort) {
			newData = [...newData].sort((a, b) => {
				const valueA = a[activeSort].toUpperCase()
				const valueB = b[activeSort].toUpperCase()

				let temp = 0
				if (valueA < valueB) {
					temp = -1
				} else if (valueA > valueB) {
					temp = 1
				}

				return activeOrder === 'ASC' ? temp : -temp
			})
		}

		setFilteredData(newData)
	}

	useEffect(() => {
		setTableViewerAlert(null)
		processFilteredData()
	}, [activeFilter, activeSort, activeOrder, data])

	const fetchData = () => {
		setData(documents)

		// Calculating the number of documents by category
		const counts = documents.reduce((acc, doc) => {
			if (categoryStringList.includes(doc.category)) {
				acc[doc.category] = (acc[doc.category] || 0) + 1
			} else {
				acc[CATEGORY_FILTER_OPTIONS.OTHERS] =
					(acc[CATEGORY_FILTER_OPTIONS.OTHERS] || 0) + 1
			}
			return acc
		}, {})

		counts[CATEGORY_FILTER_OPTIONS.ALL] = documents.length

		const updatedFilterOptions = preDefinedFilterOptions.map((option) => ({
			...option,
			count: counts[option.id] || 0,
		}))

		setFilterOptions(updatedFilterOptions)
	}

	useEffect(() => {
		if (!loading) {
			if (documents) fetchData()
			else setIsApiError(true)
		}
	}, [loading])

	const handleDownloadDocument = async ({
		serverRelativeUrl,
		documentName,
	}) => {
		const { success } = await DocumentService.downloadDocument(
			serverRelativeUrl,
			documentName,
			userEmail
		)

		if (success) {
			setTableViewerAlert(
				'success',
				t('my-documents.download-success-alert-title'),
				t('my-documents.download-success-alert-message')
			)
		} else {
			setTableViewerAlert(
				'error',
				t('my-documents.download-failed-alert-title'),
				t('my-documents.download-failed-alert-message')
			)
		}
	}

	const renderRowActions = (row, shrink = false) => {
		return (
			<IconLink
				iconType={<DownloadIcon />}
				iconPosition={'before'}
				onClick={() => handleDownloadDocument(row)}
				shrink={shrink}
				aria-label={t('my-documents.download-button')}
				analyticsid={row.documentName.replaceAll(' ', '-')}
				analyticstargeturl={row.serverRelativeUrl}
				analyticstext={t('my-documents.download-button')}
				// target="_blank"
				// rel="noopener noreferrer"
			>
				{t('my-documents.download-button')}
			</IconLink>
		)
	}
	if (isApiError)
		return (
			<Box mt={8} flexDirection={'column'} display={'flex'} gap={2}>
				<Typography color="primary.main" variant="titleMedium">
					{t('my-documents.error-document')}
				</Typography>

				<Alert
					type={'info'}
					title={t('my-documents.error-document-alert-title')}
					message={t('my-documents.error-document-alert-message')}
				/>
			</Box>
		)

	if (data.length === 0)
		return (
			<Box mt={8} flexDirection={'column'} display={'flex'} gap={2}>
				<Typography color="primary.main" variant="titleMedium">
					{t('my-documents.no-available-document')}
				</Typography>

				<Alert
					type={'info'}
					title={t('my-documents.no-available-document-alert-title')}
					message={t('my-documents.no-available-document-alert-message')}
				/>
			</Box>
		)

	return (
		<>
			<Box mt={8} flexDirection={'column'} display={'flex'} gap={2}>
				<Box mb={5}>
					<Typography
						color="primary.main"
						variant="textLink"
						component={'h2'}
						mb={2}
					>
						{t('my-documents.filter-title')}
					</Typography>

					<RadioButtonGroup
						filterOptions={filterOptions}
						activeFilter={activeFilter}
						onFilterChange={setActiveFilter}
					/>
				</Box>

				<TableViewer
					data={filteredData}
					columns={columns}
					rowActions={renderRowActions}
					activeSort={activeSort}
					activeOrder={activeOrder}
					onSortChange={(name, order = SORT_ORDER.DSC) => {
						setActiveSort(name)
						setActiveOrder(order)
					}}
					onPageNumberChange={() => setTableViewerAlert(null)}
				>
					{alertType && (
						<Box mt={2}>
							<Alert
								type={alertType}
								title={alertTitle}
								message={alertMessage}
								onClose={() => setTableViewerAlert(null)}
							/>
						</Box>
					)}
				</TableViewer>
			</Box>
		</>
	)
}

export default DocumentDashboard
