import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card as MuiCard, Typography, styled } from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/svgs/actions/circleUp.svg'

const StyledCard = styled(MuiCard)(({ theme }) => ({
	borderRadius: 8,
	padding: '16px',
	backgroundColor: theme.palette.primary.lightBlue,
	boxShadow: 'none',

	border: `2px solid ${theme.palette.secondary.main}`,

	cursor: 'pointer',

	path: {
		fill: theme.palette.secondary.main,
	},
}))

const PreviewButton = ({ text, onClick }) => {
	return (
		<Box
			padding={'inherit'}
			position={'absolute'}
			bottom={'24px'}
			width={'auto'}
			left={'0'}
			right={'0'}
		>
			<StyledCard>
				<Box display={'flex'} gap={2} onClick={onClick}>
					<InfoIcon />
					<Typography
						variant="bodySmall"
						component={'div'}
						fontWeight={'normal'}
					>
						{text}
					</Typography>
				</Box>
			</StyledCard>
		</Box>
	)
}

PreviewButton.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
}

export default PreviewButton
