import { Box, styled } from '@mui/material'

const PageContentWrapper = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(8),
	marginBottom: theme.spacing(12),
	paddingInline: theme.spacing(2),
	[theme.breakpoints.up('md')]: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(16),
		paddingInline: theme.spacing(15),
	},
	[theme.breakpoints.up('lg')]: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(16),
		paddingInline: theme.spacing(2),
	},

	maxWidth: 1232,
	marginInline: 'auto',
	width: '100%',
}))

export default PageContentWrapper
