import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'

const HTMLContainsLink = (val) => {
	return (
		val &&
		val.map((item, i) => {
			switch (item.type) {
				case 'text':
					return (
						<Typography
							key={i}
							variant={item.typography}
							component={'span'}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(item.value),
							}}
						></Typography>
					)
				case 'link':
					return (
						<Typography key={i} variant={item.typography} component={'span'}>
							<Link
								to={item.to}
								role="link"
								className="link"
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(item.value),
								}}
							></Link>
						</Typography>
					)
			}
		})
	)
}

export { HTMLContainsLink }
